<template>
  <!-- Banner -->
  <div class="col no-wrap q-pt-md full-height" v-if="classroom?.Classroom">
    <!-- v1: can update  -->
    <q-form
      class="q-gutter-y-sm q-ml-md col"
      :style="{
        marginRight: $q.screen.gt.sm ? '1rem' : '2rem',
      }"
      v-if="accessUpdate"
    >
      <div class="row full-width">
        <div class="col-auto row items-center justify-center">
          <pickerimage
            :ref="'ref_classroom_image' + classroom?.Classroom.ID"
            :data="{
              uid: 'classroom_picker_image_' + classroom?.Classroom.ID,
              objectid: classroom?.Classroom.ID,
              objecttype: 'classroom_picture',
              params: {
                ClassroomID: classroom?.Classroom.ID,
              },
            }"
            style="border-radius: 1rem; max-height: 8rem; min-height: 8rem"
            :view="{
              height: '8rem',
              width: '8rem',
              alwaysget: true,
            }"
            class="glassy-8 q-mr-sm bg-transparent"
            @changed="changedClassroomPicture"
            @deleted="deletedClassroomPicture"
            :key="'classroom_picture_key' + classroompckey"
          />
        </div>
        <div class="col row items-center">
          <q-input
            type="text"
            :label="$t('-raw-classroom-name')"
            borderless
            clearable
            autogrow
            v-model="classroom.Classroom.Name"
            style="border-radius: 1rem"
            class="glassy-8 q-px-md full-width bg-transparent"
            input-class="ubuntu bg-transparent"
            input-style="color: var(--q-a);max-height: 6rem; min-height: 6rem"
            @update:model-value="classroomchangedDate = Date.now()"
          />
        </div>
      </div>
      <div class="column">
        <q-input
          type="text"
          :label="$t('-raw-classroom-description')"
          borderless
          clearable
          autogrow
          v-model="classroom.Classroom.Description"
          style="border-radius: 1rem"
          class="glassy-8 q-px-md full-width bg-transparent"
          input-class="ubuntu bg-transparent"
          input-style="color: var(--q-a); max-height: 5rem; min-height: 5rem;"
          @update:model-value="classroomchangedDate = Date.now()"
        />
      </div>

      <div class="column">
        <sourceLanguage
          class="col"
          :label="'true'"
          :languageid="classroom?.Classroom?.SourceLanguageID"
          @select="selectSourceLanguage"
        />
      </div>

      <div class="column">
        <languages
          style="font-size: 0.7rem; border: 1px solid var(--q-0)"
          class="col q-px-md glassy-8"
          :label="$t('-raw-classroom-language')"
          @selectLanguage="selectLanguage"
          :languages="classroom.LanguageIDs"
          :key="languagekey"
        />
      </div>

      <div class="column glassy-8" style="border-radius: 1rem">
        <div class="row" v-if="classroom.Classroom.OrganizationID">
          <q-input
            type="text"
            :label="$t('-raw-classroom-organization')"
            borderless
            style="border-top-left-radius: 1rem; border-top-right-radius: 1rem"
            v-model="classroom.Classroom.OrganizationName"
            class="glassy-8 q-px-md full-width bg-transparent"
            input-class="ubuntu bg-transparent"
            disable
          />
        </div>
        <q-tab-panels
          class="glassy-8 bg-transparent"
          :style="
            classroom.Classroom.OrganizationID
              ? ''
              : 'border-top-left-radius: 1rem; border-top-right-radius: 1rem'
          "
          v-model="classroom.Classroom.Privacy"
        >
          <q-tab-panel name="private">
            <div class="row items-center q-py-sm">
              <q-icon class="q-pa-sm" name="info" />
              <div class="col text-8">
                {{ $t("-raw-classroom-privacy-private-note") }}
              </div>
            </div>
          </q-tab-panel>

          <q-tab-panel name="public">
            <div class="row items-center">
              <q-icon class="q-pr-md" name="info" />
              <div
                class="col text-8"
                v-if="
                  classroom.Classroom.OrganizationID &&
                  !classroom.Classroom.ShowForAll
                "
              >
                {{ $t("-raw-classroom-privacy-organization-public-note") }}
              </div>
              <div class="col text-8" v-else>
                {{ $t("-raw-classroom-privacy-public-note") }}
              </div>
            </div>
            <div class="row items-center">
              <q-checkbox
                v-model="classroom.Classroom.AutoAccept"
                :label="$t('-raw-classroom-auto-join-people')"
                @update:model-value="classroomchangedDate = Date.now()"
              />
            </div>
            <div
              class="row items-center"
              v-if="classroom.Classroom.OrganizationID"
            >
              <q-checkbox
                v-model="classroom.Classroom.ShowForAll"
                :label="$t('-raw-classroom-show-for-all')"
                @update:model-value="classroomchangedDate = Date.now()"
              />
            </div>
          </q-tab-panel>
        </q-tab-panels>
        <q-tabs
          v-model="classroom.Classroom.Privacy"
          no-caps
          class="text-grey"
          active-color="primary"
          align="justify"
          indicator-color="transparent"
        >
          <q-tab
            style="border-bottom-left-radius: 1rem"
            class="glassy-8"
            name="private"
            :label="$t('-raw-classroom-privacy-private')"
          />
          <q-tab
            style="border-bottom-right-radius: 1rem"
            class="glassy-8"
            name="public"
            :label="$t('-raw-classroom-privacy-public')"
          />
        </q-tabs>
      </div>

      <div
        class="column glassy-8 bg-transparent q-pa-sm"
        style="border-radius: 1rem; gap: 0.5rem"
      >
        <div class="row items-center">
          <q-checkbox
            v-model="classroom.Classroom.AutoJoinByCode"
            :label="$t('-raw-classroom-auto-join-by-code')"
            @update:model-value="autoJoinByCodeModelChanged"
          />
        </div>
        <q-input
          v-if="classroom.Classroom.AutoJoinByCode"
          v-model="classroom.Classroom.AutoJoinCode"
          :label="$t('-raw-classroom-auto-join-code')"
          autogrow
          borderless
          class="glassy-8 q-px-md full-width bg-transparent"
          clearable
          input-class="ubuntu bg-transparent"
          input-style="color: var(--q-a); max-height: 2rem; min-height: 2rem;"
          style="border-radius: 1rem"
          type="text"
          @update:model-value="classroomAutoJoinChangedDate = Date.now()"
        >
          <template #append>
            <q-btn
              class="text-e"
              flat
              icon="sync"
              no-caps
              round
              @click="generateAutoJoinCode"
            >
              <q-tooltip
                anchor="top middle"
                class="row mytooltip round-both ubuntu bg-0 text-v items-center justify-center q-px-md"
                self="bottom middle"
                transition-hide="jump-down"
                transition-show="jump-up"
              >
                {{ $t("-raw-classroom-auto-join-by-code-generate") }}
              </q-tooltip>
            </q-btn>
          </template>
        </q-input>

        <q-input
          v-if="classroom.Classroom.AutoJoinByCode"
          v-model="linkJc"
          label="jc"
          autogrow
          borderless
          class="glassy-8 q-px-md full-width bg-transparent"
          input-class="ubuntu bg-transparent text-no-wrap"
          input-style="color: var(--q-a); max-height: 2rem; min-height: 2rem; scrollbar-width: thin"
          readonly
          style="border-radius: 1rem"
        >
          <template #append>
            <q-btn
              class="text-e"
              flat
              icon="qr_code"
              no-caps
              round
              color="v"
              @click="showQrJc = true"
            />
            <link-share :link="linkJc" />
          </template>
        </q-input>
        <q-input
          v-if="classroom.Classroom.AutoJoinByCode"
          v-model="linkJoinClassroom"
          label="classroom/join"
          autogrow
          borderless
          class="glassy-8 q-px-md full-width bg-transparent"
          input-class="ubuntu bg-transparent text-no-wrap text-ellipsis"
          input-style="color: var(--q-a); max-height: 2rem; min-height: 2rem; scrollbar-width: thin"
          readonly
          style="border-radius: 1rem"
        >
          <template #append>
            <q-btn
              class="text-e"
              flat
              icon="qr_code"
              no-caps
              round
              color="v"
              @click="showQrJoinClassroom = true"
            />
            <link-share :link="linkJoinClassroom" />
          </template>
        </q-input>
        <!-- `/jc/XXXXXX` link QR code -->
        <q-dialog v-model="showQrJc">
          <q-card>
            <q-bar>
              <q-space />
              <q-btn v-close-popup dense flat icon="close" />
            </q-bar>
            <q-card-section>
              <QRCodeVue3
                :background-options="{ color: '#ffffff' }"
                :corners-dot-options="{ type: 'dot', color: '#000000' }"
                :corners-square-options="{
                  type: 'extra-rounded',
                  color: '#000000',
                }"
                :dots-options="{
                  type: 'extra-rounded',
                  color: colorv,
                  gradient: {
                    type: 'linear',
                    rotation: 0,
                    colorStops: [
                      { offset: 0, color: colorv },
                      { offset: 1, color: colorv },
                    ],
                  },
                }"
                :download="true"
                :download-options="{ name: 'voccent_jc', extension: 'png' }"
                :height="200"
                :image-options="{
                  hideBackgroundDots: true,
                  imageSize: 0.4,
                  margin: 0,
                }"
                :qr-options="{
                  typeNumber: 0,
                  mode: 'Byte',
                  errorCorrectionLevel: 'H',
                }"
                :value="linkJc"
                :width="200"
                download-button="download-button fa-download"
                file-ext="png"
                image="logo.png"
                imgclass="img-qr"
                myclass="my-qur"
              />
            </q-card-section>
          </q-card>
        </q-dialog>
        <!-- `/classroom/join/XXXXXX` link QR code -->
        <q-dialog v-model="showQrJoinClassroom">
          <q-card>
            <q-bar>
              <q-space />
              <q-btn v-close-popup dense flat icon="close" />
            </q-bar>
            <q-card-section>
              <QRCodeVue3
                :background-options="{ color: '#ffffff' }"
                :corners-dot-options="{ type: 'dot', color: colorv }"
                :corners-square-options="{
                  type: 'extra-rounded',
                  color: colorv,
                }"
                :dots-options="{
                  type: 'extra-rounded',
                  color: colorv,
                  gradient: {
                    type: 'linear',
                    rotation: 0,
                    colorStops: [
                      { offset: 0, color: colorv },
                      { offset: 1, color: colorv },
                    ],
                  },
                }"
                :download="true"
                :download-options="{
                  name: 'voccent_classroom_join',
                  extension: 'png',
                }"
                :height="200"
                :image-options="{
                  hideBackgroundDots: true,
                  imageSize: 0.4,
                  margin: 0,
                }"
                :qr-options="{
                  typeNumber: 0,
                  mode: 'Byte',
                  errorCorrectionLevel: 'H',
                }"
                :value="linkJoinClassroom"
                :width="200"
                download-button="download-button fa-download"
                file-ext="png"
                image="logo.png"
                imgclass="img-qr"
                myclass="my-qur"
              />
            </q-card-section>
          </q-card>
        </q-dialog>
      </div>

      <div class="column">
        <pickerimage
          :ref="'ref_classroom_banner' + classroom?.Classroom.ID"
          :data="{
            uid: 'classroom_picker_banner_' + classroom?.Classroom.ID,
            objectid: classroom?.Classroom.ID,
            objecttype: 'classroom_banner',
            params: {
              ClassroomID: classroom?.Classroom.ID,
            },
          }"
          style="border-radius: 1rem"
          :view="{
            height: '8rem',
            alwaysget: true,
          }"
          class="glassy-8 q-mr-sm bg-transparent full-width"
          @changed="changedClassroomBanner"
          @deleted="deletedClassroomBanner"
          :key="'classroom_banner_key' + classroombnkey"
        />
      </div>
    </q-form>

    <!-- v2: can't update -->
    <q-form class="q-ma-sm q-gutter-y-sm col" v-else>
      <pickerimage
        v-if="
          classroom?.Confirmation?.Status == 'resolved' ||
          classroom?.Confirmation?.Status == 'confirmed'
        "
        :data="{
          uid: 'classroom_picker_banner_' + classroom?.Classroom.ID,
          objectid: classroom?.Classroom.ID,
          objecttype: 'classroom_banner',
          params: {
            ClassroomID: classroom?.Classroom.ID,
          },
        }"
        style="border-radius: 1rem; position: absolute; top: 0; left: 0"
        :view="{
          height: '8rem',
          onlyshow: true,
        }"
        class="glassy-8 q-mr-sm bg-transparent full-width"
      />
      <div class="row full-width justify-center">
        <div class="column">
          <pickerimage
            :data="{
              uid: 'classroom_picker_image_' + classroom?.Classroom.ID,
              objectid: classroom?.Classroom.ID,
              objecttype: 'classroom_picture',
              params: {
                ClassroomID: classroom?.Classroom.ID,
              },
            }"
            style="
              border-radius: 1rem;
              max-height: 10rem;
              min-height: 10rem;
              border: 1px solid;
              border-color: var(--q-v);
            "
            :view="{
              height: '10rem',
              width: '10rem',
              onlyshow: true,
            }"
            class="glassy-8 bg-transparent"
          />
        </div>
      </div>
      <!-- <div class="column text-h5 items-center q-pt-md">
        <span style="font-weight: bold">{{ classroom.Classroom.Name }}</span>
      </div> -->

      <div
        class="row justify-center items-center"
        v-if="
          classroom.LanguageIDs &&
          Array.isArray(classroom.LanguageIDs) &&
          classroom.LanguageIDs.length > 0
        "
      >
        <q-icon class="q-mx-sm" size="1.5rem" name="language" />

        <span>
          <template
            v-for="(lng1, n1) in classroom.LanguageIDs"
            :key="n1 + 'classroom_languages_list1'"
          >
            <template
              v-for="(lng2, n2) in $store.getters.languages"
              :key="n2 + 'classroom_languages_list2'"
            >
              <template v-if="lng1 === lng2.ID">
                <template v-if="classroom.LanguageIDs.length > 1">
                  {{ lng2.name }};
                </template>
                <template v-else>
                  {{ lng2.name }}
                </template>
              </template>
            </template>
          </template>
        </span>
      </div>

      <div class="column items-center">
        {{ $t("-raw-members") }}: {{ classroom.Classroom?.Members ?? 0 }}
      </div>

      <div class="column items-center">
        {{ $t("-raw-plans") }}: {{ classroom.Classroom?.Plans ?? 0 }}
      </div>

      <div
        class="column items-center text-center text-italic text-8"
        v-if="classroom.Classroom?.Description != null"
      >
        {{ classroom.Classroom?.Description }}
      </div>
    </q-form>

    <!-- // !!! IMPORTANT
    // cases (if have access): now => can do
    // * no status (owner or superadmin)
    // * no status (not owner) => join
    // * rejected by admin => rejoin
    // * canceled by user => rejoin
    // * invited by admin => confirm && cancel
    // * resolved by admin => cancel membership
    // * confirmed by user => cancel membership
    // * requested by user => cancel -->
    <q-form class="q-ma-sm q-gutter-y-sm col">
      <!-- user not in classroom but waiting status -->
      <div
        class="column items-center text-center q-pt-sm text-w text-bold"
        style="height: 1.5rem"
      >
        <span v-if="classroom.Confirmation?.Status == 'invited'">{{
          $t("-raw-classroom-you-was-invite-2")
        }}</span>
        <span v-else-if="classroom.Confirmation?.Status == 'requested'">{{
          $t("-raw-classroom-you-ask-to-join")
        }}</span>
        <span v-else>&nbsp;</span>
      </div>

      <!-- user not in classroom but waiting status -->
      <div
        class="row justify-center q-py-sm"
        v-if="
          classroom.Confirmation?.Status == 'invited' ||
          classroom.Confirmation?.Status == 'requested'
        "
      >
        <q-btn
          color="n"
          class="q-py-md"
          dense
          rounded
          no-caps
          style="min-width: 10rem; font-size: 1.1rem"
          @click="cancelJoinClassroom"
          :loading="isChangingStatus"
        >
          <span class="q-mx-md">{{
            $t("-raw-classroom-operation-cancel")
          }}</span>
        </q-btn>

        <q-btn
          color="p"
          v-if="classroom.Confirmation?.Status == 'invited'"
          class="q-ml-md q-py-md"
          dense
          rounded
          no-caps
          style="min-width: 10rem; font-size: 1.1rem"
          @click="confirmJoinClassroom"
          :loading="isChangingStatus"
        >
          <span class="text-0 q-mx-md">{{ $t("-raw-join") }}</span>
        </q-btn>
      </div>

      <!-- user not in classroom and can try join  -->
      <div
        class="column items-center text-center q-py-sm"
        v-if="
          classroom.Classroom.createdby != this.$store.getters.user.ID &&
          (classroom.Confirmation?.Status == null ||
            classroom.Confirmation?.Status == 'rejected' ||
            classroom.Confirmation?.Status == 'canceled')
        "
      >
        <q-btn
          color="p"
          class="q-py-md"
          dense
          rounded
          no-caps
          style="min-width: 10rem; font-size: 1.1rem"
          @click="joinClassroom"
          :loading="isChangingStatus"
        >
          <span
            v-if="classroom.Classroom?.AutoAccept == true"
            class="text-0 q-mx-md"
            >{{ $t("-raw-join") }}</span
          >
          <span v-else class="text-0 q-mx-md">{{
            $t("-raw-general-ask-to-join")
          }}</span>
        </q-btn>
      </div>

      <!-- user in classroom and can leave -->
      <div
        class="row justify-center q-py-sm"
        v-if="
          !isSimpleUI &&
          classroom.Classroom.createdby != this.$store.getters.user.ID &&
          (classroom.Confirmation?.Status == 'resolved' ||
            classroom.Confirmation?.Status == 'confirmed')
        "
      >
        <q-btn
          color="n"
          class="q-py-md"
          dense
          rounded
          no-caps
          style="min-width: 10rem; font-size: 1.1rem"
          @click="cancelJoinClassroom"
          :loading="isChangingStatus"
        >
          <span class="text-0 q-mx-md">{{ $t("-raw-classroom-leave") }}</span>
        </q-btn>
      </div>

      <!-- rating -->
      <div
        class="row glassy-8 q-pa-md q-mt-lg bg-transparent items-center"
        style="border-radius: 1rem"
        v-if="
          !isSimpleUI &&
          (classroom?.Confirmation?.Status == 'resolved' ||
            classroom?.Confirmation?.Status == 'confirmed')
        "
      >
        <rating
          pobjecttype="classroom"
          :pobjectid="classroom.Classroom.ID"
          :prating="classroom.Classroom.Rating"
          :pcountrating="classroom.Classroom.CountRating"
        />
      </div>
    </q-form>
  </div>
</template>

<script>
import languages from "src/pages/edit/languages.vue";
import rating from "src/components/rating.vue";
import pickerimage from "src/components/picker-picture/picker.vue";
import sourceLanguage from "src/pages/edit/source-language.vue";
import QRCodeVue3 from "qrcode-vue3";
import { getCssVar } from "quasar";
import LinkShare from "./link-share.vue";
import hlpr from "src/h/helpers.js";
// import { Notify } from "quasar";

export default {
  emits: ["save", "savestatus"],
  components: {
    LinkShare,
    languages,
    rating,
    pickerimage,
    sourceLanguage,
    QRCodeVue3,
  },
  props: {
    pclassroom: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  computed: {
    accessUpdate() {
      return this.$store.getters.currentClassroomAccess?.update;
    },
    linkJc() {
      return this.classroom.Classroom.AutoJoinCode
        ? `${process.env.PUBLIC_URL}/jc/${this.classroom.Classroom.AutoJoinCode}`
        : "";
    },
    linkJoinClassroom() {
      return this.classroom.Classroom.AutoJoinCode
        ? `${process.env.PUBLIC_URL}/classroom/join/${this.classroom.Classroom.AutoJoinCode}`
        : "";
    },
    isSimpleUI() {
      return hlpr.getItem("VOC_USER_SIMPLE_UI") === true;
    },
  },
  data: () => ({
    classroom: {
      Classroom: {},
      LanduageIDs: [],
    },
    classroomchangedDate: null,
    languagechangedDate: null,
    classroomAutoJoinChangedDate: null,
    timeUpdate: 1000, // save after timeUpdate ms pass from last change
    updateIntervalClassroom: null,
    updateIntervalClassroomAutoJoin: null,
    updateIntervalLanguage: null,
    languagekey: 0,
    classroompckey: 0,
    classroombnkey: 0,
    isChangingStatus: false,
    showQrJc: false,
    showQrJoinClassroom: false,
    colorv: getCssVar("v"),
  }),
  watch: {
    classroomchangedDate: {
      handler(newVal, oldVal) {
        if (this.updateIntervalClassroom) {
          clearTimeout(this.updateIntervalClassroom);
        }
        if (newVal) {
          var lnk = this;
          this.updateIntervalClassroom = setTimeout(() => {
            lnk.save(lnk.classroom.Classroom, "save_classroom");
            lnk.$store.commit("setCurrentClassroom", {
              ID: lnk.classroom.Classroom.ID,
              Name: lnk.classroom.Classroom.Name,
            });
            lnk.classroomchangedDate = null;
          }, this.timeUpdate);
        }
      },
    },
    classroomAutoJoinChangedDate: {
      handler(newVal, oldVal) {
        if (this.updateIntervalClassroomAutoJoin) {
          clearTimeout(this.updateIntervalClassroomAutoJoin);
        }
        if (newVal) {
          var lnk = this;
          this.updateIntervalClassroomAutoJoin = setTimeout(() => {
            lnk.save(
              {
                ID: lnk.classroom.Classroom.ID,
                AutoJoinByCode: lnk.classroom.Classroom.AutoJoinByCode,
                AutoJoinCode: lnk.classroom.Classroom.AutoJoinCode,
              },
              "save_classroom_auto_join",
            );
            lnk.classroomAutoJoinChangedDate = null;
          }, this.timeUpdate);
        }
      },
    },
    languagechangedDate: {
      handler(newVal, oldVal) {
        if (this.updateIntervalLanguage) {
          clearTimeout(this.updateIntervalLanguage);
        }
        if (newVal) {
          var lnk = this;
          this.updateIntervalLanguage = setTimeout(() => {
            lnk.save(this.classroom.LanguageIDs, "save_languages");
            lnk.languagechangedDate = null;
          }, this.timeUpdate);
        }
      },
    },
    "classroom.Classroom.Privacy": {
      handler(newVal, oldVal) {
        if (oldVal && newVal && newVal !== oldVal) {
          if (newVal == "private") {
            this.classroom.Classroom.AutoAccept = false;
          }
          this.classroomchangedDate = Date.now();
        }
      },
      immediate: true,
    },
  },
  beforeMount() {
    this.sync(this.pclassroom);
  },
  beforeUnmount() {},
  methods: {
    sync(data, objname) {
      if (!objname) {
        this.classroom = data;
      } else {
        this.classroom[objname] = data;
        if (objname === "LanguageIDs") {
          this.languagekey++;
        }
      }
    },
    syncAutoJoin(data) {
      this.classroom.Classroom.AutoJoinCode = data.AutoJoinCode;
      this.classroom.Classroom.AutoJoinByCode = data.AutoJoinByCode;
    },
    syncimg(objname) {
      switch (objname) {
        case "picture":
          this.classroompckey++;
          break;
        case "banner":
          this.classroombnkey++;
          break;
      }
    },
    save(data, method) {
      this.$emit("save", data, method);
    },
    saveStatus(data, method) {
      this.$emit("savestatus", data, method);
    },
    cancelJoinClassroom() {
      this.isChangingStatus = true;
      this.saveStatus(
        {
          ObjectID: this.classroom.Classroom?.ID,
        },
        "cancel_join",
      );
    },
    confirmJoinClassroom() {
      this.isChangingStatus = true;
      this.saveStatus(
        {
          ObjectID: this.classroom.Classroom?.ID,
        },
        "confirm_join",
      );
    },
    joinClassroom() {
      this.isChangingStatus = true;
      this.saveStatus(
        {
          ObjectID: this.classroom.Classroom?.ID,
        },
        "request_join",
      );
    },
    generateAutoJoinCode() {
      let code = "";
      for (let i = 0; i < 5; i++) {
        code += Math.random().toString(36).substring(2, 4);
      }
      this.classroom.Classroom.AutoJoinCode = code;
      this.classroomAutoJoinChangedDate = Date.now();
    },
    autoJoinByCodeModelChanged() {
      // set AutoJoinByCode = true and AutoJoinCode is not set - generate it
      if (
        this.classroom.Classroom.AutoJoinByCode &&
        this.classroom.Classroom.AutoJoinCode == null
      ) {
        this.generateAutoJoinCode();
        return;
      }
      // just save
      this.classroomAutoJoinChangedDate = Date.now();
    },
    selectLanguage(val) {
      this.classroom.LanguageIDs = val;
      this.languagechangedDate = Date.now();
    },
    selectSourceLanguage(val) {
      this.classroom.Classroom.SourceLanguageID = val;
      this.classroomchangedDate = Date.now();
    },
    changedClassroomPicture() {
      this.$refs["ref_classroom_image" + this.classroom?.Classroom.ID].Save();
    },
    deletedClassroomPicture() {
      this.$refs["ref_classroom_image" + this.classroom?.Classroom.ID].Save();
    },
    changedClassroomBanner() {
      this.$refs["ref_classroom_banner" + this.classroom?.Classroom.ID].Save();
    },
    deletedClassroomBanner() {
      this.$refs["ref_classroom_banner" + this.classroom?.Classroom.ID].Save();
    },
    changingStatus(val, obj) {
      // classroom is private && user was invite by admin or joined by code: push into main page
      // because user can't join again anymore

      // note: ref-de9db833:
      if (
        obj &&
        (obj.Object?.Confirmation?.Status == "canceled" ||
          obj.Object?.Confirmation?.Status == "rejected") &&
        obj.Object?.Classroom.OrganizationID ===
          this.$store.getters.user.FocusOrganizationID
      ) {
        this.$store.commit("setUserFocusOrganizationID", null);
      }

      if (
        this.isChangingStatus == true &&
        val == false &&
        // this.classroom?.Classroom.AutoJoinByCode
        this.classroom?.Classroom.Privacy == "private" &&
        this.$route.name === "route-classroom"
      ) {
        this.$router.push("/");
      }
      this.isChangingStatus = val;
    },
  },
};
</script>

<style>
.download-button {
  visibility: hidden;
  position: relative;
}

.download-button:after {
  visibility: visible;
  font-size: 1.5rem;
  display: inline-block;
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 512 512'%3E%3Cpath fill='gray' d='M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z'/%3E%3C/svg%3E");
  position: absolute;
  width: 22px;
  height: 10px;
  top: 0;
  left: 170px;
  cursor: pointer;
  color: var(--q-v);
}
</style>
