<script src="./index.js"></script>
<template>
  <q-page
    class="boxshadow no-wrap bg-1 column full-width"
    style="overflow-y: hidden"
  >
    <!-- style="border-top-left-radius: 1rem; border-top-right-radius: 1rem" -->
    <q-card flat class="full-width bg-2">
      <div
        class="column full-width justify-center items-center q-px-md q-pt-md q-mb-sm"
      >
        <div class="row full-width justify-center items-center">
          <div class="q-mr-md">
            <languages
              style="
                font-size: 0.8rem;
                border: 1px solid var(--q-0);
                width: 20rem;
              "
              class="col q-px-md glassy-8"
              @selectLanguage="selectLanguage"
              :languages="filterLanguages"
              :view="{ scroll: true, height: '4rem' }"
            />
          </div>
          <q-input
            :ref="'filterName_' + id"
            v-model="filterName"
            borderless
            type="text"
            :placeholder="$t('-raw-classroom-search')"
            debounce="500"
            q-input
            style="
              border-radius: 0.5rem;
              border: 2px solid var(--q-2);
              box-shadow: 0 0 2rem var(--q-0);
              transition-duration: 0.2s;
              pointer-events: auto;
              min-width: 12rem;
            "
            class="bg-0 q-px-md col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12"
          >
            <template #prepend>
              <q-icon name="search" />
            </template>
            <template #append>
              <q-icon
                v-if="filterName !== ''"
                name="clear"
                class="cursor-pointer"
                @click="resetFilterName"
              ></q-icon>
              <!-- Search params button-->
              <q-btn
                style="height: 2rem; width: 2rem; border-radius: 0.5rem"
                color="v"
                icon="tune"
                flat
                @click="toggleShowSearchForm"
              >
              </q-btn>
            </template>
            <q-menu
              fit
              no-parent-event
              v-model="showSearchForm"
              transition-show="jump-up"
              transition-hide="jump-down"
              class="bg-1 q-pa-xs"
              style="border-radius: 0.5rem"
            >
              <form>
                <div class="column no-wrap q-pa-md">
                  <div class="row no-wrap q-pt-md">
                    <div
                      class="col q-my-auto ubuntu q-pr-sm no-wrap row justify-between"
                    >
                      <q-checkbox
                        left-label
                        checked-icon="task_alt"
                        v-model="filterSourceLanguages"
                        @update:model-value="search()"
                      >
                        <span class="q-pr-sm">{{
                          $t("-raw-search-my-source-languages")
                        }}</span>
                      </q-checkbox>
                    </div>
                  </div>
                  <div class="row no-wrap q-pt-md" v-if="isSystem">
                    <div
                      class="col q-my-auto ubuntu q-pr-sm no-wrap row justify-between"
                    >
                      <q-checkbox
                        left-label
                        checked-icon="task_alt"
                        v-model="ignoreSystemRole"
                        @update:model-value="search()"
                      >
                        <span class="q-pr-sm">{{
                          $t("-raw-search-ignore-system-role")
                        }}</span>
                      </q-checkbox>
                    </div>
                  </div>
                </div>
              </form>
            </q-menu>
          </q-input>
        </div>
      </div>
    </q-card>
    <q-scroll-area class="col q-pt-md q-mx-md">
      <q-infinite-scroll
        :ref="'foundScroll_' + id"
        class="row justify-center fit"
        @load="searchNextPage"
      >
        <div
          v-for="(item, index) in foundList"
          :key="'found_' + index + item.ID"
          class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12 q-px-sm q-pb-md"
        >
          <classroomcard
            :ref="'classroomcard_' + item.ID"
            :item="item"
            :canedit="isSystem"
            class="full-width"
            @choose="choose"
          />
        </div>
        <div
          v-if="!isSearchLoading && Object.keys(foundList).length === 0"
          class="row justify-center content-center full-width full-height ubuntu"
        >
          {{ $t("-raw-404-nothing-found-v2") }}
        </div>
      </q-infinite-scroll>
    </q-scroll-area>
  </q-page>
</template>
