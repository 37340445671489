import { initializeApp } from "firebase/app";
import {
  getAuth,
  signOut,
  onAuthStateChanged,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
  OAuthProvider,
  getRedirectResult,
} from "firebase/auth";
import api from "src/h/api.js";
import h from "src/h/helpers.js";
import { Dialog, Notify } from "quasar";
import { i18n } from "src/boot/i18n.js";
import { Constantes } from "src/boot/constants.js";
import { streamer } from "src/boot/streamer.js";

const firebaseConfig = {
  apiKey: "AIzaSyDQH3NQg8pW3C8UYls8U9zj-HaR4dOfzgw",
  authDomain: "voccent.firebaseapp.com",
  databaseURL: "https://voccent.firebaseio.com",
  projectId: "voccent",
  storageBucket: "voccent.appspot.com",
  messagingSenderId: "936783312403",
  appId: "1:936783312403:web:27b46ddcfacf3471",
};
const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);

export default {
  state: {
    user: false,
    userTariff: {},
    newUser: false,
    languages: false,
    currenciesInTariff: [],
    tariffTable: {},
    tariffPrices: [],
    usernameAvailable: null,
    updatedUser: {
      firstname: null,
      lastname: null,
      username: null,
      BirthdayDateTime: null,
      newAvatar: null,
      MailMain: false,
      MailChannel: false,
      MailChat: false,
    },
    goalLanguage: null,
    languageUI: {},
    level: null,
    ttsVoices: null,
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload;

      let user = state.user;
      // if (user) {
      //   smartlook("identify", user.ID, {
      //     name: user.firstname + " " + user.lastname,
      //     email: user.cred_id,
      //   });
      // }
    },
    setUserFocusOrganizationID(state, payload) {
      if (typeof state.user === "object") {
        state.user.FocusOrganizationID = payload;
      }
    },
    setUserTariff(state, payload) {
      state.userTariff = payload;
    },
    setNewUser(state, payload) {
      state.newUser = payload;
    },
    setLanguages(state, payload) {
      state.languages = payload;
    },
    setLanguageUI(state, payload) {
      state.languageUI = payload;
    },
    setCurrenciesInTariff(state, payload) {
      state.currenciesInTariff = payload;
    },
    setTariffTable(state, payload) {
      state.tariffTable = payload;
    },
    setTariffPrices(state, payload) {
      state.tariffPrices = payload;
    },
    setUpdatedUser(state, payload) {
      state.updatedUser = payload;
    },
    setUsernameAvailable(state, payload) {
      state.usernameAvailable = payload;
    },
    updateUser(state, payload) {
      state.user[payload.name] = payload.value;
    },
    updateUpdatedUser(state, payload) {
      state.updatedUser[payload.name] = payload.value;
    },
    updateUpdatedUserAsset(state, payload) {
      if (state.updatedUser.Asset) {
        state.updatedUser.Asset[payload.name] = payload.value;
      }
    },
    updateNewUser(state, payload) {
      state.newUser[payload.name] = payload.value;
    },
    setNewAvatar(state, payload) {
      state.updatedUser["newAvatar"] = payload;
    },
    disposeNewAvatar(state) {
      delete state.updatedUser.newAvatar;
    },
    setGoalLanguage(state, payload) {
      state.goalLanguage = payload;
    },
    setUserLevel(state, payload) {
      state.level = payload;
    },
    setTTSVoices(state, payload) {
      state.ttsVoices = payload;
    },
    updateLanguagesTranslation(state) {
      if (Array.isArray(state.languages)) {
        state.languages = state.languages.map((lang) => ({
          ...lang,
          localName: i18n.global.t("-raw-language-" + lang.locale) || lang.name,
        }));
      }
    },
  },
  actions: {
    // TODO: set cookies/cache for tokens and only call api if something's missing
    userLogout({ commit }) {
      // Uncomment the following to unlink the accounts. Useful for linking testing.
      // firebase.auth().currentUser.unlink(firebase.auth.FacebookAuthProvider.PROVIDER_ID)

      signOut(auth)
        .then(() => {
          console.log("info-de3c589e-success: signed out");
        })
        .catch((error) => {
          console.log("error-de3c589e-error: signed out");
        });

      // clear Intercom session
      window.Intercom("shutdown");
      h.removeItem("VOC_USER_TOKEN");
      h.removeItem("VOC_USER_ROLE_IS_SYSTEM");
      h.removeItem("VOC_USER_ROLE_IS_ADMIN");
      h.removeItem("VOC_USER_ORGANIZATION_ID");
      h.removeItem("VOC_USER_SIMPLE_UI");
      commit("setOrganizationID", null);
      commit("setOrganization", {});
      h.removeItem("VOC_USER_TOKEN_REFRESH");
      h.removeItem("VOC_USER_TOKEN_EXPIRE_AT");
      h.removeItem("VOC_USER_TOKEN_SCOPE_1");
      h.removeItem("VOC_USER_TOKEN_SCOPE_2");
      commit("setUserScope", {});
      h.removeItem("VOC_CLIENT_TOKEN");
      h.removeItem("VOC_CLIENT_TOKEN_REFRESH");
      h.removeItem("VOC_CLIENT_TOKEN_EXPIRE_AT");
      h.removeItem("VOC_USER_FB_UID");
      streamer.socket.send(
        JSON.stringify({
          Type: "disconnect",
        }),
      );
      commit("setUser", false);
    },
    fetchLanguages({ commit }) {
      // commit("setLoading", true);
      commit("setLanguages", Constantes.languages);
      commit("updateLanguagesTranslation");
      // commit("setLoading", false);

      // api
      //   .Call({
      //     url: "/api/v1/dictionary/language",
      //     method: "get",
      //   })
      //   .then(
      //     (response) => {
      //       let resp = response || [];
      //       // resp = resp.map((lang)=>({...lang, localName: i18n.global.t("-raw-language-" + lang.locale) || lang.name}));
      //       commit("setLanguages", resp);
      //       commit("updateLanguagesTranslation");
      //       commit("setLoading", false);
      //     },
      //     (e) => {
      //       console.error("error languages: ", e);
      //     }
      //   );
    },
    fetchCurrenciesInTariff({ commit }) {
      return new Promise((resolve, reject) => {
        api
          .Call({
            url: "/billing/currencies_use_in_tariff",
            method: "get",
          })
          .then(
            (response) => {
              if (response == null) {
                response = [];
              }
              commit("setCurrenciesInTariff", response);
              resolve(response);
              return;
            },
            (e) => {
              console.error(
                "error-fc656023: fetchCurrenciesInTariff error: ",
                e,
              );
              reject(e);
              return;
            },
          );
      });
    },
    fetchTariffTable({ commit }) {
      return new Promise((resolve, reject) => {
        api
          .Call({
            url: "/billing/tariff_table",
            method: "get",
          })
          .then(
            (response) => {
              if (response == null) {
                response = {};
              }
              commit("setTariffTable", response);
              resolve(response);
              return;
            },
            (e) => {
              console.error("error-17b0ec0f: fetchTariffTable error: ", e);
              reject(e);
              return;
            },
          );
      });
    },
    fetchTariffPrices({ commit }, payload) {
      return new Promise((resolve, reject) => {
        api
          .Call({
            url: "/billing/tariff_prices/" + payload.currency_code,
            method: "get",
          })
          .then(
            (response) => {
              if (response == null) {
                response = [];
              }
              commit("setTariffPrices", response);
              resolve(response);
              return;
            },
            (e) => {
              console.error("error-bd6661e5: fetchTariffPrices error: ", e);
              reject(e);
              return;
            },
          );
      });
    },
    checkUsername({ commit }, payload) {
      const pattern = new RegExp("^[a-zA-Z0-9._-]{1,20}$");
      if (!pattern.test(payload)) {
        commit("setUsernameAvailable", false);
        return;
      }
      api
        .Call({
          url: "/api/v1/user/available/" + payload,
        })
        .then(
          (response) => {
            commit("setUsernameAvailable", response);
          },
          (e) => {
            console.error("checkUsername", e);
            commit("setUsernameAvailable", false);
          },
        );
    },
    fillOrganizationID({ commit }) {
      let organizationID = null;

      if (h.getItem("VOC_USER_ORGANIZATION_ID")) {
        organizationID = h.getItem("VOC_USER_ORGANIZATION_ID");
      }

      commit("setOrganizationID", organizationID);
    },
    fillUserScope({ commit }) {
      const mapScopes = {};

      if (h.getItem("VOC_USER_TOKEN_SCOPE_1")) {
        const scopes = h.getItem("VOC_USER_TOKEN_SCOPE_1").split(" ");
        for (var i = 0; i < scopes.length; i++) {
          mapScopes[scopes[i]] = true;
        }
      }

      if (h.getItem("VOC_USER_TOKEN_SCOPE_2")) {
        const scopes = h.getItem("VOC_USER_TOKEN_SCOPE_2").split(" ");
        for (var i = 0; i < scopes.length; i++) {
          mapScopes[scopes[i]] = true;
        }
      }

      commit("setUserScope", mapScopes);
    },
    getTTSVoices({ commit, getters, dispatch }) {
      if (h.getItem("VOC_USER_TOKEN")) {
        commit("setLoading", true);
        return api
          .Call({
            url: "/api/v1/tts/voices",
          })
          .then((response) => {
            commit("setTTSVoices", response);
          })
          .finally(() => {
            commit("setLoading", false);
          });
      } else {
        commit("setTTSVoices", null);
      }
    },
    getUser({ commit, getters, dispatch }) {
      if (h.getItem("VOC_USER_TOKEN")) {
        commit("setLoading", true);
        return api
          .Call({
            url: "/api/v1/user",
          })
          .then(
            (response) => {
              commit("setNewUser", false);
              commit("setUser", response);
              commit("setLoading", false);
              // console.debug("debug-0ca78d35: getUser", response);
            },
            (e) => {
              commit("setUser", false);
              commit("setLoading", false);
              dispatch("userSignOut");
              // navigator.geolocation.getCurrentPosition(
              //   position => {
              //     var user = getters.user;
              //     user.gpslocation =
              //       position.coords.latitude.toString() +
              //       ", " +
              //       position.coords.longitude.toString();
              //     commit("setUser", user);
              //   },
              //   function() {
              //     console.error("Location Access Denied");
              //   }
              // );
            },
          );
      } else {
        commit("setUser", null);
      }
    },
    getUserTariff({ commit, getters }) {
      if (h.getItem("VOC_USER_TOKEN")) {
        commit("setLoading", true);
        return api
          .Call({
            url: "/billing/user_subscription_current",
          })
          .then(
            (response) => {
              // fill capabilities for simplify
              let capabilities = {};
              for (
                let i = 0;
                i < response.TariffTable.Capabilities.length;
                i++
              ) {
                capabilities[response.TariffTable.Capabilities[i].ID] =
                  response.TariffTable.Capabilities[i];
                capabilities[response.TariffTable.Capabilities[i].ID].Value =
                  response.TariffTable.Values[0][i];
              }
              commit("setUserTariff", {
                tariff: response.TariffTable.Tariffs[0],
                capabilities: capabilities,
              });
              commit("setLoading", false);
              console.debug("debug-682a17d3: USER tariff: ", response);
            },
            (e) => {
              commit("setLoading", false);
              // console.error("getUserTariff", e);
              commit("setUserTariff", {});
            },
          );
      } else {
        commit("setUserTariff", {});
      }
    },
    getUserFirebase({ commit, dispatch }, payload) {
      commit("setLoading", true);
      commit("setError", false);
      var fbUUID = payload.user.uid;
      payload.user.getIdToken().then((fbToken) => {
        // DO not uncomment these, it will cause lots of client_token requests (5/25/22, 8:38 AM @ira)
        // commit('setTokenFirebase', fbToken)
        // h.removeItem("VOC_USER_TOKEN");
        // h.removeItem("VOC_USER_TOKEN_REFRESH");
        // h.removeItem("VOC_USER_TOKEN_EXPIRE_AT");
        // h.removeItem("VOC_USER_TOKEN_SCOPE_1");
        // h.removeItem("VOC_USER_TOKEN_SCOPE_2");
        // h.removeItem("VOC_USER_FB_UID");
        // commit("setUserScope", {});
        dispatch("userSignOut", false);
        api
          .Call({
            url: "/oauth2/token",
            data: {
              GrantType: "firebase",
              FirebaseToken: fbToken,
            },
            method: "post",
          })
          .then(
            (response) => {
              if (response.needusername || response.needconfirmrestore) {
                commit("setNewUser", {
                  needusername: response.needusername,
                  needconfirmrestore: response.needconfirmrestore,
                  username: "",
                  fbToken: fbToken,
                  photoURL: payload.user.photoURL,
                });
                commit("setLoading", false);
              } else {
                h.setItem("VOC_USER_TOKEN", response.access_token);
                h.setItem("VOC_USER_ROLE_IS_SYSTEM", response.RoleIsSystem);
                h.setItem("VOC_USER_ROLE_IS_ADMIN", response.RoleIsAdmin);
                h.setItem("VOC_USER_ORGANIZATION_ID", response.OrganizationID);
                h.setItem("VOC_USER_SIMPLE_UI", response.simple_ui);
                commit(
                  "setOrganizationID",
                  response.OrganizationID ? response.OrganizationID : null,
                );
                commit("setOrganization", {
                  ID: response.OrganizationID ? response.OrganizationID : null,
                });
                h.setItem("VOC_USER_TOKEN_REFRESH", response.refresh_token);
                h.setItem("VOC_USER_TOKEN_EXPIRE_AT", response.expire_at);
                h.setItem("VOC_USER_FB_UID", fbUUID);
                const scopes = response.scope.split(" ");
                const middle = scopes.length / 2;
                h.setItem(
                  "VOC_USER_TOKEN_SCOPE_1",
                  scopes.slice(0, middle).join(" "),
                );
                h.setItem(
                  "VOC_USER_TOKEN_SCOPE_2",
                  scopes.slice(middle, scopes.length).join(" "),
                );
                dispatch("getUser");
                dispatch("fillUserScope");
                streamer.socket.send(
                  JSON.stringify({
                    Token: response.access_token,
                    Type: "connect",
                  }),
                );
              }
            },
            (e) => {
              console.error(e);
              commit("setLoading", false);
              commit("setError", e);
            },
          );
      });
    },
    signUp({ commit, dispatch, getters }, payload) {
      commit("setLoading", true);
      commit("setError", false);

      h.removeItem("VOC_USER_TOKEN");
      h.removeItem("VOC_USER_ROLE_IS_SYSTEM");
      h.removeItem("VOC_USER_ROLE_IS_ADMIN");
      h.removeItem("VOC_USER_ORGANIZATION_ID");
      h.removeItem("VOC_USER_SIMPLE_UI");
      commit("setOrganizationID", null);
      commit("setOrganization", {});
      h.removeItem("VOC_USER_TOKEN_REFRESH");
      h.removeItem("VOC_USER_TOKEN_EXPIRE_AT");
      h.removeItem("VOC_USER_TOKEN_SCOPE_1");
      h.removeItem("VOC_USER_TOKEN_SCOPE_2");
      // h.removeItem("VOC_USER_FB_UID"); // don't remove! because we already signed in in firebase (here we sign in our database)

      commit("setUserScope", {});
      api
        .Call({
          url: "/oauth2/token",
          data: {
            GrantType: "firebase",
            FirebaseToken: payload.fbToken,
            Username: payload.username || "",
            Restoreuser: payload.restoreuser,
            CurrentLang: payload.currentlang,
            WorkLang: payload.worklang,
          },
          method: "post",
        })
        .then(
          (response) => {
            h.setItem("VOC_USER_TOKEN", response.access_token);
            h.setItem("VOC_USER_ROLE_IS_SYSTEM", response.RoleIsSystem);
            h.setItem("VOC_USER_ROLE_IS_ADMIN", response.RoleIsAdmin);
            h.setItem("VOC_USER_ORGANIZATION_ID", response.OrganizationID);
            h.setItem("VOC_USER_SIMPLE_UI", response.simple_ui);
            commit(
              "setOrganizationID",
              response.OrganizationID ? response.OrganizationID : null,
            );
            commit("setOrganization", {
              ID: response.OrganizationID ? response.OrganizationID : null,
            });
            h.setItem("VOC_USER_TOKEN_REFRESH", response.refresh_token);
            h.setItem("VOC_USER_TOKEN_EXPIRE_AT", response.expire_at);
            const scopes = response.scope.split(" ");
            const middle = scopes.length / 2;
            h.setItem(
              "VOC_USER_TOKEN_SCOPE_1",
              scopes.slice(0, middle).join(" "),
            );
            h.setItem(
              "VOC_USER_TOKEN_SCOPE_2",
              scopes.slice(middle, scopes.length).join(" "),
            );
            streamer.socket.send(
              JSON.stringify({
                Token: response.access_token,
                Type: "connect",
              }),
            );
            dispatch("getUser").then(() => {
              if (payload.photoURL && !getters.user.Asset.user_avatar) {
                function status(response) {
                  if (response.status >= 200 && response.status < 300) {
                    return Promise.resolve(response);
                  } else {
                    return Promise.reject(new Error(response.statusText));
                  }
                }

                function blob(response) {
                  return response.blob();
                }

                fetch(payload.photoURL)
                  .then(status)
                  .then(blob)
                  .then(function (data) {
                    commit("updateUpdatedUser", {
                      name: "ID",
                      value: getters.user.ID,
                    });
                    commit("setNewAvatar", data);
                    dispatch("userUpdateAvatar", {
                      ...getters.updatedUser,
                    }).then(() => {
                      dispatch("getUser");
                    });
                  })
                  .catch(function (error) {
                    console.error("error-bc24ffb4: request failed", error);
                  });
              }
            });
            dispatch("fillUserScope");
          },
          (e) => {
            console.error(e);
            commit("setLoading", false);
            commit("setError", e);
          },
        );
    },
    // setUser ({ commit, dispatch }, payload) {
    //   commit('setLoading', true)
    //   commit('setError', false)
    //   payload.getIdToken()
    //     .then(fbToken => {
    //       // console.log(fbToken)
    //       commit('setTokenFirebase', fbToken)
    //       vocAccount.GetTokenClient() // TODO: only call if not in cookies/storage
    //         .then(clToken => {
    //           commit('setTokenClient', clToken)
    //           vocAccount.GetTokenUser(fbToken, clToken)
    //             .then(usrToken => {
    //               commit('setTokenUser', usrToken)
    //             })
    //             .catch(e => {
    //               commit('setLoading', false)
    //               commit('setError', e)
    //               console.error('setUser > GetTokenUser', e)
    //             })
    //           vocAccount.GetUserLazy(fbToken, clToken) // TODO: Firebase overwrites name changes in settings22
    //             .then(user => {
    //               commit('setUser', user)
    //               commit('setLoading', false)
    //               // console.log('setUser', user)
    //               navigator.geolocation.getCurrentPosition(position => {
    //                 user.gpslocation = position.coords.latitude.toString() + ', ' + position.coords.longitude.toString()
    //               }, function () {
    //                 console.error('Location Access Denied')
    //               })
    //               // TODO: `languageid: navigator.language` needs to contain the UUID of the Language.
    //               // user.currentlang = new Array()
    //               // user.currentlang.push({languageid: navigator.language})
    //               dispatch('userUpdate', { ...user }) // TODO: this calls setUser again for no reason, fix
    //             })
    //             .catch(e => {
    //               commit('setLoading', false)
    //               commit('setError', e)
    //               console.error('setUser > GetUserLazy', e)
    //             })
    //         })
    //         .catch(e => {
    //           commit('setLoading', false)
    //           commit('setError', e)
    //           console.error('setUser > GetTokenClient', e)
    //         })
    //     })
    // },
    userDelete({ commit, dispatch, getters }) {
      var that = this;
      api.CallConfirmation(
        {
          // method: 'delete',
          // message: 'Do you really want to delete your account?'
          method: "warning",
          message: i18n.global.t("-raw-setting-enter-username"),
          messageDetails: [
            i18n.global.t("-raw-setting-delete-account-op-1"),
            [
              i18n.global.t("-raw-setting-delete-account-op-2"),
              i18n.global.t("-raw-setting-delete-account-op-3"),
              i18n.global.t("-raw-setting-delete-account-op-4"),
              i18n.global.t("-raw-setting-delete-account-op-5"),
            ],
          ],
          icon: "report",
          key: getters.user.username,
          buttonOk: "delete",
        },
        () => {
          return new Promise((resolve, reject) => {
            // commit('setLoading', true)
            api.CallUndo(
              {
                message: i18n.global.t("-raw-message-del-user"),
                ID: "user_delete",
              },
              () => {
                return api
                  .Call({
                    url: "/api/v1/user",
                    method: "delete",
                    show_error: true,
                  })
                  .then(
                    (result) => {
                      // commit('setLoading', false)
                      that.$router.push("/");
                      dispatch("userLogout");
                    },
                    (e) => {
                      // commit('setLoading', false)
                      console.error("error delete user", e);
                    },
                  );
              },
            );
            reject();
          });
        },
      );
    },
    userUpdateMailData({ commit, dispatch }, updatedUser) {
      // console.warn(updatedUser)
      commit("setLoading", true);
      return api
        .Call({
          url: "/mail/profile",
          method: "patch",
          data: {
            Main: updatedUser.MailMain,
            Chat: updatedUser.MailChat,
            Channel: updatedUser.MailChannel,
          },
        })
        .then(
          (response) => {},
          (e) => {
            commit("setLoading", false);
            commit("setError", { code: "whoops" });
            console.error("error-lol", "patchUser", e);
          },
        );
    },
    userUpdateData({ commit, dispatch }, updatedUser) {
      // console.warn(updatedUser)
      commit("setLoading", true);
      return api
        .Call({
          url: "/api/v1/user/userid/" + updatedUser.ID,
          method: "patch",
          data: updatedUser,
          show_error: true,
        })
        .then(
          (userResponse) => {
            commit("setUser", userResponse);
            commit("setLoading", false);
          },
          (e) => {
            commit("setLoading", false);
            commit("setError", { code: "whoops" });
            console.error("error-lol", "patchUser", e);
          },
        );
      // vocAccount.GetTokenUser(getters.getTokenFirebase, getters.getTokenClient)
      //   .then(usrToken => {
      //     commit('setTokenUser', usrToken)
      //     vocAccount.UpdateUser(updatedUser, usrToken)
      //       .then(data => {
      //         commit('setUser', data)
      //         commit('setLoading', false)
      //         // console.log('userUpdate', data)
      //       })
      //       .catch(e => {
      //         commit('setLoading', false)
      //         commit('setError', e)
      //         console.error('userUpdate', e)
      //       })
      //   })
    },
    userUpdateAvatar({ commit }, updatedUser) {
      // TODO: make saveAsset helper
      const formData = new FormData();
      formData.append("object_id", updatedUser.ID);
      formData.append("file_type", "user_avatar");
      formData.append("file", updatedUser.newAvatar);
      return api
        .Call({
          url: "/api/v1/asset",
          data: formData,
          method: "post",
        })
        .then(
          (postAssetResponse) => {
            commit("updateUpdatedUserAsset", {
              name: "user_avatar",
              value: [postAssetResponse.file_object_id],
            });
            commit("disposeNewAvatar");
          },
          (e) => {
            console.error(
              "settings/store.js" + "error-71bc03ae",
              "postAssetResponse",
              e,
            );
          },
        );
    },
    userUpdate({ dispatch }, updatedUser) {
      if (updatedUser.newAvatar) {
        dispatch("userUpdateAvatar", { ...updatedUser }).then(() => {
          dispatch("userUpdateMailData", { ...updatedUser }).then(
            dispatch("userUpdateData", { ...updatedUser }),
          );
        });
      } else {
        dispatch("userUpdateMailData", { ...updatedUser }).then(
          dispatch("userUpdateData", { ...updatedUser }),
        );
      }
    },
    userProvider({ commit, dispatch }, payload) {
      commit("setLoading", true);
      commit("setError", false);

      payload.setCustomParameters({
        prompt: "select_account",
      });

      console.debug(
        "info-1bd16d51: if it's a WebView, don't open a tab",
        is_webview(),
      );
      // let aaAuth = auth.signInWithRedirect(payload);
      // let aaAuth = auth.signInWithPopup(payload);
      // let aaAuth = is_webview()
      //   ? auth.signInWithRedirect(payload)
      //   : auth.signInWithPopup(payload);

      console.log("payload", payload);
      // debugger;

      signInWithPopup(auth, payload).then((r) => {
        // this function return result = null, so right now always get error
        // we don't need this method at all (see onAuthStateChanged function in boot - watch method there)
        // getRedirectResult(auth)
        //   .then((result) => {
        //     // This gives you a Google Access Token. You can use it to access Google APIs.
        //     let credential = null;
        //     switch (payload.providerId) {
        //       case "google.com":
        //         credential = GoogleAuthProvider.credentialFromResult(result);
        //         break;
        //       case "microsoft.com":
        //         credential = OAuthProvider.credentialFromResult(result);
        //         break;
        //       case "facebook.com":
        //         credential = FacebookAuthProvider.credentialFromResult(result);
        //         break;
        //       case "apple.com":
        //         credential = FacebookAuthProvider.credentialFromResult(result);
        //         break;
        //       default:
        //         console.error(
        //           "error-5461c03b: unknown provider id",
        //           payload.providerId,
        //           JSON.parse(JSON.stringify(payload)),
        //         );
        //     }
        //     const token = credential?.accessToken;
        //     // The signed-in user info.
        //     const user = result.user;
        //     // IdP data available using getAdditionalUserInfo(result)
        //     // ...
        //     commit("setLoading", false);
        //     console.log("info-9e4c59bb: Auth success", user, token, credential);
        //   })
        //   .catch((error) => {
        //     // const errorCode = error.code;
        //     // const errorMessage = error.message;
        //     // const email = error.customData.email;
        //     const credential = GoogleAuthProvider.credentialFromError(error);
        //     console.error(
        //       "error-af1906e5: Auth",
        //       error,
        //       // errorMessage,
        //       // email,
        //       credential,
        //     );
        //   });
      });

      // TODO: Why do we need this?
      // aaAuth
      //   .then((result) => {
      //     commit("setLoading", false);
      //     // dispatch("userSignOut", false);
      //   })
      //   .catch((err) => {
      //     if (err.code === "auth/account-exists-with-different-credential") {
      //       Dialog.create({
      //         message: err.message,
      //         title: "Sign in",
      //         cancel: true,
      //       })
      //         .onOk(() => {
      //           auth
      //             .fetchSignInMethodsForEmail(err.email)
      //             .then((methods) => {
      //               auth
      //                 .signInWithRedirect(getProviderForProviderId(methods[0]))
      //                 .then((result) => {
      //                   result.user
      //                     .linkWithCredential(err.credential)
      //                     .then((usercred) => {
      //                       commit("setLoading", false);
      //                     })
      //                     .catch((e) => {
      //                       Notify.create({
      //                         message: e.message,
      //                         color: "negative",
      //                       });
      //                       commit("setLoading", false);
      //                     });
      //                 })
      //                 .catch((e) => {
      //                   commit("setLoading", false);
      //                   commit("setError", e);
      //                 });
      //             })
      //             .catch((e) => {
      //               commit("setLoading", false);
      //               commit("setError", e);
      //             });
      //         })
      //         .onCancel(() => {
      //           commit("setLoading", false);
      //         });
      //     } else {
      //       commit("setLoading", false);
      //       commit("setError", err);
      //     }
      //   });
    },
    // userSignUp({ commit, dispatch }, payload) {
    //   commit("setLoading", true);
    //   commit("setError", false);
    //   firebase
    //     .auth()
    //     .createUserWithEmailAndPassword(payload.email, payload.password)
    //     .then((data) => {
    //       commit("setLoading", false);
    //       // dispatch('setUser', data.val())
    //     })
    //     .catch((error) => {
    //       commit("setLoading", false);
    //       commit("setError", error.message);
    //     });
    // },
    // userSignIn({ commit, dispatch }, payload) {
    //   commit("setLoading", true);
    //   commit("setError", false);
    //   firebase
    //     .auth()
    //     .signInWithEmailAndPassword(payload.email, payload.password)
    //     .then((data) => {
    //       commit("setLoading", false);
    //       // dispatch('setUser', data.val())
    //     })
    //     .catch((error) => {
    //       commit("setLoading", false);
    //       commit("setError", error.message);
    //     });
    // },
    userSignOut({ commit }, withFirebase) {
      if (withFirebase == null || withFirebase == undefined) {
        withFirebase = true;
      }
      if (withFirebase === true) {
        signOut(auth)
          .then(() => {
            console.log("info-de3c589e-success: signed out");
          })
          .catch((error) => {
            console.log("error-de3c589e-error: signed out");
          });
      }
      // clear Intercom session
      window.Intercom("shutdown");

      h.removeItem("VOC_USER_TOKEN");
      h.removeItem("VOC_USER_ROLE_IS_SYSTEM");
      h.removeItem("VOC_USER_ROLE_IS_ADMIN");
      h.removeItem("VOC_USER_ORGANIZATION_ID");
      h.removeItem("VOC_USER_SIMPLE_UI");
      commit("setOrganizationID", null);
      commit("setOrganization", {});
      h.removeItem("VOC_CLIENT_TOKEN");
      h.removeItem("VOC_CLIENT_TOKEN_REFRESH");
      h.removeItem("VOC_USER_TOKEN_REFRESH");
      h.removeItem("VOC_USER_TOKEN_SCOPE_1");
      h.removeItem("VOC_USER_TOKEN_SCOPE_2");
      commit("setUserScope", {});
      h.removeItem("VOC_CLIENT_TOKEN_EXPIRE_AT");
      h.removeItem("VOC_USER_TOKEN_EXPIRE_AT");
      h.removeItem("VOC_USER_FB_UID");
      streamer.socket.send(
        JSON.stringify({
          Type: "disconnect",
        }),
      );
      commit("setUser", null);
    },
  },
  getters: {
    user(state) {
      return state.user;
    },
    userTariff(state) {
      return state.userTariff;
    },
    newUser(state) {
      return state.newUser;
    },
    languages(state) {
      return state.languages;
    },
    languagesMapID(state) {
      return state.languages.reduce((acc, lang) => {
        acc[lang.ID] = lang;
        return acc;
      }, {});
    },
    languageUI(state) {
      return state.languageUI;
    },
    updatedUser(state) {
      return state.updatedUser;
    },
    usernameAvailable(state) {
      return state.usernameAvailable;
    },
    currenciesInTariff(state) {
      return state.currenciesInTariff;
    },
    tariffTable(state) {
      return state.tariffTable;
    },
    tariffPrices(state) {
      return state.tariffPrices;
    },
    ttsVoices(state) {
      return state.ttsVoices;
    },
    userAvatarURL(state) {
      const assetID = state.user?.Asset?.user_avatar;
      return state.updatedUser?.newAvatar
        ? URL.createObjectURL(state.updatedUser?.newAvatar, {
            oneTimeOnly: true,
          })
        : assetID
          ? process.env.PUBLIC_API_URL +
            "/api/v1/asset/file/user_avatar/" +
            assetID[0]
          : "";
    },
    goalLanguage(state) {
      return state.goalLanguage;
    },
    userLevel(state) {
      return state.level;
    },
  },
};

function getProviderForProviderId(providerId) {
  switch (providerId) {
    case auth.GoogleAuthProvider.PROVIDER_ID:
      return new auth.GoogleAuthProvider();
    case auth.FacebookAuthProvider.PROVIDER_ID:
      return new auth.FacebookAuthProvider();
    case auth.GithubAuthProvider.PROVIDER_ID:
      return new auth.GithubAuthProvider();
    case auth.TwitterAuthProvider.PROVIDER_ID:
      return new auth.TwitterAuthProvider();
    case auth.EmailAuthProvider.PROVIDER_ID:
      return new auth.EmailAuthProvider();
    default:
      throw new Error(`No provider implemented for ${providerId}`);
  }
}
