import api from "src/h/api.js";
// import h from "src/h/helpers.js"
// import { Notify } from "quasar";
import { v4 as uuidv4 } from "uuid";
import languages from "src/pages/edit/languages.vue";
import campuscard from "src/components/campuscard.vue";
import { i18n } from "src/boot/i18n.js";
import Lib from "src/h/helpers.js";

export default {
  emits: ["choose"],
  props: {
    view: {
      // {id}
      type: Object,
      default: function () {
        return {};
      },
    },
    data: {
      // {languages}
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  components: {
    languages,
    campuscard,
  },
  computed: {
    isSystem() {
      if (Lib.getItem("VOC_USER_ROLE_IS_SYSTEM")) {
        return true;
      }
      return false;
    },
  },
  data: () => ({
    id: uuidv4(),
    filterName: "",
    filterLanguages: [
      // "00000000-0000-0000-0000-000000000001",
      // "00000000-0000-0000-0000-000000000002",
      // "00000000-0000-0000-0000-000000000003",
    ],
    showSearchForm: false,
    filterSourceLanguages: false,
    ignoreSystemRole: false,
    foundList: {},
    isSearchLoading: false,
    isSearchScrollEnd: false,
    fnScrollDone: null, // q-infinite-scroll done function
    selectedItem: null,
  }),
  watch: {
    filterName: {
      handler(newVal, oldVal) {
        if (newVal !== undefined) {
          this.search();
        }
      },
    },
    selectedItem: {
      handler(newVal, oldVal) {
        if (newVal !== undefined) {
          this.$emit("choose", newVal);
        }
      },
    },
    // use on close language menu
    // filterLanguages: {
    //   handler(newVal, oldVal) {
    //     this.search();
    //   },
    // },
  },
  beforeMount() {
    if (this.data.languages) {
      this.filterLanguages.splice(0, 0, ...this.data.languages);
    }
    if (this.view.id) {
      this.id = this.view.id;
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {},
    search() {
      this.foundList = {};
      this.isSearchScrollEnd = false;
      // signal done loading previous q-infinite-scroll elements and reset
      if (this.fnScrollDone) this.fnScrollDone(true);
      this.$refs["foundScroll_" + this.id]?.reset();
      this.$refs["foundScroll_" + this.id]?.resume();
      // this.$refs["foundScroll_" + this.id]?.trigger(); // search will be twice!
    },
    toggleShowSearchForm() {
      this.showSearchForm = !this.showSearchForm;
    },
    searchNextPage(index, done) {
      if (done) this.fnScrollDone = done;
      if (this.isSearchScrollEnd === true) {
        if (done) {
          done(true);
        }
        return;
      }
      this.isSearchLoading = true;

      // source languages
      let sourceLanguageID = "";
      if (this.$store.getters.languages) {
        sourceLanguageID = this.$store.getters.languages.find(
          (val) => val.locale === i18n.global.locale,
        )?.ID;
      }
      let sourcelanguageids = [];
      if (this.$store.getters.user?.currentlang) {
        this.$store.getters.user?.currentlang.forEach((ch) => {
          sourcelanguageids.push(ch.languageid);
        });
      }

      let sourceLanguages = "";
      if (this.filterSourceLanguages) {
        sourceLanguages = "true";
      }
      let ignoreSystemRole = "";
      if (this.ignoreSystemRole) {
        ignoreSystemRole = "true";
      }

      let sourceLanguageGroup = 0;
      let offset = 0;

      if (this.foundList && Object.keys(this.foundList).length) {
        let last =
          this.foundList[
            Object.keys(this.foundList)[Object.keys(this.foundList).length - 1]
          ];
        sourceLanguageGroup = last.SourceLanguageGroup;

        Object.keys(this.foundList).forEach((key, index) => {
          if (this.foundList[key].SourceLanguageGroup === sourceLanguageGroup) {
            offset++;
          }
        });
      }

      let limit = 20;
      api
        .Call({
          url:
            "/api/v1/search/campus?q=" +
            encodeURI(this.filterName ? this.filterName : "") +
            "&languageID=" +
            this.filterLanguages.join(",") +
            "&limit=" +
            limit +
            "&offset=" +
            offset +
            // Object.getOwnPropertyNames(this.foundList).length +
            `&sourceLanguageGroup=` +
            sourceLanguageGroup +
            `&sourceLanguages=` +
            sourceLanguages +
            `&ignoreSystemRole=` +
            ignoreSystemRole +
            `&sourceLocaleLanguageID=${encodeURI(sourceLanguageID)}` +
            `&sourceLanguageIDs=${encodeURI(sourcelanguageids.toString())}`,
          method: "get",
          show_error: true,
        })
        .then(
          (response) => {
            if (!response) {
              response = [];
            }
            response.forEach((item) => {
              this.foundList[item.ID] = item;
            });
            if (response.length < limit) this.isSearchScrollEnd = true;
            if (this.fnScrollDone) this.fnScrollDone(this.isSearchScrollEnd);
            if (!this.foundList[this.selectedItem]) {
              this.selectedItem = null;
            } else {
              // if we choosen the item, after search we don't set selectedItem = null and set color too
              let pnt = this;
              let itemID = this.selectedItem;
              setTimeout(() => {
                if (pnt.selectedItem && pnt.$refs["campuscard_" + itemID]) {
                  pnt.$refs["campuscard_" + itemID][0].selectItem();
                }
              }, 100);
            }
            this.isSearchLoading = false;
          },
          (e) => {
            console.error(e);
            this.isSearchLoading = false;
          },
        );
    },
    selectLanguage(val) {
      this.filterLanguages.splice(0, this.filterLanguages.length, ...val);
      this.search();
    },
    choose(ID) {
      this.$emit("choose", ID);
    },
    resetFilterName() {
      this.filterName = "";
      this.$refs["filterName_" + this.id].focus();
    },
    choose(ID) {
      if (this.selectedItem !== ID) {
        if (
          this.selectedItem &&
          this.$refs["campuscard_" + this.selectedItem]
        ) {
          this.$refs["campuscard_" + this.selectedItem][0].unselectItem();
        }
        this.selectedItem = ID;
      }
    },
  },
};
