import Info from "src/pages/classroom/info.vue";
import Content from "src/pages/classroom/content.vue";
import api from "src/h/api.js";
import h from "src/h/helpers.js";
import { Notify } from "quasar";
import { v4 as uuidv4 } from "uuid";
import pickerimage from "src/components/picker-picture/picker.vue";

export default {
  components: { Info, Content, pickerimage },
  emits: ["notexists"],
  props: {
    data: {
      // {id}
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data: () => ({
    isloaded: false,
    isloadedAccess: false,
    classroom: {},
    streamerClassroomUser: null,
    streamerUpdate: null,
    streamerRead: null,
    streamerReadDetails: null,
    streamerDelete: null,
    paramsURL: {},
    sessionID: uuidv4(),
    contenttab: null,
    contentkey: 0,
    wsconnected: {},
    currentID: null,
    computedScrollHeight: {
      minHeight: "",
    },
  }),
  beforeMount() {
    this.parseParamsUrl();
    if (this.paramsURL["t"]) {
      let pu = this.paramsURL["t"];
      if (pu === "people") {
        // right now only 'people' tab can join (don't add full tab map because can have errors by access - tab miss)
        this.contenttab = pu;
        this.setParamsUrl({ t: null });
      }
    }
    // get currentID from param or from url
    let id = this.data?.id;
    if (!id) {
      id = this.$route.params.uid;
    }
    this.currentID = id;
  },
  mounted() {
    let pageElement = this.$refs.classroomRef;
    if (pageElement) {
      pageElement = pageElement.$el;
      this.computedScrollHeight.minHeight =
        window.innerHeight - pageElement.getBoundingClientRect().top + "px";
    }

    // NOTE: ref-5fe0ab61
    this.wsconnected = {};
    this.get();
    this.getAccess();
  },
  beforeUnmount() {
    this.$store.commit("setCurrentClassroomAccess", {});
    this.$store.commit("setCurrentClassroom", {});
    this.streamerClassroomUserDisconnect(200);
    this.streamerUpdateDisconnect(200);
    this.streamerReadDisconnect(200);
    this.streamerReadDetailsDisconnect(200);
    this.streamerDeleteDisconnect(200);
  },
  watch: {
    "$streamer.isConnect.value": {
      handler(newVal, oldVal) {
        if (newVal === true && oldVal === false) {
          // NOTE: ref-5fe0ab61
          this.wsconnected = {};
          this.sessionID = uuidv4();
          this.get(); // call reconnect tickettoken inside get()
          this.getAccess();
          // window.location.reload()
        }
      },
      immediate: false,
    },
    // $route(to, from) {
    //   if (to.path === from.path && to.name === "route-classroom") {
    //     this.parseParamsUrl();
    //     if (this.paramsURL["t"]) {
    //       setTimeout(() => {
    //         this.chooseTab(this.paramsURL["t"]), 200;
    //       });
    //       this.$refs.contentRef.syncTab(this.paramsURL["t"]);
    //     } else {
    //       setTimeout(() => {
    //         this.chooseTab(), 200;
    //       });
    //     }
    //   }
    // },
    "$store.getters.currentClassroomOperation": {
      handler(newVal, oldVal) {
        if (newVal !== null && oldVal === null) {
          if (newVal === "delete") {
            this.streamerDelete.send({
              Operation: "classroom_delete",
              Object: { ID: this.currentID },
            });
          }
          this.$store.commit("setCurrentClassroomOperation", null);
        }
      },
      immediate: true,
    },
    wsconnected: {
      handler(newVal, oldVal) {
        if (oldVal !== null && oldVal !== undefined) {
          if (Object.keys(newVal).length === 5) {
            this.isloaded = true;
          }
        }
      },
      immediate: false,
      deep: true,
    },
  },
  computed: {
    accessUpdate() {
      return this.$store.getters.currentClassroomAccess?.update;
    },
    isSimpleUI() {
      return h.getItem("VOC_USER_SIMPLE_UI") === true;
    },
  },
  methods: {
    get() {
      if (this.$streamer.isConnect.value === false) {
        return;
      }
      this.isloaded = false; // important if 'reload' page (get data by api after reconnect)
      api
        .Call({
          url: "/api/v1/classroom/" + this.currentID,
          show_error: true,
        })
        .then(
          (result) => {
            if (!result.Classroom?.ID) {
              // classroom not exists
              this.$emit("notexists", this.currentID);
              return;
            }
            this.classroom = result;
            this.$store.commit("setCurrentClassroom", {
              ID: result.Classroom.ID,
              Name: result.Classroom.Name,
            });
            this.streamerClassroomUserDisconnect();
            this.streamerClassroomUserConnect();
          },
          (e) => {
            this.isloaded = true;
          },
        );
    },
    getAccess() {
      api
        .Call({
          url: "/api/v1/share/access/classroom/" + this.currentID,
        })
        .then(
          (result) => {
            this.streamerUpdateDisconnect();
            this.streamerReadDisconnect();
            this.streamerReadDetailsDisconnect();
            this.streamerDeleteDisconnect();
            this.streamerUpdateConnect();
            this.streamerReadConnect();
            this.streamerReadDetailsConnect();
            this.streamerDeleteConnect();
            // if (!result?.update) {
            //   this.streamerUpdateConnect();
            //   this.streamerReadConnect();
            //   this.streamerReadDetailsConnect();
            //   this.streamerDeleteConnect();
            // } else
            if (!result?.update) {
              this.contenttab = "plans";
            }
            this.$store.commit("setCurrentClassroomAccess", result);
            this.isloadedAccess = true;
          },
          (e) => {},
        );
    },

    streamerClassroomUserConnect() {
      var lnk = this;
      lnk.streamerClassroomUser = lnk.$streamer.addTicket(
        "classroom_user",
        this.$store.getters.user?.ID,
        function (obj, sessionID) {
          switch (obj.Operation) {
            case "update_permission_user":
              // important return, we subscribed for all classrooms updated, need only current
              if (obj.Object.Classroom.ID !== lnk.classroom.Classroom.ID) {
                return;
              }
              h.CopyObject(obj.Object.Classroom, lnk.classroom.Classroom);
              if (
                lnk.classroom.Confirmation === undefined ||
                lnk.classroom.Confirmation === null
              ) {
                lnk.classroom.Confirmation = {};
              }
              h.CopyObject(obj.Object.Confirmation, lnk.classroom.Confirmation);
              lnk.getAccess(); // here obj.Object.Confirmation not the same as access (for example 'update'=true, but access = false)
              if (lnk.$refs?.infoRef) {
                lnk.$refs?.infoRef.changingStatus(false, obj);
              }
              break;
          }
        },
        function (obj, lastSend, lastOp) {
          let res = JSON.parse(obj);
          if (lastOp == "generate_ticket_token") {
            lnk.wsconnected["classroom_user"] = true;
          } else if (lastOp == "message") {
            switch (lastSend.Operation) {
              case "cancel_join":
              case "confirm_join":
              case "request_join":
                lnk.$refs?.infoRef.changingStatus(false);
              default:
                Notify.create({
                  group: true,
                  timeout: 10000,
                  message:
                    res?.ErrCode === "ret-2000"
                      ? res?.Err
                      : lnk.$t("-raw-error-occurred"),
                  color: "1",
                  textColor: "n",
                  classes: "round-both q-ml-lg q-mb-sm",
                  position: "bottom",
                });
            }
          }
        },
        this.sessionID,
        function () {
          lnk.wsconnected["classroom_user"] = false;
        },
      );
    },
    streamerClassroomUserDisconnect(tm) {
      let lnk = this;
      if (tm > 0) {
        setTimeout(() => {
          if (lnk.streamerClassroomUser !== null) {
            lnk.streamerClassroomUser.removeTicket();
          }
        }, tm);
      } else {
        if (lnk.streamerClassroomUser !== null) {
          lnk.streamerClassroomUser.removeTicket();
        }
      }
    },
    saveStatus(obj, method) {
      this.streamerClassroomUser.send({
        Operation: method,
        Object: obj,
      });
    },
    streamerUpdateConnect() {
      var lnk = this;
      lnk.streamerUpdate = lnk.$streamer.addTicket(
        "classroom_update",
        this.currentID,
        null,
        function (obj, lastSend, lastOp) {
          let res = JSON.parse(obj);
          if (lastOp == "generate_ticket_token") {
            lnk.wsconnected["classroom_update"] = true;
          } else if (lastOp == "message") {
            Notify.create({
              group: true,
              timeout: 10000,
              message:
                res?.ErrCode === "ret-2000"
                  ? res?.Err
                  : lnk.$t("-raw-error-occurred"), // + " Can't save classroom.",
              color: "1",
              textColor: "n",
              classes: "round-both q-ml-lg q-mb-sm",
              position: "bottom",
            });
          }
        },
        this.sessionID,
        function () {
          lnk.wsconnected["classroom_update"] = false;
        },
      );
    },
    streamerUpdateDisconnect(tm) {
      let lnk = this;
      if (tm > 0) {
        setTimeout(() => {
          if (lnk.streamerUpdate !== null) {
            lnk.streamerUpdate.removeTicket();
          }
        }, tm);
      } else {
        if (lnk.streamerUpdate !== null) {
          lnk.streamerUpdate.removeTicket();
        }
      }
    },
    streamerReadConnect() {
      var lnk = this;
      lnk.streamerRead = lnk.$streamer.addTicket(
        "classroom_read",
        this.currentID,
        function (obj, sessionID) {
          switch (obj.Operation) {
            case "save_classroom":
              if (!sessionID || sessionID != this.sessionID) {
                lnk.$refs.infoRef.sync(obj.Object, "Classroom");
                lnk.$store.commit("setCurrentClassroom", {
                  ID: obj.Object.ID,
                  Name: obj.Object.Name,
                });
              }
              break;
            case "save_classroom_auto_join":
              if (!sessionID || sessionID != this.sessionID) {
                lnk.$refs.infoRef.syncAutoJoin(obj.Object);
              }
              break;
            case "save_languages":
              if (!sessionID || sessionID != this.sessionID) {
                lnk.$refs.infoRef.sync(obj.Object, "LanguageIDs");
              }
              lnk.$refs.contentRef.sync(obj.Object, "save_languages");
              break;
            case "delete_classroom":
              if (lnk.$route.name === "route-classroom") {
                lnk.$router.push("/authoring/classrooms");
              }
              break;
            case "classroom_picture_changed":
              // where is no sessionID for http, so reload any time
              // if (!sessionID || sessionID != this.sessionID) {
              lnk.$refs.infoRef.syncimg("picture");
              // }
              break;
            case "classroom_banner_changed":
              lnk.$refs.infoRef.syncimg("banner");
              break;
          }
        },
        function (obj, lastSend, lastOp) {
          // let res = JSON.parse(obj);
          if (lastOp == "generate_ticket_token") {
            lnk.wsconnected["classroom_read"] = true;
          }
        },
        this.sessionID,
        function () {
          lnk.wsconnected["classroom_read"] = false;
        },
      );
    },
    streamerReadDisconnect(tm) {
      let lnk = this;
      if (tm > 0) {
        setTimeout(() => {
          if (lnk.streamerRead !== null) {
            lnk.streamerRead.removeTicket();
          }
        }, tm);
      } else {
        if (lnk.streamerRead !== null) {
          lnk.streamerRead.removeTicket();
        }
      }
    },
    streamerReadDetailsConnect() {
      var lnk = this;
      lnk.streamerReadDetails = lnk.$streamer.addTicket(
        "classroom_read_details",
        this.currentID,
        function (obj, sessionID) {
          switch (obj.Operation) {
            case "update_permission_user":
              // if (!sessionID || sessionID != this.sessionID) {
              if (lnk.$refs.contentRef) {
                lnk.$refs.contentRef.sync(obj.Object, "people_member");
              }
              // }
              break;
            case "update_classroom_campus":
              lnk.$refs.contentRef.sync(obj.Object, "classroom_campus");
              break;
            case "create_classroom_plan":
              lnk.$refs.contentRef.sync(
                obj.Object,
                "classroom_plan_created",
                !sessionID || sessionID != this.sessionID,
              );
              break;
            case "upsert_classroom_plan":
              lnk.$refs.contentRef.sync(
                obj.Object,
                "classroom_plan_updated",
                !sessionID || sessionID != this.sessionID,
              );
              break;
            case "delete_classroom_plan":
              lnk.$refs.contentRef.sync(
                obj.Object,
                "classroom_plan_deleted",
                !sessionID || sessionID != this.sessionID,
              );
              break;
            case "finish_classroom_plan":
              lnk.$refs.contentRef.sync(
                obj.Object,
                "classroom_plan_finished",
                !sessionID || sessionID != this.sessionID,
              );
              break;
            case "create_campus_plan_element":
              lnk.$refs.contentRef.sync(
                obj.Object,
                "campus_plan_element_created",
                !sessionID || sessionID != this.sessionID,
              );
              break;
            case "upsert_campus_plan_element":
              lnk.$refs.contentRef.sync(
                obj.Object,
                "campus_plan_element_updated",
                !sessionID || sessionID != this.sessionID,
              );
              break;
            case "delete_campus_plan_element":
              lnk.$refs.contentRef.sync(
                obj.Object,
                "campus_plan_element_deleted",
                !sessionID || sessionID != this.sessionID,
              );
              break;
            case "upsert_campus_plan":
              lnk.$refs.contentRef.sync(
                obj.Object,
                "campus_plan_updated",
                !sessionID || sessionID != this.sessionID,
              );
              break;
          }
        },
        function (obj, lastSend, lastOp) {
          // let res = JSON.parse(obj);
          if (lastOp == "generate_ticket_token") {
            lnk.wsconnected["classroom_read_details"] = true;
          }
        },
        this.sessionID,
        function () {
          lnk.wsconnected["classroom_read_details"] = false;
        },
      );
    },
    streamerReadDetailsDisconnect(tm) {
      // streamerReadDetails maybe null if unmount before create streamerReadDetails
      // but if create 'this' inside setTimeout - it's will be pause clear data/unmount from this component
      // let lnk = this;
      // setTimeout(() => {
      //   if (lnk.streamerReadDetails !== null) {
      //     lnk.streamerReadDetails.removeTicket();
      //   }
      // }, 1000);
      let lnk = this;
      if (tm > 0) {
        setTimeout(() => {
          if (lnk.streamerReadDetails !== null) {
            lnk.streamerReadDetails.removeTicket();
          }
        }, tm);
      } else {
        if (lnk.streamerReadDetails !== null) {
          lnk.streamerReadDetails.removeTicket();
        }
      }
    },
    streamerDeleteConnect() {
      var lnk = this;
      lnk.streamerDelete = lnk.$streamer.addTicket(
        "classroom_delete",
        this.currentID,
        null,
        function (obj, lastSend, lastOp) {
          let res = JSON.parse(obj);
          if (lastOp == "generate_ticket_token") {
            lnk.wsconnected["classroom_delete"] = true;
          } else if (lastOp == "message") {
            Notify.create({
              group: true,
              timeout: 10000,
              message:
                res?.ErrCode === "ret-2000"
                  ? res?.Err
                  : lnk.$t("-raw-error-occurred"), // + " Can't delete classroom.",
              color: "1",
              textColor: "n",
              classes: "round-both q-ml-lg q-mb-sm",
              position: "bottom",
            });
          }
        },
        this.sessionID,
        function () {
          lnk.wsconnected["classroom_delete"] = false;
        },
      );
    },
    streamerDeleteDisconnect(tm) {
      let lnk = this;
      if (tm > 0) {
        setTimeout(() => {
          if (lnk.streamerDelete !== null) {
            lnk.streamerDelete.removeTicket();
          }
        }, tm);
      } else {
        if (lnk.streamerDelete !== null) {
          lnk.streamerDelete.removeTicket();
        }
      }
    },
    save(obj, method) {
      this.streamerUpdate.send({
        Operation: method,
        Object: obj,
      });
    },
    // chooseTab(tab) {
    //   if (tab !== null && tab !== undefined) {
    //     if (tab === "plans" && !this.paramsURL["tp"]) {
    //       this.setParamsUrl({ t: tab, tp: "current" });
    //     } else {
    //       this.setParamsUrl({ t: tab });
    //     }
    //   } else {
    //     this.setParamsUrl({ t: "" });
    //   }
    // },
    parseParamsUrl() {
      if (!this.$router.currentRoute.value.query) {
        return;
      }

      this.paramsURL = {};
      var qr = this.$router.currentRoute.value.query;
      for (var name in qr) {
        this.paramsURL[name] = qr[name];
      }
    },
    setParamsUrl(params) {
      const query = Object.assign({}, this.$route.query);

      for (var name in params) {
        this.paramsURL[name] = encodeURIComponent(params[name]);
      }
      for (var name in this.paramsURL) {
        if (this.paramsURL[name]?.length > 0) {
          if (this.paramsURL[name] == "null") {
            delete query[name];
          } else {
            query[name] = this.paramsURL[name];
          }
        } else {
          query[name] = "";
        }
      }

      this.$router.push({ query });
    },
    // setParamsUrl(params) {
    //   const query = Object.assign({}, this.$route.query);

    //   for (var name in params) {
    //     this.paramsURL[name] = encodeURIComponent(params[name]);
    //   }
    //   for (var name in this.paramsURL) {
    //     if (this.paramsURL[name]?.length > 0) {
    //       query[name] = this.paramsURL[name];
    //     } else {
    //       query[name] = "";
    //     }
    //   }

    //   // NOTE: If we want to use back and forward navigation t needs to be analyzed more thoroughly
    //   if (query["t"] != this.$route.query["t"]) {
    //     if (this.$route.name === "route-classroom" && !this.$route.query["t"]) {
    //       this.$router.replace({ query });
    //     } else {
    //       this.$router.push({ query });
    //     }
    //   }
    // },
  },
};
