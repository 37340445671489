<template>
  <div
    class="q-pa-sm full-height full-width column no-wrap itemcard bg-0"
    :class="{
      selectedcard: selected === true,
      hovercard: over === true && selected === false,
    }"
    style="display: inline-block; border-radius: 1rem; max-height: 32rem"
    @click.stop="selectItem()"
    @mouseover="overItem()"
    @mouseleave="leaveItem()"
  >
    <div
      :style="$q.screen.gt.sm ? 'font-size: 4rem' : 'font-size: 2rem'"
      class="row text-9"
    >
      <div
        class="col column no-wrap col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-2"
        style="
          font-size: 4rem;
          font-weight: bold;
          overflow: hidden;
          white-space: nowrap;
        "
      >
        <q-avatar
          style="border-radius: 0rem; height: 3rem; width: 3rem"
          size="3rem"
          color="1"
        >
          <pickerimage
            :data="{
              uid: 'campus_card_img_' + item.ID,
              objectid: item.ID,
              objecttype: 'campus_picture',
            }"
            :view="{
              onlyshow: true,
            }"
            class="glassy-8 bg-transparent full-width"
            style="border-style: solid; border-color: var(--q-0)"
          />
        </q-avatar>
      </div>
      <div
        class="col column no-wrap col-xl-10 col-lg-10 col-md-10 col-sm-10 col-xs-10 column q-px-sm text-9"
        style="
          font-size: 1.7rem;
          font-weight: bold;
          overflow: hidden;
          white-space: nowrap;
          text-stroke: 1px var(--q-0);
          text-fill-color: var(--q-9);
          -webkit-text-stroke: 1px var(--q-0);
          -webkit-text-fill-color: var(--q-9);
          paint-order: stroke fill;
        "
      >
        <q-scroll-area
          class="row full-height items-center justify-center full-width"
        >
          <div class="row text-no-wrap" style="height: 2rem">
            {{ item.Name }}
          </div>
          <q-tooltip
            anchor="top right"
            class="mytooltip round-both ubuntu bg-0 text-v row items-center q-px-md"
            self="bottom right"
            transition-hide="jump-left"
            transition-show="jump-right"
          >
            {{ item.Name }}
          </q-tooltip>
        </q-scroll-area>
      </div>
    </div>
    <div class="row">
      <div
        class="col text-8 ubuntu row q-mr-sm"
        style="font-size: 0.8rem; font-weight: normal"
      >
        <div
          class="col column items-start q-mx-xs text-4"
          style="overflow: hidden"
        >
          <div
            v-if="
              item.LanguageIDs &&
              Array.isArray(item.LanguageIDs) &&
              item.LanguageIDs.length > 0
            "
          >
            <span style="font-weight: bold"
              >{{
                $t("-raw-languages") === "-raw-languages"
                  ? "Languages"
                  : $t("-raw-languages")
              }}:
            </span>
            <template
              v-for="(lng1, n1) in item.LanguageIDs"
              :key="n1 + 'campus_languages_list1'"
            >
              <template
                v-for="(lng2, n2) in $store.getters.languages"
                :key="n2 + 'campus_languages_list2'"
              >
                <template v-if="lng1 === lng2.ID"> {{ lng2.name }}; </template>
              </template>
            </template>
          </div>
          <div style="white-space: nowrap">
            <span style="font-weight: bold">{{ $t("-raw-members") }}: </span>
            {{ item.Members }}
          </div>
          <div style="white-space: nowrap">
            <span style="font-weight: bold">
              {{ $t("-raw-plans") }}: {{ item.Plans }}</span
            >
          </div>
        </div>
        <div class="row items-end">
          <div v-if="item.Rating">
            <q-icon name="star" />
            {{ item.Rating.toFixed(1)
            }}<span style="font-size: 0.6rem">/{{ item.CountRating }}</span>
            <q-tooltip
              anchor="top middle"
              self="bottom middle"
              class="bg-4 text-8 round-both"
              transition-show="jump-up"
              transition-hide="jump-down"
            >
              <b
                >{{ $t("-raw-challenge-rated-by") }} {{ item.CountRating }}
                {{ $t("-raw-challenge-rated-by-users") }}</b
              >
            </q-tooltip>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-end text-8" v-if="canedit">
      <q-btn
        @click.stop="this.$router.push('/campus/' + item.ID)"
        size="0.5rem"
        round
        flat
        color="v"
        class="bg-2"
        icon="edit"
        style="width: 1rem; height: 1rem; position: relative"
      >
        <q-tooltip
          anchor="top middle"
          self="bottom middle"
          class="bg-2 text-8 round-both"
          transition-show="jump-up"
          transition-hide="jump-down"
        >
          <b>{{ $t("-raw-campuscard-edit") }}</b>
        </q-tooltip>
      </q-btn>
    </div>
  </div>
</template>

<style scoped>
.itemcard {
  cursor: pointer;
  border: 2px solid var(--q-1);
  user-select: none;
}
.hovercard {
  border: 2px dashed var(--q-v);
}
.selectedcard {
  border: 2px solid var(--q-p);
}
</style>

<script>
import pickerimage from "src/components/picker-picture/picker.vue";

export default {
  emits: ["choose"],
  props: ["item", "canedit"],
  components: {
    pickerimage,
  },
  data: () => ({
    selected: false,
    over: false,
  }),
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    selectItem() {
      this.selected = true;
      this.$emit("choose", this.item.ID);
    },
    unselectItem() {
      this.selected = false;
    },
    overItem() {
      this.over = true;
    },
    leaveItem() {
      this.over = false;
    },
  },
};
</script>
