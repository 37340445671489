import classroompage from "src/pages/classroom/index.vue";
import api from "src/h/api.js";
import { v4 as uuidv4 } from "uuid";
import { Notify } from "quasar";
import Classrooms from "src/pages/classrooms/index.vue";

export default {
  components: {
    classroompage,
    Classrooms,
  },
  data() {
    return {
      loaded: false,
      sessionID: null, // uuidv4(),
      streamerObject: null,
      classrooms: [],
      currentClassroomID: null,
      showFullPanel: false,
      clpgkey: 0,
      paramsURL: {},
      classroomJoinByCodeDialog: false,
      classroomJoinByCode: null,
      classroomJoinByCodeLoading: false,
      joinclassroomdialog: false,
      joinclassroomdialogMax: true,
      classroomJoinChosen: null,
    };
  },
  watch: {
    "$streamer.isConnect.value": {
      handler(newVal, oldVal) {
        if (
          newVal === true &&
          oldVal === false &&
          this.userID !== undefined &&
          this.userID !== null
        ) {
          this.streamerConnect();
        }
      },
      immediate: false,
    },
    userID: {
      handler(newVal, oldVal) {
        if (
          newVal !== undefined &&
          newVal !== null &&
          this.$streamer.isConnect.value == true &&
          this.streamerObject == null
        ) {
          this.streamerConnect();
        }
      },
      immediate: false,
    },
    classroomJoinByCodeDialog: {
      handler(newVal, oldVal) {
        if (
          newVal == true &&
          (oldVal == false || oldVal == null || oldVal == undefined)
        ) {
          this.classroomJoinByCodeLoading = false;
          this.classroomJoinByCode = null;

          let lnk = this;
          setTimeout(() => {
            if (lnk.$refs.inputCodeJoinClassroomMy) {
              lnk.$refs.inputCodeJoinClassroomMy.focus();
            }
            100;
          });
        }
      },
      immediate: false,
    },
    $route(to, from) {
      if (
        to.path === from.path &&
        (to.name === "-raw-route-user-classrooms" ||
          to.name === "route-main-page")
      ) {
        let lnk = this;
        setTimeout(() => {
          lnk.checkUrlClassroomID();
          200;
        });
      }
    },
  },
  mounted() {
    if (
      this.userID !== undefined &&
      this.userID !== null &&
      this.$streamer.isConnect.value == true
    ) {
      this.streamerConnect();
    }
  },
  beforeUnmount() {
    this.streamerDisconnect(200);
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    userID() {
      return this.$store.getters.user?.ID;
    },
    dynamicLeftWidth() {
      const screen = this.$q.screen;

      if (this.showFullPanel) {
        if (screen.xs) {
          // smallest screens (smartphones)
          return { width: "100%" };
        } else if (screen.sm) {
          // small tablet
          return { width: "40%" };
        } else if (screen.md) {
          // tablet
          return { width: "30%" };
        } else if (screen.lg) {
          // notebook
          return { width: "20%" };
        } else if (screen.xl) {
          // biggest screens
          return { width: "20%" };
        }
      } else {
        return { width: "90px" };
      }

      // if errors
      return { width: "100%" };
    },
    userlanguages() {
      if (this.user) {
        let res = [];
        this.user.worklang.forEach(function (sbitem, inx) {
          res.push(sbitem.languageid);
        });
        return res;
      }
      return [];
    },
  },
  methods: {
    classroomJoinConfirm() {
      if (!this.classroomJoinChosen) return;
      this.streamerObject.send({
        Operation: "request_join",
        Object: {
          ObjectID: this.classroomJoinChosen,
          NeedReturnExists: true,
        },
      });
    },
    classroomChooseFromList(ID) {
      this.classroomJoinChosen = ID;
    },
    parseParamsUrl() {
      if (!this.$router.currentRoute.value.query) {
        return;
      }

      this.paramsURL = {};
      var qr = this.$router.currentRoute.value.query;
      for (var name in qr) {
        this.paramsURL[name] = qr[name];
      }
    },
    setParamsUrl(params) {
      const query = Object.assign({}, this.$route.query);

      for (var name in params) {
        if (params[name] !== null) {
          this.paramsURL[name] = encodeURIComponent(params[name]);
        } else if (name in this.paramsURL) {
          delete this.paramsURL[name];
          if (name in query) {
            delete query[name];
          }
        }
      }
      for (var name in this.paramsURL) {
        if (this.paramsURL[name]?.length > 0) {
          query[name] = this.paramsURL[name];
        } else {
          query[name] = "";
        }
      }

      // NOTE: If we want to use back and forward navigation clid needs to be analyzed more thoroughly
      if (query["clid"] !== this.$route.query["clid"]) {
        if (
          (this.$route.name === "-raw-route-user-classrooms" ||
            this.$route.name === "route-main-page") &&
          !this.$route.query["clid"]
        ) {
          this.$router.replace({ query });
        } else {
          this.$router.push({ query });
        }
      } else {
        this.$router.push({ query });
      }
    },
    enterClassroomByCode() {
      this.classroomJoinByCodeLoading = true;
      api
        .Call({
          url: "/api/v1/classroom/join_by_code/" + this.classroomJoinByCode,
          method: "post",
          show_error: true,
        })
        .then(
          (result) => {
            // note: ref-719d1def:
            if (result.NewJoin === true && result.FocusOrganizationID) {
              this.$store.commit(
                "setUserFocusOrganizationID",
                result.FocusOrganizationID,
              );
            }
            this.selectCurrentClassroom(result.ClassroomID);
          },
          (e) => {
            this.classroomJoinByCodeLoading = false;
          },
        );
    },
    selectCurrentClassroom(ID) {
      if (this.currentClassroomID !== ID) {
        this.currentClassroomID = ID;
        this.classroomJoinByCodeDialog = false;
        this.clpgkey++;
        this.setParamsUrl({ clid: this.currentClassroomID });
      } else if (!this.paramsURL["clid"]) {
        // actual if open main-page by click on logo
        this.setParamsUrl({ clid: this.currentClassroomID });
      }
    },
    selectClassroomJoinByCodeDialog() {
      this.currentClassroomID = null;
      this.classroomJoinByCodeDialog = true;
      // this.setParamsUrl({ clid: null });
    },
    classroomNotExists(ID) {
      if (this.paramsURL["clid"]) {
        if (this.paramsURL["clid"] == ID) {
          this.setParamsUrl({ clid: null });
        }
      }
    },
    checkUrlClassroomID() {
      this.parseParamsUrl();
      let idExists = false;
      if (this.paramsURL["clid"]) {
        for (let i = 0; i < this.classrooms.length; i++) {
          if (this.classrooms[i].Classroom.ID == this.paramsURL["clid"]) {
            this.selectCurrentClassroom(this.classrooms[i].Classroom.ID);
            idExists = true;
            break;
          }
        }
        // if (!idExists) {
        //   this.setParamsUrl({ clid: null });
        // }
        // we can open by url any available classroom (not deleted with exists id)
        if (!idExists) {
          this.selectCurrentClassroom(this.paramsURL["clid"]);
          idExists = true;
        }
      }
      if (!idExists) {
        if (this.classrooms.length > 0) {
          // by default select first classroom in list
          this.selectCurrentClassroom(this.classrooms[0].Classroom.ID);
          idExists = true;
        }
      }
      if (!idExists) {
        this.selectClassroomJoinByCodeDialog();
      }
    },
    toggleShowFullPanel() {
      this.showFullPanel = !this.showFullPanel;
    },
    streamerConnect() {
      var lnk = this;
      lnk.sessionID = uuidv4();
      lnk.streamerDisconnect();
      lnk.streamerObject = lnk.$streamer.addTicket(
        "classroom_user",
        this.$store.getters.user?.ID,
        function (obj, sessionID) {
          switch (obj.Operation) {
            case "get_list":
              let currentClassrooms = [];
              if (obj.Object !== null && obj.Object !== undefined) {
                currentClassrooms = obj.Object;
                // // here show only current classrooms and waiting confirms (rejected don't show)
                // // note: ref-9264d359. already selected in backend
                // obj.Object.forEach((obj_) => {
                //   if (
                //     !(
                //       obj_.Confirmation &&
                //       (obj_.Confirmation?.Status === "rejected" ||
                //         obj_.Confirmation?.Status == "canceled")
                //     )
                //   ) {
                //     currentClassrooms.push(obj_);
                //   }
                // });
              }
              lnk.classrooms.splice(
                0,
                lnk.classrooms.length,
                ...currentClassrooms,
              );
              lnk.loaded = true;
              lnk.checkUrlClassroomID();
              break;
            case "update_permission_user":
              // note: ref-de9db833:
              if (
                obj &&
                (obj.Object?.Confirmation?.Status == "canceled" ||
                  obj.Object?.Confirmation?.Status == "rejected") &&
                obj.Object?.Classroom.OrganizationID ===
                  lnk.$store.getters.user.FocusOrganizationID
              ) {
                lnk.$store.commit("setUserFocusOrganizationID", null);
              }
              lnk.classroomChanged(
                obj.Object,
                // here show only current classrooms and waiting confirms (rejected don't show)
                // note: ref-9264d359
                obj.Object.Confirmation &&
                  (obj.Object.Confirmation?.Status === "rejected" ||
                    obj.Object.Confirmation?.Status == "canceled"),
              );
              break;
            case "create_classroom":
              lnk.classroomChanged({ Classroom: obj.Object });
              break;
            case "delete_classroom":
              lnk.classroomChanged({ Classroom: { ID: obj.Object } }, true);
              break;
            case "request_join":
              if (obj.Object.AlreadyIsMember === true) {
                lnk.selectCurrentClassroom(obj.Object.ObjectID);
                lnk.classroomJoinChosen = null;
              }
              break;
          }
        },
        function (obj, lastSend, lastOp) {
          let res = JSON.parse(obj);
          if (lastOp == "generate_ticket_token") {
            // lnk.loaded = false;
          } else if (lastOp == "message") {
            switch (lastSend?.Operation) {
              case "get_list":
                Notify.create({
                  group: true,
                  timeout: 10000,
                  message:
                    res?.ErrCode === "ret-2000"
                      ? res?.Err
                      : lnk.$t("-raw-error-occurred"),
                  color: "1",
                  textColor: "n",
                  classes: "round-both q-ml-lg q-mb-sm",
                  position: "bottom",
                });
                break;
            }
          }
        },
        lnk.sessionID,
        function () {
          lnk.streamerObject.send({
            Operation: "get_list",
            Object: {},
          });
        },
      );
    },
    streamerDisconnect(tm) {
      // important use `this` inside  setTimeout - actual in some cases:
      // streamerDisconnect call if streamerObject = null, but will be not empty after mounted end
      // it's reproduce if create and close component very quick
      let lnk = this;
      if (tm > 0) {
        setTimeout(() => {
          if (lnk.streamerObject !== null) {
            lnk.streamerObject.removeTicket();
          }
        }, tm);
      } else {
        if (lnk.streamerObject !== null) {
          lnk.streamerObject.removeTicket();
        }
      }
    },
    classroomChanged(obj, needDelete) {
      // add / update / delete
      const elementIndex = this.classrooms.findIndex(
        (item) => item.Classroom.ID === obj.Classroom.ID,
      );
      if (needDelete && elementIndex !== -1) {
        this.classrooms.splice(elementIndex, 1);
        if (this.currentClassroomID === obj.Classroom.ID) {
          this.checkUrlClassroomID();
        }
      } else if (!needDelete && elementIndex === -1) {
        // here we save order of classrooms, we need sort here in UI (because maybe user was in classroom in past)
        // so can't add at the begin
        // this.classrooms.splice(0, 0, obj);

        let objDate = new Date();
        if (obj.Confirmation && obj.Confirmation.createdat) {
          objDate = new Date(obj.Confirmation.createdat);
        } else if (obj.Classroom.createdat) {
          objDate = new Date(obj.Classroom.createdat);
        }

        let left = 0;
        let right = this.classrooms.length;

        while (left < right) {
          const mid = Math.floor((left + right) / 2);
          let midDate = new Date();
          if (
            this.classrooms[mid].Confirmation &&
            this.classrooms[mid].Confirmation.createdat
          ) {
            midDate = new Date(this.classrooms[mid].Confirmation.createdat);
          } else if (this.classrooms[mid].Classroom.createdat) {
            midDate = new Date(this.classrooms[mid].Classroom.createdat);
          }

          if (midDate > objDate) {
            left = mid + 1;
          } else {
            right = mid;
          }
        }

        this.classrooms.splice(left, 0, obj);

        // if select classroom after join from dialog
        if (this.classroomJoinChosen === obj.Classroom.ID) {
          this.selectCurrentClassroom(this.classroomJoinChosen);
          this.classroomJoinChosen = null;
        }
      } else if (!needDelete && elementIndex !== -1) {
        this.classrooms[elementIndex] = obj;
      }
    },
  },
};
