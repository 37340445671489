<template>
  <q-field
    borderless
    stack-label
    :disable="disabled"
    style="border-radius: 1rem !important"
    :style="{
      height: view?.height ? view.height : '3rem',
      border: !languages?.length
        ? '1px solid var(--q-h)'
        : '1px solid var(--q-2)',
    }"
  >
    <template #control>
      <div v-if="languages?.length > 0" tabindex="0" class="no-outline fit">
        <q-scroll-area
          style="cursor: pointer"
          v-if="view?.scroll === true"
          class="q-pt-md fit"
        >
          <template v-for="treeobj in tree" :key="treeobj.ID + '_tree'">
            <template
              v-for="language in languages"
              :key="language + '_language'"
            >
              <template v-if="treeobj.ID === language">
                {{ treeobj.name }};
              </template>
            </template>
          </template>
        </q-scroll-area>
        <div v-else>
          <template v-for="treeobj in tree" :key="treeobj.ID + '_tree'">
            <template
              v-for="language in languages"
              :key="language + '_language'"
            >
              <template v-if="treeobj.ID === language">
                {{ treeobj.name }};
              </template>
            </template>
          </template>
        </div>
      </div>
      <div v-else class="ubuntu text-8">
        {{ $t("-raw-story-choose-languages") }}
      </div>
    </template>
    <q-menu
      fit
      class="z-max"
      style="border-radius: 1rem !important"
      @show="
        $refs.filterRef.focus();
        checkTickedLanguages();
      "
    >
      <q-input filled v-model="filter" ref="filterRef">
        <template #prepend>
          <q-icon name="search" />
        </template>
        <template #append>
          <q-icon
            v-if="filter !== ''"
            name="clear"
            class="cursor-pointer"
            @click="resetFilter"
          />
        </template>
      </q-input>
      <div class="column row items-end full-width">
        <q-btn
          class="q-mr-md full-width"
          flat
          style="font-size: 0.8rem"
          no-caps
          :disable="!(languages && languages.length > 0)"
          @click="clearLanguages"
        >
          {{ $t("-raw-category-clear-all") }}
        </q-btn>
      </div>
      <q-tree
        v-show="!filterResultEmpty"
        ref="treeRef"
        :nodes="tree"
        v-model:ticked="ticked"
        v-model:expanded="expanded"
        tick-strategy="strict"
        node-key="ID"
        label-key="name"
        :filter="filter"
        :filter-method="filterMethod"
        @update:ticked="updateTicked"
        default-expand-all
        class="column row q-mx-md"
      >
      </q-tree>
      <div v-show="filterResultEmpty" class="column row items-center">
        {{ $t("-raw-category-filter-not-found") }}
      </div>
    </q-menu>
  </q-field>
</template>

<script>
export default {
  props: [
    "languages",
    "disabled", // true, false
    "view", // {scroll: bool, height: '3rem'}
  ],
  emits: ["selectLanguage"],
  data() {
    return {
      filter: "",
      ticked: [],
      expanded: [],
      filterResultEmpty: false,
    };
  },
  watch: {
    filter: {
      handler(newVal, oldVal) {
        this.filterNodeExists();
      },
    },
    "languages.length": {
      handler(newVal, oldVal) {
        this.checkTickedLanguages();
      },
    },
    "tree.length": {
      handler(newVal, oldVal) {
        this.checkTickedLanguages();
      },
    },
  },
  methods: {
    checkTickedLanguages() {
      // check selected languages
      // check after loaded languages from object, from tree and not checked yet
      if (this.languages && this.tree && this.ticked.length === 0) {
        for (var i = 0; i < this.languages.length; i++) {
          this.ticked.push(this.languages[i]);
        }
      }
    },
    filterMethod(node, filter) {
      const filt = filter.toLowerCase();
      return node.name && node.name.toLowerCase().indexOf(filt) > -1;
    },

    // user filterMethod code, need this method check exists any node by filter
    filterNodeExists() {
      var lnk = this;
      var fnd = function (arr) {
        for (var i = 0; i < arr.length; i++) {
          if (lnk.filterMethod({ name: arr[i].name }, lnk.filter)) {
            return true;
          }
        }
        return false;
      };

      if (fnd(this.tree)) {
        this.filterResultEmpty = false;
      } else {
        this.filterResultEmpty = true;
      }
    },

    resetFilter() {
      this.filter = "";
      this.$refs.filterRef.focus();
    },

    clearLanguages() {
      this.$refs.treeRef.setTicked(this.ticked, false);
    },

    updateTicked(target) {
      this.$emit("selectLanguage", target);
    },
  },
  computed: {
    tree() {
      return this.$store.getters.languages;
    },
  },
  mounted() {},
};
</script>
