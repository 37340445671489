<template>
  <div class="row col no-wrap justify-between">
    <div
      v-if="!webshareapisupport || noNative"
      class="row items-center justify-between no-wrap bg-1 full-width round-1"
    >
      <q-btn
        v-for="media in medias.slice(0, 7)"
        :key="media.i"
        :color="media.h ? 'v' : media.c"
        :href="media.u + $route.path"
        class="share-button"
        flat
        round
        target="__blank"
        type="a"
        @mouseleave="media.h = false"
        @mouseover="media.h = true"
      >
        <!-- :color="media.h ? 'v' : '5'" -->
        <q-icon :name="media.i" class="share-icon" />
      </q-btn>
      <q-btn
        class="share-button"
        color="v"
        dense
        flat
        round
        @click="copylink()"
      >
        <q-icon class="share-icon" name="file_copy" />
        <q-tooltip
          v-model="copied"
          anchor="center right"
          class="z-max round-both q-px-md text-v bg-1"
          no-parent-event
          self="center left"
          transition-hide="jump-left"
          transition-show="jump-right"
        >
          <div class="ubuntu" style="font-weight: 600; font-size: 1rem">
            {{ $t("-raw-tooltip-link-copied") }}!
          </div>
        </q-tooltip>
      </q-btn>
    </div>
    <q-btn
      v-else
      color="v"
      dense
      flat
      icon="o_share"
      round
      @click="shareNative()"
    />
  </div>
</template>

<script>
export default {
  props: {
    link: {
      type: String,
      default: function () {
        return "";
      },
    },
    noNative: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
  },

  //   = %20 | +
  // ! = %21
  // # = %23
  // $ = %24
  // % = %25
  // & = %26
  // ' = %27
  // ( = %28
  // ) = %29
  // * = %2A
  // + = %2B
  // , = %2C
  // / = %2F
  // : = %3A
  // ; = %3B
  // = = %3D
  // ? = %3F
  // @ = %40
  // [ = %5B
  // ] = %5D

  data: () => ({
    copied: false,
    medias: [
      // HACKER NEWS: https://news.ycombinator.com/submitlink?u=http%3A%2F%2Fsharingbuttons.io&amp;t=Super%20fast%20and%20easy%20Social%20Media%20Sharing%20Buttons.%20No%20JavaScript.%20No%20tracking.
      // XING: https://www.xing.com/app/user?op=share;url=http%3A%2F%2Fsharingbuttons.io;title=Super%20fast%20and%20easy%20Social%20Media%20Sharing%20Buttons.%20No%20JavaScript.%20No%20tracking.
      // TUMBLR: https://www.tumblr.com/widgets/share/tool?posttype=link&amp;title=Super%20fast%20and%20easy%20Social%20Media%20Sharing%20Buttons.%20No%20JavaScript.%20No%20tracking.&amp;caption=Super%20fast%20and%20easy%20Social%20Media%20Sharing%20Buttons.%20No%20JavaScript.%20No%20tracking.&amp;content=http%3A%2F%2Fsharingbuttons.io&amp;canonicalUrl=http%3A%2F%2Fsharingbuttons.io&amp;shareSource=tumblr_share_button
      // { h: false, c: 'indigo', i: 'fab fa-tumblr', u: '' },
      // WHATSAPP: whatsapp://send?text=Super%20fast%20and%20easy%20Social%20Media%20Sharing%20Buttons.%20No%20JavaScript.%20No%20tracking.%20http%3A%2F%2Fsharingbuttons.io
      // { h: false, c: 'green', i: 'fab fa-whatsapp', u: '' },
      // TELEGRAM: https://telegram.me/share/url?text=Super%20fast%20and%20easy%20Social%20Media%20Sharing%20Buttons.%20No%20JavaScript.%20No%20tracking.&amp;url=http%3A%2F%2Fsharingbuttons.io
      // { h: false, c: 'light-blue', i: 'fab fa-telegram-plane', u: 'https://telegram.me/share/url?text=Accept the Challenge.&amp;url=${process.env.PUBLIC_URL}/try' },
      // PINTEREST: https://pinterest.com/pin/create/button/?url=http%3A%2F%2Fsharingbuttons.io&amp;media=http%3A%2F%2Fsharingbuttons.io&amp;description=Super%20fast%20and%20easy%20Social%20Media%20Sharing%20Buttons.%20No%20JavaScript.%20No%20tracking.
      // {
      //   h: false,
      //   c: "red",
      //   i: "fab fa-pinterest-p",
      //   u: `http://pinterest.com/pin/create/button/?url=${process.env.PUBLIC_URL}/try`,
      // },
      // REDDIT: https://reddit.com/submit/?url=http%3A%2F%2Fsharingbuttons.io&amp;resubmit=true&amp;title=Super%20fast%20and%20easy%20Social%20Media%20Sharing%20Buttons.%20No%20JavaScript.%20No%20tracking.
      // {
      //   h: false,
      //   c: "deep-orange",
      //   i: "fab fa-reddit-alien",
      //   u: `http://www.reddit.com/submit?url=${process.env.PUBLIC_URL}/try`,
      // },
      // LINKEDIN: https://www.linkedin.com/shareArticle?mini=true&amp;url=http%3A%2F%2Fsharingbuttons.io&amp;title=Super%20fast%20and%20easy%20Social%20Media%20Sharing%20Buttons.%20No%20JavaScript.%20No%20tracking.&amp;summary=Super%20fast%20and%20easy%20Social%20Media%20Sharing%20Buttons.%20No%20JavaScript.%20No%20tracking.&amp;source=http%3A%2F%2Fsharingbuttons.
      // LINKEDIN: https://www.linkedin.com/shareArticle
      // ?mini=true
      // &url=https%3A%2F%2Fwww.css-tricks.com%2F
      // &title=CSS-Tricks // overrides og:title
      // &summary=Tips%2C+Tricks%2C+and+Techniques+on+using+Cascading+Style+Sheets. // overrides og:desc
      // &source=CSS-Tricks
      // {
      //   h: false,
      //   c: "blue-10",
      //   i: "fab fa-linkedin-in",
      //   u: `http://www.linkedin.com/shareArticle?mini=true&url=${process.env.PUBLIC_URL}/try`,
      // },
      // VKONTAKTE: http://vk.com/share.php?title=Super%20fast%20and%20easy%20Social%20Media%20Sharing%20Buttons.%20No%20JavaScript.%20No%20tracking.&amp;url=http%3A%2F%2Fsharingbuttons.io
      // VKONTAKTE: https://vk.com/share.php?url={адрес страницы}
      // &title= // uses twitter>og:title
      // &image= // uses twitter>og:image
      // &noparse=true/false
      // &no_vk_links=[0,1] (mobile user-links)
      // { lol, vk
      //   h: false,
      //   c: "blue",
      //   i: "fab fa-vk",
      //   u: `https://vk.com/share.php?url=${process.env.PUBLIC_URL}/try`
      // },
      // FACEBOOK: https://facebook.com/sharer/sharer.php?u=http%3A%2F%2Fsharingbuttons.io
      // FACEBOOK: https://www.facebook.com/dialog/share TODO: register Facebook App ID
      // FACEBOOK: https://www.facebook.com/sharer.php?u=https%3A%2F%2Fvoccent.com%2F
      // {
      //   h: false,
      //   c: "blue",
      //   i: "fab fa-facebook-f",
      //   u: `http://www.facebook.com/sharer.php?u=${process.env.PUBLIC_URL}/try`,
      // },
      // TWITTER: https://twitter.com/intent/tweet/?text=Super%20fast%20and%20easy%20Social%20Media%20Sharing%20Buttons.%20No%20JavaScript.%20No%20tracking.&amp;url=http%3A%2F%2Fsharingbuttons.io
      // TWITTER: https://twitter.com/intent/tweet
      // ?url=http%3A%2F%2Fcss-tricks.com%2F
      // &text=Tips%2C+Tricks%2C+and+Techniques+on+using+Cascading+Style+Sheets.
      // &hashtags=css,html
      // TWITTER: `http://twitter.com/share?text=Voc2Me&url=${process.env.PUBLIC_URL}/try`
      // {
      //   h: false,
      //   c: "light-blue",
      //   i: "fab fa-twitter",
      //   u: `http://twitter.com/intent/tweet?text=I challenged you on @Voccent! Click the link below to accept my challenge: &hashtags=voice,accent,dsp,voccent&url=${process.env.PUBLIC_URL}/try`,
      // },
      // EMAIL: mailto:?subject=Super%20fast%20and%20easy%20Social%20Media%20Sharing%20Buttons.%20No%20JavaScript.%20No%20tracking.&amp;body=http%3A%2F%2Fsharingbuttons.io
      // { // lol, email
      //   h: false,
      //   c: "amber",
      //   i: "alternate_email",
      //   u:
      //     `mailto:?subject=Voc2Me&body=I challenged you on Voccent! Click the link to accept the challenge: ${process.env.PUBLIC_URL}/try`
      // },
    ],
  }),
  computed: {
    createdChallenge() {
      return this.$store.getters.createdChallenge;
    },
    user() {
      return this.$store.getters.user;
    },
    webshareapisupport() {
      return navigator.share;
    },
  },
  methods: {
    copylink() {
      navigator.clipboard.writeText(this.link).then(
        () => {
          this.copied = true;
          setTimeout(() => {
            this.copied = false;
          }, 1000);
          console.log("Async: Copying to clipboard was successful!");
        },
        function (err) {
          console.error("Async: Could not copy text: ", err);
        },
      );
    },
    shareNative() {
      navigator.share({
        title: "Voccent: Language Learning and Emotions",
        url: this.link,
      });
    },
  },
};
</script>

<style scoped>
.share-icon {
  transition-duration: 0.25s;
}

.share-button:hover .share-icon {
  transform: scale(0.9) rotate(20deg);
  /* color: var(--q-p); */
}

.nice-reverse {
  user-select: none;
  font-size: 3rem;
  font-weight: 1000;
  background: linear-gradient(90deg, var(--q-h), var(--q-v));
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}
</style>
