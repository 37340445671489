<script src="./index.js"></script>
<template>
  <q-page
    class="boxshadow no-wrap bg-1 column full-width"
    style="overflow-y: hidden"
  >
    <div
      class="column row full-width items-center q-pa-md"
      v-if="connected === null"
    >
      <q-spinner-ball color="primary" size="lg" />
    </div>
    <div
      v-else-if="connected === false"
      class="row q-gutter-x-md items-center justify-center text-n fit"
      style="font-size: 1.2rem; font-weight: bold"
    >
      <span>{{ $t("-raw-no-access") }}</span>
    </div>
    <q-card flat class="full-width bg-2" v-if="connected">
      <!-- NOTE: ref-53558aaa height -->
      <!-- NOTE: ref-c34adf3a: hide/show list or select -->
      <div
        class="row full-width justify-center items-center"
        style="height: 4rem"
      >
        <div
          class="column col col-xl-2 col-lg-2 col-md-3 col-sm-4 col-xs-4"
          v-if="$q.screen.gt.md && fit"
        ></div>
        <div
          :class="
            $q.screen.gt.md && fit
              ? 'col-xl-10 col-lg-10 col-md-9 col-sm-8 col-xs-8'
              : 'col-12'
          "
          class="row items-center"
        >
          <q-checkbox
            v-model="filter.IsOnlyMy"
            :label="$t('-raw-report-show-only-my')"
            @update:model-value="search"
            class="q-pl-xl"
          />

          <q-select
            borderless
            :label="
              filter.SelectReportID ? undefined : $t('-raw-report-all-types')
            "
            v-if="!($q.screen.gt.md && fit)"
            popup-content-style="border-radius: 1rem;"
            style="border-radius: 1rem; max-width: 20rem"
            size="sm"
            class="bg-0 q-pr-sm q-pl-md col q-ml-xl"
            v-model="filter.SelectReportID"
            :options="groupList"
            option-label="GroupName"
            option-value="ReportID"
            emit-value
            map-options
            clearable
            @update:model-value="selectReportTypeByID"
          >
          </q-select>
        </div>
        <div style="position: absolute; right: 1rem">
          <Action
            :ireporttypelist="groupList"
            :ireporttype="filter.Report"
            :iissmall="$q.screen.gt.md ? false : true"
            :ref="'action_' + uid"
            @run="runNewReport"
            v-show="!this.selectedReport?.ID"
          ></Action>
        </div>
      </div>
    </q-card>
    <div class="row full-width" v-if="connected">
      <!-- NOTE: ref-53558aaa height -->
      <!-- NOTE: ref-c34adf3a: hide/show list or select -->
      <div
        class="column col col-xl-2 col-lg-2 col-md-3 col-sm-4 col-xs-4 bg-4 q-pt-sm"
        :style="{ height: `calc(${height}px - 4rem)` }"
        v-if="$q.screen.gt.md && fit"
      >
        <!-- <q-scroll-area class="col q-pt-sm q-mx-sm" :delay="1200" visible> -->
        <q-scroll-area
          class="col"
          style="position: relative; overflow: hidden"
          :thumb-style="{
            right: '2px',
            borderRadius: '5px',
            width: '10px',
            opacity: 1,
            'margin-right': '2rem',
            'background-color': 'var(--q-6)',
            left: '0px',
          }"
        >
          <div
            v-for="grp in groupList"
            :key="'group_' + grp.ReportID"
            class="groupCard q-py-sm q-ma-sm q-px-md"
            @click="selectReportType(grp)"
            :class="
              filter.Report?.ReportID &&
              filter.Report?.ReportID === grp.ReportID
                ? 'text-v text-bold bg-2'
                : 'bg-1'
            "
            :style="{
              'border-color':
                filter.Report?.ReportID &&
                filter.Report?.ReportID === grp.ReportID
                  ? 'var(--q-e)'
                  : 'var(--q-6)',
            }"
          >
            {{ grp.GroupName }}
          </div>
        </q-scroll-area>
      </div>
      <!-- NOTE: ref-53558aaa height -->
      <div
        class="column col bg-1"
        :class="
          $q.screen.gt.md && fit
            ? 'col-xl-10 col-lg-10 col-md-9 col-sm-8 col-xs-8'
            : 'col-12'
        "
        :ref="'ref_main_scroll_result_' + uid"
        :id="'ref_main_scroll_result_' + uid"
        :style="{ height: `calc(${height}px - 4rem)` }"
      >
        <q-scroll-area
          class="col q-pt-md q-mx-md"
          v-show="openingreport == false && !selectedReport?.ID"
        >
          <q-infinite-scroll
            :ref="'foundScroll_' + uid"
            class="row justify-center fit"
            @load="searchNextPage"
          >
            <template #loading>
              <q-spinner-ball color="primary" size="lg"
            /></template>
            <!-- <div
              v-for="(item, index) in foundArr"
              :key="'found_' + index + item.ID"
              class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12 q-px-sm q-pb-md"
            >
              <reportcard
                :key="'cardkey_' + refcardkeys[item.ID]"
                :ref="'refreportcard_' + item.ID"
                :item="item"
                :show-info="true"
                :is-deleting="deletingReports.get(item.ID)"
                @deleteReport="(ID) => deleteReport(ID)"
                @click="openReport(item.ID)"
              />
            </div> -->

            <div
              v-for="(item, index) in foundArr"
              :key="'found_' + index + item.ID"
              class="col-xl-8 col-lg-8 col-md-10 col-sm-12 col-xs-12 q-px-sm q-pb-md"
            >
              <reportcard
                :key="'cardkey_' + refcardkeys[item.ID]"
                :ref="'refreportcard_' + item.ID"
                :item="item"
                :can-select="item.Status === 'done'"
                :is-deleting="deletingReports.get(item.ID)"
                @deleteReport="(ID) => deleteReport(ID)"
                @click="openReport(item)"
              />
            </div>
            <!-- v-if="!isSearchLoading && Object.keys(foundList).length === 0" -->
            <div
              v-if="!isSearchLoading && foundArr.length === 0"
              class="row justify-center content-center full-width full-height ubuntu"
            >
              <!-- {{ $t("-raw-404-nothing-found-v2") }} -->
              <q-img
                class="col-xl-6 col-lg-6 col-md-6 col-sm-8 col-xs-10"
                fit="contain"
                src="not_found.svg"
              />
            </div>
          </q-infinite-scroll>
        </q-scroll-area>
        <div
          class="column row full-width items-center q-pa-md"
          v-if="openingreport == true"
        >
          <q-spinner-ball color="primary" size="lg" />
        </div>
        <div
          class="column row full-width items-center q-pa-md q-pl-xl"
          v-if="openingreport == false && selectedReport?.ID"
        >
          <div class="row full-width justify-start items-center">
            <q-btn
              class="bg-1 q-mx-xs"
              flat
              icon="keyboard_arrow_left"
              no-caps
              rounded
              @click="selectedReport = {}"
            >
              <q-tooltip
                anchor="bottom middle"
                class="bg-1 text-8 round-both"
                self="top middle"
                transition-hide="jump-down"
                transition-show="jump-up"
              >
                <b>{{ $t("-raw-report-back-to-report-list") }}</b>
              </q-tooltip>
            </q-btn>
            <div class="text-bold q-pl-md" style="font-size: 1.2rem">
              {{ selectedReport.GroupName }}
            </div>
          </div>
          <div class="row full-width">
            <div
              class="col text-8 ubuntu row q-mr-sm"
              style="font-size: 0.8rem; font-weight: normal"
            >
              <div
                class="col column items-start q-mx-xs text-4"
                style="overflow: hidden"
              >
                <div class="row full-width">
                  <div class="column col">
                    <div v-if="selectedReport.CreatedByName">
                      <span class="q-pr-md">
                        <span style="font-weight: bold"
                          >{{ $t("-raw-report-author") }}:
                        </span>
                        {{ selectedReport.CreatedByName }}
                      </span>
                    </div>
                    <div style="white-space: nowrap">
                      <span class="q-pr-md">
                        <span style="font-weight: bold"
                          >{{ $t("-raw-report-created") }}:
                        </span>
                        {{ formatTimestamp(selectedReport.createdat) }}
                      </span>
                      <span v-show="selectedReport.updatedat">
                        <span style="font-weight: bold"
                          >{{ $t("-raw-report-updated") }}:
                        </span>
                        {{ formatTimestamp(selectedReport.updatedat) }}
                      </span>
                    </div>
                  </div>
                </div>

                <div
                  v-if="
                    selectedReportParams?.DateFrom ||
                    selectedReportParams?.DateTo
                  "
                  style="white-space: nowrap"
                >
                  <span class="q-pr-md"
                    ><span
                      v-if="selectedReportParams?.DateFrom"
                      style="font-weight: bold"
                      >From:</span
                    >
                    {{ formatDate(selectedReportParams.DateFrom) }}
                  </span>
                  <span
                    ><span
                      v-if="selectedReportParams?.DateTo"
                      style="font-weight: bold"
                      >To:</span
                    >
                    {{ formatDate(selectedReportParams.DateTo) }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            class="row full-width justify-start q-pt-sm"
            v-if="
              selectedReport.SendEmail === true || selectedReport.Xlsx === true
            "
          >
            <div class="q-pl-md">
              <template v-if="selectedReport.SendEmail === true">
                <q-btn
                  style="border-radius: 1rem"
                  no-caps
                  flat
                  class="bg-v text-f q-mr-md ubuntu"
                  :loading="sendingEmail"
                  @click="sendEmail"
                >
                  <div class="gt-sm q-mr-lg">
                    {{ $t("-raw-report-send-email") }}
                  </div>
                  <q-icon name="send" />
                </q-btn>
              </template>
              <template
                v-if="
                  selectedReport.Xlsx === true &&
                  selectedReport.ResultIsEmpty === false
                "
              >
                <q-btn
                  style="border-radius: 1rem"
                  no-caps
                  flat
                  :loading="xlsxdownloading"
                  class="bg-v text-f q-mr-md ubuntu"
                  @click="downloadXlsx"
                >
                  <div class="gt-sm q-mr-lg">
                    {{ $t("-raw-report-download-xlsx") }}
                  </div>
                  <q-icon name="save_alt" />
                </q-btn>
              </template>
            </div>
          </div>
          <div
            class="row full-width justify-start q-pt-sm"
            v-if="selectedReport.Result"
          >
            <div
              v-if="selectedReport.GroupID === 'academic_performance_per_user'"
              style="height: 100%"
            >
              <AcademicPerformancePerUser :item="selectedReport" />
            </div>
            <div
              v-if="selectedReport.GroupID === 'emotion_status'"
              style="height: 100%"
            >
              <EmotionStatus :item="selectedReport" />
            </div>
          </div>
          <div
            class="row full-width justify-start q-pt-sm"
            v-if="selectedReport.EmailTemplate"
          >
            <q-separator class="q-my-md full-width q-separator-dashed" />
            <!-- can't use class="bg-e" because color inverse, need use "background-color: #fff"-->
            <iframe
              class="full-width"
              style="border: 0px; background-color: #fff"
              :style="{ height: `calc(${window.innerHeight}px - 20rem)` }"
              :srcdoc="selectedReport.EmailTemplate"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </q-page>
</template>

<style scoped>
.groupCard {
  cursor: pointer;
  border: 1px solid var(--q-6);
  user-select: none;
  border-radius: 1rem;
}
.groupCard:hover {
  background: var(--q-2);
}
.q-separator-dashed {
  border-top: 4px dashed #ccc; /* Creates a dashed line */
  border-bottom: none; /* Removes the default solid line */
}
</style>
