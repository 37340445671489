<script src="./index.js"></script>
<style lang="css" scoped>
.q-tab {
  justify-content: start !important;
  padding-left: 0.75rem;
}

.q-tab__content .q-icon {
  margin-right: 0.5rem !important;
}
.tab {
  border-radius: 0.8rem;
  font-size: 0.8rem;
  margin: 0.5rem 0;
  align-items: start !important;
  text-align: left !important;
  z-index: 2; /* Ensure these elements are above the background */
}
.tab-active {
  pointer-events: none;
  background: var(--q-e);
}

.q-icon-toggle-name-panel {
  cursor: pointer;
  margin: 10px;
  transition: color 0.2s;
}

.classroom-img {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
  overflow: hidden;
}
.classroom-img-brd {
  border-color: var(--q-h);
  border-width: 4px;
  border-style: solid;
}
.classroom-img-dflt-brd {
  border-color: var(--q-b);
  border-width: 4px;
  border-style: solid;
}
.classroom-status-wait-circle {
  position: absolute;
  top: 5px;
  left: 65px;
  width: 7px;
  height: 7px;
  background-color: var(--q-w);
  border-radius: 50%;
}
</style>

<template>
  <div v-if="!loaded" class="column row items-center q-pa-md">
    <q-spinner-ball color="primary" size="lg" />
  </div>

  <div class="row" style="max-height: 100%" v-else>
    <!-- LEFT NAV (classrooms list) -->
    <!-- col-xs-11 col-lg-2 col-md-3 col-4 -->
    <div
      :style="dynamicLeftWidth"
      style="
        max-height: 100%;
        border-radius: 0.75rem;
        justify-content: start;
        overflow-x: hidden;
        overflow-y: auto;
        transition:
          opacity 0.3s ease,
          width 0.3s ease;
      "
      v-if="classrooms.length > 0"
    >
      <!-- :name="showFullPanel ? 'visibility' : 'visibility_off'" -->
      <div style="width: 90px" class="row justify-center">
        <q-icon
          size="1.5rem"
          class="q-icon-toggle-name-panel"
          :name="showFullPanel ? 'menu_open' : 'menu'"
          :class="{
            'text-e': showFullPanel,
            'text-b': !showFullPanel,
          }"
          @click="toggleShowFullPanel"
        />
      </div>

      <q-scroll-area
        class="row full-height items-center justify-center full-width"
      >
        <div
          v-for="(cls, n) in classrooms"
          :key="n + 'classrooms_list'"
          class="col-12"
          @click="selectCurrentClassroom(cls.Classroom.ID)"
        >
          <q-item
            clickable
            class="q-py-sm row full-height items-center justify-center full-width"
            :class="{
              'bg-3': currentClassroomID === cls.Classroom.ID,
            }"
          >
            <q-item-section avatar style="width: 50px; height: 50px">
              <q-img
                :src="
                  '/api/v1/asset/object/classroom_picture/' + cls.Classroom.ID
                "
                class="classroom-img"
                :class="{
                  'classroom-img-brd': currentClassroomID === cls.Classroom.ID,
                }"
                style="width: 50px; height: 50px"
              >
                <template #error>
                  <img
                    src="Cc.svg"
                    class="classroom-img"
                    style="width: 50px; height: 50px"
                  />
                </template>
              </q-img>
              <div
                class="classroom-status-wait-circle"
                v-if="
                  cls.Confirmation?.Status === 'invited' ||
                  cls.Confirmation?.Status === 'requested'
                "
              ></div>
            </q-item-section>

            <q-item-section>
              <div v-if="showFullPanel" class="q-ml-sm">
                {{ cls.Classroom.Name }}
              </div>
            </q-item-section>
          </q-item>
        </div>
      </q-scroll-area>
    </div>
    <div
      style="border-radius: 0.75rem; height: 100%; width: 100%"
      class="col bg-transparent q-ml-sm"
    >
      <div class="full-width">
        <q-btn
          style="left: 1rem"
          :class="
            classroomJoinByCodeDialog
              ? 'bg-c classroom-img-brd'
              : 'bg-b classroom-img-dflt-brd'
          "
          :round="$q.screen.lt.lg"
          :rounded="$q.screen.gt.md"
          size="sm"
          flat
          color="0"
          @click="
            classroomJoinByCode = null;
            selectClassroomJoinByCodeDialog();
          "
        >
          <q-icon name="vpn_key" size="sm" />
          <!-- <q-tooltip
            anchor="top middle"
            self="bottom middle"
            class="row mytooltip round-both ubuntu bg-0 text-v items-center justify-center q-px-md"
            transition-show="jump-up"
            transition-hide="jump-down"
          >
          </q-tooltip> -->
        </q-btn>
      </div>
      <classroompage
        :key="'clpg_' + clpgkey"
        v-if="currentClassroomID"
        :data="{ id: currentClassroomID }"
        @notexists="classroomNotExists"
      />
      <div
        class="row col items-center justify-center q-pt-xl q-mt-xl"
        v-if="classroomJoinByCodeDialog"
      >
        <q-card
          style="box-shadow: none; border-radius: 0.75rem"
          class="col-lg-4 col-md-6 col-sm-8 col-xs-12 q-pa-lg"
        >
          <q-card-section class="row">
            <span
              style="color: var(--q-e); font-size: 1.5rem; font-weight: 1000"
            >
              {{ $t("-raw-my-classrooms-join-by-code") }}
            </span>
          </q-card-section>
          <q-card-section class="row q-pt-none q-pb-sm">
            <q-input
              ref="inputCodeJoinClassroomMy"
              type="text"
              borderless
              autogrow
              style="border-radius: 0.75rem"
              v-model="classroomJoinByCode"
              :label="$t('-raw-classroom-auto-join-code')"
              class="bg-0 q-px-md full-width"
              input-class="ubuntu bg-transparent"
              input-style="color: var(--q-a); max-height: 2rem; min-height: 2rem; min-width: 25rem;"
              @keypress.enter.prevent="
                !(
                  classroomJoinByCode == null || classroomJoinByCode.length == 0
                )
                  ? enterClassroomByCode()
                  : null
              "
            >
              <template #after>
                <q-btn
                  flat
                  no-caps
                  :label="$t('-raw-general-enter')"
                  class="bg-v text-0 q-px-lg q-ma-md"
                  style="border-radius: 0.5rem"
                  :loading="classroomJoinByCodeLoading"
                  :disabled="
                    classroomJoinByCode == null ||
                    classroomJoinByCode.length == 0
                  "
                  @click="enterClassroomByCode"
                />
              </template>
            </q-input>
          </q-card-section>
          <q-card-section class="row q-my-sm">
            <span
              style="color: var(--q-e); font-size: 1.5rem; font-weight: 1000"
              class="text-uppercase"
            >
              {{ $t("-raw-my-classrooms-join-by-code-or") }}
            </span>
          </q-card-section>

          <q-card-section class="row q-pt-none q-pb-sm">
            <q-btn
              flat
              no-caps
              :label="$t('-raw-my-classrooms-search')"
              class="bg-0 text-e text-bold"
              style="border-radius: 0.5rem; font-size: 1.2rem"
              @click="joinclassroomdialog = true"
              icon="search"
            />
          </q-card-section>
        </q-card>
      </div>
    </div>
    <q-dialog
      v-model="joinclassroomdialog"
      :maximized="joinclassroomdialogMax"
      persistent
      transition-hide="slide-down"
      transition-show="slide-up"
    >
      <q-card>
        <q-card-section
          :style="{
            height: `calc(${window.innerHeight}px - 5rem) !important`,
          }"
        >
          <q-btn
            v-close-popup
            class="bg-e"
            icon="close"
            no-caps
            style="
              position: absolute;
              right: 1rem;
              width: 2rem;
              height: 2rem;
              z-index: 9999 !important;
            "
            text-color="0"
          ></q-btn>
          <Classrooms
            :data="{ languages: userlanguages }"
            class="fit"
            @choose="classroomChooseFromList"
          />
        </q-card-section>
        <q-card-actions align="right" class="q-pr-md">
          <q-btn
            v-close-popup
            class="q-py-md bg-2"
            color="e"
            dense
            icon-left="arrow_back"
            no-caps
            outline
            rounded
            style="height: 3rem"
          >
            <span class="q-mx-md">{{ $t("-raw-general-cancel") }}</span>
          </q-btn>
          <q-btn
            v-close-popup
            :color="classroomJoinChosen == null ? 'v' : 'p'"
            :disabled="classroomJoinChosen == null"
            dense
            icon-right="arrow_forward"
            no-caps
            rounded
            style="height: 3rem"
            text-color="0"
            @click="classroomJoinConfirm"
          >
            <span class="q-mx-md">{{ $t("-raw-general-ask-to-join") }}</span>
          </q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>
