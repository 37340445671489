<template>
  <Bar
    id="my-chart-id"
    :options="chartOptions"
    :data="chartData"
    style="height: 45vh"
    v-if="showChart"
  />
</template>

<script>
import { Bar } from "vue-chartjs";
import { Chart as ChartJS, registerables } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import "chartjs-adapter-moment";
import moment from "moment";

ChartJS.register(...registerables);

export default {
  name: "AcademicPerformance",
  props: ["item"],
  components: { Bar },
  data() {
    return {
      showChart: false,
      chartData: {
        datasets: [
          {
            label: "Attempt count",
            backgroundColor: "#777fff",
            data: {},
            order: 1,
          },
          {
            label: "Avg percent",
            backgroundColor: "#58be50",
            data: {},
            type: "line",
            order: 0,
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            min: moment(this.item.Params.DateFrom).startOf("day").toDate(),
            max: moment(this.item.Params.DateTo).startOf("day").toDate(),
            type: "time",
            time: {
              format: "YYYY-MM-DD",
              tooltipFormat: "ll",
              unit: "day",
              displayFormats: {
                day: "DD MMM",
              },
            },
            scaleLabel: {
              display: true,
              labelString: "Date",
            },
          },
          y: {
            scaleLabel: {
              display: true,
              labelString: "value",
            },
          },
        },
      },
    };
  },
  mounted() {
    this.calcDataAttemptCount();
    this.showChart = true;
  },
  computed: {
    ChartDataLabels() {
      return ChartDataLabels;
    },
  },
  methods: {
    calcDataAttemptCount() {
      if (!Array.isArray(this.item.Result)) return;
      const sortedDates = this.item.Result.map((attempt) =>
        moment(attempt.CreatedAt).startOf("day").toDate(),
      ) // Extract createdat field from each object
        .sort((a, b) => a - b);
      sortedDates.forEach((date) => {
        this.chartData.datasets[0].data[date] = 0;
        this.chartData.datasets[1].data[date] = 0;
      });

      this.item.Result.forEach((attempt) => {
        let date = moment(attempt.CreatedAt).startOf("day").toDate();
        this.chartData.datasets[0].data[date]++;
        this.chartData.datasets[1].data[date] += attempt.TotalPercent;
      });
      // calc avg percent
      for (const date in this.chartData.datasets[1].data) {
        this.chartData.datasets[1].data[date] =
          this.chartData.datasets[1].data[date] /
          this.chartData.datasets[0].data[date];
      }
    },
  },
};
</script>
