// info - 7363484a-2f55-4437-9df1-a06d25d2ad84
const Constantes = {
  systemuserid: "10000000-0000-0000-0000-000000000000",
  capabilities: {
    chatid: "00000000-0000-0000-0000-000000000004",
    maxchannelsid: "00000000-0000-0000-0000-000000000001",
    maxchallengesinchannelsid: "00000000-0000-0000-0000-000000000002",
    maxplaylistssid: "00000000-0000-0000-0000-00000000001b",
    maxattemptssid: "00000000-0000-0000-0000-000000000006",
    maxstoriesid: "00000000-0000-0000-0000-00000000001c",
    energyanalysisid: "00000000-0000-0000-0000-00000000000b",
    pitchanalysisid: "00000000-0000-0000-0000-00000000000c",
    breathanalysisid: "00000000-0000-0000-0000-00000000000d",
    emotionanalysisid: "00000000-0000-0000-0000-00000000001e",
    maxstorypassesid: "00000000-0000-0000-0000-00000000001d",
    maxpubliccontentid: "00000000-0000-0000-0000-000000000040",
    maxclassroomsid: "00000000-0000-0000-0000-000000000041",
    maxcampusesid: "00000000-0000-0000-0000-000000000042",
  },
  languages: [
    {
      name: "English (US)",
      iso_2: "en",
      iso_3: "eng",
      code: "045",
      locale: "en-US",
      currency_code: "USD",
      translator_type: 1,
      ID: "00000000-0000-0000-0000-000000000001",
      ObjectID: null,
    },
    {
      name: "Deutsch",
      iso_2: "de",
      iso_3: "deu",
      code: "481",
      locale: "de-DE",
      currency_code: null,
      translator_type: 1,
      ID: "00000000-0000-0000-0000-000000000010",
      ObjectID: null,
    },
    {
      name: "Español",
      iso_2: "es",
      iso_3: "spa",
      code: "230",
      locale: "es-ES",
      currency_code: null,
      translator_type: 1,
      ID: "00000000-0000-0000-0000-000000000004",
      ObjectID: null,
    },
    {
      name: "Français",
      iso_2: "fr",
      iso_3: "fra",
      code: "745",
      locale: "fr-FR",
      currency_code: null,
      translator_type: 1,
      ID: "00000000-0000-0000-0000-000000000009",
      ObjectID: null,
    },
    {
      name: "Italiano",
      iso_2: "it",
      iso_3: "ita",
      code: "235",
      locale: "it-IT",
      currency_code: null,
      translator_type: 1,
      ID: "00000000-0000-0000-0000-000000000018",
      ObjectID: null,
    },
    {
      name: "Português",
      iso_2: "pt",
      iso_3: "por",
      code: "545",
      locale: "pt-BR",
      currency_code: null,
      translator_type: 1,
      ID: "00000000-0000-0000-0000-000000000006",
      ObjectID: null,
    },
    {
      name: "Polski",
      iso_2: "pl",
      iso_3: "pol",
      code: "540",
      locale: "pl-PL",
      currency_code: null,
      translator_type: 1,
      ID: "00000000-0000-0000-0000-000000000017",
      ObjectID: null,
    },
    {
      name: "Suomen",
      iso_2: "fi",
      iso_3: "fin",
      code: "740",
      locale: "fi-FI",
      currency_code: null,
      translator_type: 1,
      ID: "00000000-0000-0000-0000-000000000011",
      ObjectID: null,
    },
    {
      name: "Türkçe",
      iso_2: "tr",
      iso_3: "tur",
      code: "693",
      locale: "tr-TR",
      currency_code: null,
      translator_type: 1,
      ID: "00000000-0000-0000-0000-000000000021",
      ObjectID: null,
    },
    {
      name: "Svenska",
      iso_2: "sv",
      iso_3: "swe",
      code: "805",
      locale: "sv-SV",
      currency_code: null,
      translator_type: 1,
      ID: "00000000-0000-0000-0000-000000000025",
      ObjectID: null,
    },
    {
      name: "Norsk",
      iso_2: "no",
      iso_3: "nor",
      code: "506",
      locale: "no-NO",
      currency_code: null,
      translator_type: 1,
      ID: "00000000-0000-0000-0000-000000000026",
      ObjectID: null,
    },
    {
      name: "Русский",
      iso_2: "ru",
      iso_3: "rus",
      code: "570",
      locale: "ru-RU",
      currency_code: "RUB",
      translator_type: 1,
      ID: "00000000-0000-0000-0000-000000000002",
      ObjectID: null,
    },
    {
      name: "Саха",
      iso_2: "",
      iso_3: "sah",
      code: "865",
      locale: "sah-RU",
      currency_code: null,
      translator_type: null,
      ID: "00000000-0000-0000-0000-000000000012",
      ObjectID: null,
    },
    {
      name: "Татар теле",
      iso_2: "tt",
      iso_3: "tat",
      code: "660",
      locale: "tt-RU",
      currency_code: null,
      translator_type: 1,
      ID: "00000000-0000-0000-0000-000000000019",
      ObjectID: null,
    },
    {
      name: "Українська",
      iso_2: "uk",
      iso_3: "ukr",
      code: "720",
      locale: "uk-UA",
      currency_code: null,
      translator_type: 1,
      ID: "00000000-0000-0000-0000-000000000020",
      ObjectID: null,
    },
    {
      name: "қазақ тілі",
      iso_2: "kk",
      iso_3: "kaz",
      code: "255",
      locale: "kk-KZ",
      currency_code: null,
      translator_type: 1,
      ID: "00000000-0000-0000-0000-000000000024",
      ObjectID: null,
    },
    {
      name: "Bahasa Indonesia",
      iso_2: "id",
      iso_3: "ind",
      code: "210",
      locale: "id-ID",
      currency_code: null,
      translator_type: 1,
      ID: "00000000-0000-0000-0000-000000000028",
      ObjectID: null,
    },
    {
      name: "Հայոց լեզու",
      iso_2: "hy",
      iso_3: "hye",
      code: "055",
      locale: "hy-AM",
      currency_code: null,
      translator_type: 1,
      ID: "00000000-0000-0000-0000-000000000015",
      ObjectID: null,
    },
    {
      name: "עִברִית",
      iso_2: "he",
      iso_3: "heb",
      code: "198",
      locale: "he-IL",
      currency_code: null,
      translator_type: 1,
      ID: "00000000-0000-0000-0000-000000000014",
      ObjectID: null,
    },
    {
      name: "اللغة العربية",
      iso_2: "ar",
      iso_3: "ara",
      code: "50",
      locale: "ar-AR",
      currency_code: null,
      translator_type: 1,
      ID: "00000000-0000-0000-0000-000000000023",
      ObjectID: null,
    },
    {
      name: "हिंदी",
      iso_2: "hi",
      iso_3: "hin",
      code: "770",
      locale: "hi-IN",
      currency_code: null,
      translator_type: 1,
      ID: "00000000-0000-0000-0000-000000000005",
      ObjectID: null,
    },
    {
      name: "ไทย",
      iso_2: "th",
      iso_3: "tha",
      code: "645",
      locale: "th-TH",
      currency_code: null,
      translator_type: 1,
      ID: "00000000-0000-0000-0000-000000000022",
      ObjectID: null,
    },
    {
      name: "ქართული ენა",
      iso_2: "ka",
      iso_3: "kat",
      code: "158",
      locale: "ka-GE",
      currency_code: null,
      translator_type: 1,
      ID: "00000000-0000-0000-0000-000000000016",
      ObjectID: null,
    },
    {
      name: "中文",
      iso_2: "zh",
      iso_3: "zho",
      code: "315",
      locale: "zh-CN",
      currency_code: null,
      translator_type: 1,
      ID: "00000000-0000-0000-0000-000000000013",
      ObjectID: null,
    },
    {
      name: "日本語",
      iso_2: "ja",
      iso_3: "jpn",
      code: "870",
      locale: "ja-JP",
      currency_code: null,
      translator_type: 1,
      ID: "00000000-0000-0000-0000-000000000007",
      ObjectID: null,
    },
    {
      name: "繁體中文",
      iso_2: "zh",
      iso_3: "zho",
      code: "315",
      locale: "zh-TW",
      currency_code: null,
      translator_type: 1,
      ID: "00000000-0000-0000-0000-000000000003",
      ObjectID: null,
    },
    {
      name: "한국인",
      iso_2: "ko",
      iso_3: "kor",
      code: "330",
      locale: "ko-KR",
      currency_code: null,
      translator_type: 1,
      ID: "00000000-0000-0000-0000-000000000008",
      ObjectID: null,
    },
    {
      name: "Lingua latina",
      iso_2: "la",
      iso_3: "lat",
      code: "308",
      locale: "la-LA",
      currency_code: null,
      translator_type: 1,
      ID: "00000000-0000-0000-0000-000000000027",
      ObjectID: null,
    },
    {
      name: "فارسی",
      iso_2: "fa",
      iso_3: "fas",
      code: "535",
      locale: "fa-IR",
      currency_code: null,
      translator_type: 1,
      ID: "00000000-0000-0000-0000-000000000029",
      ObjectID: null,
    },
    {
      name: "Mandiŋka kango",
      iso_2: "",
      iso_3: "mnk",
      code: "",
      locale: "mnk-GM",
      currency_code: null,
      translator_type: null,
      ID: "00000000-0000-0000-0000-000000000030",
      ObjectID: null,
    },
    {
      name: "Kiswahili",
      iso_2: "",
      iso_3: "swa",
      code: "",
      locale: "swa-SW",
      currency_code: null,
      translator_type: null,
      ID: "00000000-0000-0000-0000-000000000031",
      ObjectID: null,
    },
    {
      name: "Gĩkũyũ",
      iso_2: "",
      iso_3: "kik",
      code: "",
      locale: "kik-KI",
      currency_code: null,
      translator_type: null,
      ID: "00000000-0000-0000-0000-000000000032",
      ObjectID: null,
    },
    {
      name: "Luganda",
      iso_2: "",
      iso_3: "lug",
      code: "",
      locale: "lug-LG",
      currency_code: null,
      translator_type: null,
      ID: "00000000-0000-0000-0000-000000000033",
      ObjectID: null,
    },
    {
      name: "Serbian",
      iso_2: "rs",
      iso_3: "srb",
      code: "",
      locale: "sr-RS",
      currency_code: null,
      translator_type: 1,
      ID: "00000000-0000-0000-0000-000000000034",
      ObjectID: null,
    },
    {
      name: "Tiếng Việt",
      iso_2: "vi",
      iso_3: "vie",
      code: "",
      locale: "vi-VN",
      currency_code: null,
      translator_type: 1,
      ID: "00000000-0000-0000-0000-000000000035",
      ObjectID: null,
    },
  ],
  adminRoleID: "033542ac-c537-492e-9d6c-c872624d5446",
  userRoleID: "7f0f15a0-b708-452e-856b-41b4df080f81",
  googleMapDarkTheme: [
    // https://developers.google.com/maps/documentation/javascript/examples/style-array#maps_style_array-javascript
    { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
    {
      elementType: "labels.text.stroke",
      stylers: [{ color: "#242f3e" }],
    },
    {
      elementType: "labels.text.fill",
      stylers: [{ color: "#746855" }],
    },
    {
      featureType: "administrative.locality",
      elementType: "labels.text.fill",
      stylers: [{ color: "#d59563" }],
    },
    {
      featureType: "poi",
      elementType: "labels.text.fill",
      stylers: [{ color: "#d59563" }],
    },
    {
      featureType: "poi.park",
      elementType: "geometry",
      stylers: [{ color: "#263c3f" }],
    },
    {
      featureType: "poi.park",
      elementType: "labels.text.fill",
      stylers: [{ color: "#6b9a76" }],
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [{ color: "#38414e" }],
    },
    {
      featureType: "road",
      elementType: "geometry.stroke",
      stylers: [{ color: "#212a37" }],
    },
    {
      featureType: "road",
      elementType: "labels.text.fill",
      stylers: [{ color: "#9ca5b3" }],
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [{ color: "#746855" }],
    },
    {
      featureType: "road.highway",
      elementType: "geometry.stroke",
      stylers: [{ color: "#1f2835" }],
    },
    {
      featureType: "road.highway",
      elementType: "labels.text.fill",
      stylers: [{ color: "#f3d19c" }],
    },
    {
      featureType: "transit",
      elementType: "geometry",
      stylers: [{ color: "#2f3948" }],
    },
    {
      featureType: "transit.station",
      elementType: "labels.text.fill",
      stylers: [{ color: "#d59563" }],
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [{ color: "#17263c" }],
    },
    {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [{ color: "#515c6d" }],
    },
    {
      featureType: "water",
      elementType: "labels.text.stroke",
      stylers: [{ color: "#17263c" }],
    },
  ],
  libraryObjectGenAI: "ada3d7e8-7349-4c0f-ae79-5d8982fec9fe",
};

export default ({ app }) => {
  app.config.globalProperties.$constants = Constantes;
};

export { Constantes };
