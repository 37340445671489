import { mapGetters } from "vuex";
import chat from "src/components/inbox/chat.vue";
import noAccessByTariff from "src/components/have-no-access-by-tariff.vue";
import api from "src/h/api.js";
import { v4 as uuidv4 } from "uuid";

export default {
  name: "Inbox",
  components: {
    chat,
    noAccessByTariff,
  },
  data() {
    return {
      activeList: [],
      selectedInbox: {},
      streamerChatUser: null,
      writeTo: {
        type: null,
        objectID: null,
      },
      showInbox: false,
      newMessages: 0,
      inboxAvailable: true,
      sessionID: uuidv4(),
    };
  },
  mounted() {
    this.checkInboxAvailable();
    this.GetActiveList();
  },
  beforeUnmount() {
    this.StreamerChatUserTicketRemove(200);
  },
  computed: {
    // currentchallenge ?
    ...mapGetters(["currentchannel", "createdChallenge", "playlistChannelID"]),
    userTariffChat() {
      return "Yes";
      // this.$store.getters.userTariff?.capabilities?.[
      //   this.$constants.capabilities.chatid
      // ].Value.String;
    },
  },
  watch: {
    // userTariffChat: {
    //   handler(newVal, oldVal) {
    //     this.checkInboxAvailable();
    //     let cap = this.$store.getters.userTariff?.capabilities;
    //     gtag("event", "userTariffChat", {
    //       event_category: "billing",
    //       event_label: "Billing access guard for the tariff " + cap + " opened",
    //     });
    //   },
    //   immediate: true,
    // },
    "$streamer.isConnect.value": {
      handler(newVal, oldVal) {
        if (newVal === true && oldVal === false) {
          // need check because from store newVal/oldVal can be false/undefined
          // this.StreamerChatUserTicketConnect()
          this.GetActiveList();
        }
      },
      immediate: true,
    },
    "$store.getters.currentchannel.ID": {
      handler() {
        this.checkInboxAvailable();
      },
    },
    showInbox: {
      handler(newVal, oldVal) {
        // show dialog inbox - if open dialog and have 'new' message - update read
        // if (newVal && !this.inboxAvailable) {
        //   this.$store.dispatch(
        //     "showNoAccessTariff",
        //     this.$constants.capabilities.chatid
        //   );
        //   this.showInbox = false;
        //   return;
        // }
        if (this.inboxAvailable && newVal === true && oldVal === false) {
          gtag("event", "inboxChatWindowOpened", {
            event_category: "chat",
            event_label: "Chat window opened",
          });
        }
        if (
          this.inboxAvailable &&
          newVal === true &&
          oldVal === false &&
          this.selectedInbox?.MessageStatus === "new"
        ) {
          this.CheckInboxMessageRead();
        }
        // if (newVal === true && oldVal === false) {
        //   if (this.selectedInbox.ID) {
        //     // open dialog with opened chat
        //     this.streamerChatUser.send({
        //       ChatID: this.selectedInbox.ID,
        //       Status: "read",
        //     })
        //   }
        // }
      },
    },
  },
  methods: {
    // get 'active' chat list
    GetActiveList() {
      this.sessionID = uuidv4();
      this.StreamerChatUserTicketRemove();
      this.StreamerChatUserTicketConnect();
      api
        .Call({
          url: "/api/v1/chat/list",
        })
        .then(
          (result) => {
            if (result !== null) {
              this.activeList = result;

              // Don't sort here because we have sort from backend (first chat for system)
              // // Sort read/unread
              // this.activeList.sort((a, b) =>
              //   a.MessageStatus === "new" ? -1 : 1
              // );

              // Count how many unreads
              var newMessages = 0;
              for (var i = 0; i < this.activeList.length; i++) {
                if (this.activeList[i].MessageStatus == "new") {
                  newMessages++;
                }
              }
              this.newMessages = newMessages;
              if (this.newMessages > 0) {
                this.$h.PlayAudiosampleFromURL("chat-notification.wav", 0.01);
              }
            } else {
              this.activeList.splice(0, this.activeList.length, ...[]);
              this.newMessages = 0;
            }
          },
          (e) => {
            console.error("error /api/v1/chat/list: ", e);
          },
        )
        .then(() => {
          // this.StreamerChatUserTicketConnect()
        });
    },
    StreamerChatUserTicketConnect() {
      var lnk = this;
      this.streamerChatUser = this.$streamer.addTicket(
        "inbox_chat_user",
        this.$store.getters.user.ID,
        function (obj) {
          lnk.UpdateChatInfo(obj);
          if (obj.MessageStatus === "new") {
            // important! check status - we don't want loop
            // get status here -> update status = 'read' (if inbox open) -> get status here -> ....
            lnk.CheckInboxMessageRead();
          }
        },
      );
    },
    StreamerChatUserTicketRemove(tm) {
      let lnk = this;
      if (tm > 0) {
        setTimeout(() => {
          if (lnk.streamerChatUser !== null) {
            lnk.streamerChatUser.removeTicket();
          }
        }, tm);
      } else {
        if (lnk.streamerChatUser !== null) {
          lnk.streamerChatUser.removeTicket();
        }
      }
    },
    ChooseInboxElement(obj) {
      if (this.selectedInbox.ID !== obj.ID) {
        this.selectedInbox = obj;
        this.writeTo.type = null;
        this.writeTo.objectID = null;
        this.CheckInboxMessageRead();
      }
    },
    // we create new chat or update status
    UpdateChatInfo(obj) {
      var exts = false;
      for (var i = 0; i < this.activeList.length; i++) {
        if (this.activeList[i].ID === obj.ID) {
          exts = true;
          if (obj.MessageStatus != this.activeList[i].MessageStatus) {
            if (obj.MessageStatus === "new") {
              this.newMessages++;
              this.$h.PlayAudiosampleFromURL("chat-notification.wav", 0.2);
            } else {
              this.newMessages--;
            }
          }
          // we know, only MessageStatus can change
          this.activeList[i].MessageStatus = obj.MessageStatus;
          break;
        }
        // Sort read/unread - don't sort! (we sort from api - fix 'system')
        // this.activeList.sort((a, b) => (a.MessageStatus === "new" ? -1 : 1));
      }
      if (!exts) {
        // we know - first chat is system
        if (this.activeList && this.activeList.length > 0) {
          this.activeList.splice(1, 0, obj);
        } else {
          this.activeList.unshift(obj);
        }
        if (obj.MessageStatus === "new") {
          this.newMessages++;
          this.$h.PlayAudiosampleFromURL("chat-notification.wav", 0.2);
        } else {
          this.newMessages--;
        }
      }
    },
    CreatedNewChat(obj) {
      this.UpdateChatInfo(obj);
      this.ChooseInboxElement(obj);
    },
    CheckInboxMessageRead() {
      // if (this.showInbox && this.selectedInbox?.MessageStatus === "new") {
      if (this.showInbox && this.selectedInbox.ID) {
        this.streamerChatUser.send({
          ChatID: this.selectedInbox.ID,
          Status: "read",
        });
      }
    },
    openInboxEvent() {
      gtag("event", "showInboxWindow", {
        event_category: "Inbox",
        event_label: "Inbox opened",
      });
    },
    checkInboxAvailable() {
      if (this.userTariffChat === "Yes") {
        this.inboxAvailable = true;
        return;
      }
      if (!this.$store.getters.currentchannel.ID) {
        this.inboxAvailable = false;
        return;
      }
      api
        .Call({
          url:
            `/billing/check_quota_tariff_limit/` +
            this.$constants.capabilities.chatid +
            `?channelID=` +
            this.$store.getters.currentchannel.ID,
          method: "get",
          show_error: false,
        })
        .then(
          (c) => {
            this.inboxAvailable = c.Access === true;
          },
          (e) => {
            this.inboxAvailable = false;
          },
        );
    },
  },
};
