import api from "src/h/api.js";
import { v4 as uuidv4 } from "uuid";
import languages from "src/pages/edit/languages.vue";
import classroomcard from "src/components/classroomcard.vue";
import { Notify } from "quasar";
import reportcard from "src/components/reportcard.vue";
import Action from "src/pages/reports/action.vue";
import Lib from "src/h/helpers.js";
import AcademicPerformancePerUser from "./templates/academicPerformancePerUser.vue";
import EmotionStatus from "./templates/emotionStatus.vue";
import moment from "moment";

export default {
  props: {
    view: {
      // {uid, height, fit (true/false) - false if don't show list in left part}
      type: Object,
      default: function () {
        return {};
      },
    },
    data: {
      // {objectid, objecttype}
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  components: {
    Action,
    languages,
    classroomcard,
    reportcard,
    AcademicPerformancePerUser,
    EmotionStatus,
  },
  data: () => ({
    uid: uuidv4(),
    limitMessagesPerPage: 50,
    height: 200,
    fit: true,
    objectID: null,
    objectType: null,
    paramsURL: {},
    sendingEmail: false,

    connected: null,
    streamerReports: null,
    sessionID: uuidv4(),
    groupList: [],
    newReportObject: {},

    filter: {
      Report: {
        ReportID: null,
      },
      IsOnlyMy: true,

      SelectReportID: null,
    },
    refcardkeys: {},

    deletingReports: new Map(),
    openingreport: false,
    selectedReport: {},
    selectedReportParams: {},

    foundList: {},
    foundArr: [],
    isSearchLoading: false,
    isSearchScrollEnd: false,
    fnScrollDone: null, // q-infinite-scroll done function

    xlsxdownloading: false,
  }),
  watch: {
    "$streamer.isConnect.value": {
      handler(newVal, oldVal) {
        if (newVal === true && oldVal === false) {
          this.sessionID = uuidv4();
          this.streamerReportsDisconnect();
          this.streamerReportsConnect();
        }
      },
      immediate: false,
    },
    "selectedReport.ID": {
      handler(newVal, oldVal) {
        this.sendingEmail = false;

        if (this.selectedReport?.Params) {
          if (typeof this.selectedReport.Params == "string") {
            this.selectedReportParams = JSON.parse(this.selectedReport.Params);
          } else {
            this.selectedReportParams = this.selectedReport.Params;
          }
        } else {
          this.selectedReportParams = {};
        }
      },
    },
    $route(to, from) {
      if (
        (from.name === "route-reports" && to.name === "route-reports-object") ||
        (to.name === "route-reports" && from.name === "route-reports-object")
      ) {
        window.location.reload();
      }
    },
    // "filter.Report": {
    //   handler(newVal, oldVal) {
    //     if (oldVal && newVal) {
    //       this.search();
    //     }
    //   },
    // },
  },
  beforeMount() {
    if (this.view.uid) {
      this.uid = this.view.uid;
    }
    if (this.view.height) {
      this.height = this.view.height;
    } else {
      this.height = window.innerHeight;
    }

    if (this.view.fit === false) {
      this.fit = false;
    }

    // get some params from url (separate page) or like prop
    this.objectID = this.data.objectid;
    this.objectType = this.data.objecttype;
    if (!this.objectID && this.$route.params.objectid) {
      this.objectID = this.$route.params.objectid;
    }
    if (!this.objectType && this.$route.params.objecttype) {
      this.objectType = this.$route.params.objecttype;
    }
    // for WS connect important default values
    if (!this.objectID) {
      this.objectID = "";
    }
    if (!this.objectType) {
      this.objectType = "none";
    }
  },
  mounted() {
    this.streamerReportsConnect();
    this.deletingReports.clear();
  },
  beforeUnmount() {
    this.streamerReportsDisconnect(200);
  },
  methods: {
    selectReportType(report) {
      if (this.filter.Report?.ReportID === report.ReportID) {
        this.filter.Report = {
          ReportID: null,
        };
        this.filter.SelectReportID = null;
      } else {
        this.filter.Report = report;
        this.filter.SelectReportID = report.ReportID;
      }
      this.search();
      this.$refs["action_" + this.uid].setReportType(this.filter.Report);
    },
    selectReportTypeByID() {
      this.groupList.forEach((item) => {
        if (item.ReportID == this.filter.SelectReportID) {
          this.selectReportType(item);
          return;
        }
      });
      if (this.filter.SelectReportID == null) {
        // clear select report
        this.selectReportType(this.filter.Report);
      }
    },
    streamerReportsConnect() {
      var lnk = this;
      lnk.streamerReports = lnk.$streamer.addTicket(
        "report",
        // "classroom_c1065f10-d301-41fa-90a8-502d343189ef", // test
        this.objectType + "_" + this.objectID,
        function (obj, sessionID) {
          switch (obj.Operation) {
            case "get_report_group":
              if (obj.Object) {
                // // test
                // for (let i = 0; i < 100; i++) {
                //   obj.Object.push({
                //     ReportID: uuidv4(),
                //     GroupName: "test_" + i,
                //   });
                // }
                lnk.groupList = obj.Object;
              } else {
                lnk.groupList.splice(0, lnk.groupList.length);
              }
              if (obj.Object.ChatID) {
                lnk.chatID = obj.Object.ChatID;
              }
              lnk.connected = true;
              break;
            case "run_report":
              if (sessionID == this.sessionID) {
                // close dialog
                if (lnk.$refs["action_" + lnk.uid]) {
                  lnk.$refs["action_" + lnk.uid].endCreateReport(true);
                }
                if (lnk.selectedReport?.ID) {
                  // user added new report, but right now in show report card, close it
                  lnk.selectedReport = {};
                }
              }
              if (
                (lnk.filter.IsOnlyMy &&
                  lnk.$store.getters.user?.ID !== obj.Object.createdby) ||
                (lnk.filter?.Report?.GroupID &&
                  lnk.filter?.Report?.GroupID !== obj.Object.GroupID)
              ) {
                break;
              }
              if (!lnk.foundList.hasOwnProperty(obj.Object.ID)) {
                lnk.foundList[obj.Object.ID] = null;
                lnk.refcardkeys[obj.Object.ID] = 0;
                lnk.foundArr.splice(0, 0, ...[obj.Object]);
              }

              break;
            case "get_list":
              let lng = 0;
              if (obj.Object !== null && obj.Object !== undefined) {
                lng = obj.Object.length;
              }
              if (lng < lnk.limitMessagesPerPage) {
                lnk.isSearchScrollEnd = true;
              }
              if (lng > 0) {
                for (let i = 0; i < obj.Object.length; i++) {
                  if (!lnk.foundList.hasOwnProperty(obj.Object[i].ID)) {
                    lnk.foundList[obj.Object[i].ID] = obj.Object[i];
                    lnk.refcardkeys[obj.Object[i].ID] = 0;
                    lnk.foundArr.splice(
                      lnk.foundArr.length,
                      0,
                      ...[obj.Object[i]],
                    );
                  }
                }
              }

              if (lnk.fnScrollDone) lnk.fnScrollDone(lnk.isSearchScrollEnd);
              lnk.isSearchLoading = false;

              break;
            case "delete_report":
              lnk.deletingReports.delete(obj.Object.ID);
              if (lnk.foundList.hasOwnProperty(obj.Object.ID)) {
                delete lnk.foundList[obj.Object.ID];
                for (let i = 0; i < lnk.foundArr.length; i++) {
                  if (lnk.foundArr[i].ID === obj.Object.ID) {
                    lnk.foundArr.splice(i, 1);
                    delete lnk.refcardkeys[obj.Object.ID];
                    break;
                  }
                }
              }
              break;
            case "report_status_changed":
              if (lnk.foundList.hasOwnProperty(obj.Object.ID)) {
                for (let i = 0; i < lnk.foundArr.length; i++) {
                  if (lnk.foundArr[i].ID === obj.Object.ID) {
                    if (!obj.Object.CreatedByName) {
                      // if don't return by ws username don't need copy it
                      obj.Object.CreatedByName = lnk.foundArr[i].CreatedByName;
                    }
                    Lib.CopyObject(obj.Object, lnk.foundArr[i]);
                    break;
                  }
                }
                lnk.refcardkeys[obj.Object.ID]++;
              }
              break;
            case "get_report":
              if (
                typeof obj.Object.Params == "string" &&
                obj.Object.Params != ""
              ) {
                obj.Object.Params = JSON.parse(obj.Object.Params);
              }
              if (
                typeof obj.Object.Result == "string" &&
                obj.Object.Result != ""
              ) {
                obj.Object.Result = JSON.parse(obj.Object.Result);
              }
              lnk.selectedReport = obj.Object;
              lnk.openingreport = false;
              break;
            case "send_email":
              lnk.sendingEmail = false;
              Notify.create({
                group: true,
                timeout: 10000,
                message: lnk.$t("-raw-report-email-sent"),
                color: "1",
                textColor: "p",
                classes: "round-both q-ml-lg q-mb-sm",
                position: "bottom",
              });
              break;
          }
        },
        function (obj, lastSend, lastOp) {
          let res = JSON.parse(obj);
          if (lastOp == "generate_ticket_token") {
            lnk.connected = false;
          } else if (lastOp == "message") {
            switch (lastSend?.Operation) {
              case "get_report_group":
              case "run_report":
              case "get_list":
              case "delete_report":
              case "get_report":
              case "send_email":
                Notify.create({
                  group: true,
                  timeout: 10000,
                  message:
                    res?.ErrCode === "ret-2000"
                      ? res?.Err
                      : lnk.$t("-raw-error-occurred"),
                  color: "1",
                  textColor: "n",
                  classes: "round-both q-ml-lg q-mb-sm",
                  position: "bottom",
                });
                break;
            }
            switch (lastSend?.Operation) {
              case "run_report":
                lnk.$refs["action_" + lnk.uid].endCreateReport(false);
              case "get_list":
                lnk.isSearchLoading = false;
              case "delete_report":
                // lnk.deletingReports.delete(ID);
                lnk.deletingReports.clear();
              case "get_report":
                lnk.openingreport = false;
              case "send_email":
                lnk.sendingEmail = false;
            }
          }
        },
        this.sessionID,
        function () {
          lnk.streamerReports.send({
            Operation: "get_report_group",
            Object: {},
          });
        },
      );
    },
    streamerReportsDisconnect(tm) {
      let lnk = this;
      if (tm > 0) {
        setTimeout(() => {
          if (lnk.streamerReports !== null) {
            lnk.streamerReports.removeTicket();
          }
        }, tm);
      } else {
        if (lnk.streamerReports !== null) {
          lnk.streamerReports.removeTicket();
        }
      }
    },
    runNewReport(obj) {
      this.streamerReports.send({
        Operation: "run_report",
        Object: obj,
      });
    },
    sendEmail() {
      this.sendingEmail = true;
      this.streamerReports.send({
        Operation: "send_email",
        Object: {
          ReportID: this.selectedReport.ID,
        },
      });
    },
    search() {
      this.refcardkeys = {};
      this.isSearchScrollEnd = false;
      this.foundList = {};
      if (this.selectedReport?.ID) {
        this.selectedReport = {};
      }
      this.foundArr.splice(0, this.foundArr.length, ...[]);
      // signal done loading previous q-infinite-scroll elements and reset
      // if (this.fnScrollDone) this.fnScrollDone(true);

      setTimeout(() => {
        this.$refs["foundScroll_" + this.uid]?.reset();
        this.$refs["foundScroll_" + this.uid]?.resume();
      }, 50);
      // this.$refs["foundScroll_" + this.uid]?.trigger(); // search will be twice!
    },
    searchNextPage(index, done) {
      if (done) this.fnScrollDone = done;
      if (this.isSearchScrollEnd === true) {
        if (done) {
          done(true);
        }
        return;
      }
      this.isSearchLoading = true;

      let offset = Object.keys(this.foundList).length;

      this.streamerReports.send({
        Operation: "get_list",
        Object: {
          Limit: this.limitMessagesPerPage,
          Offset: offset,
          GroupID: this.filter?.Report?.GroupID,
          IsOnlyMy: this.filter?.IsOnlyMy,
        },
      });
    },
    deleteReport(ID) {
      const lnk = this;

      api.CallConfirmation(
        {
          method: "delete",
          message: this.$t("-raw-confirm-delete-report"),
          buttonOk: this.$t("-raw-tooltip-delete"),
        },
        () => {
          return new Promise((resolve, reject) => {
            lnk.deletingReports.set(ID, true);
            lnk.streamerReports.send({
              Operation: "delete_report",
              Object: {
                ID: ID,
              },
            });
            resolve();
          });
        },
      );
    },

    openReport(rep) {
      if (rep.Status !== "done") {
        return;
      }

      var tp = [];
      this.groupList.forEach((item) => {
        if (item.ReportID == rep.ReportID) {
          switch (item.GroupID) {
            case "academic_performance_per_user":
            case "emotion_status":
              tp.push("data");
          }
          if (tp.length == 0 && item.EmailTemplate) {
            tp.push("emailtemplate");
          }
          return;
        }
      });

      this.openingreport = true;
      this.streamerReports.send({
        Operation: "get_report",
        Object: {
          ID: rep.ID,
          Data: tp.join(";"),
        },
      });
    },

    formatTimestamp(ts) {
      if (!ts) return "";
      return moment(ts).format("L LT");
    },

    formatDate(ts) {
      if (!ts) return "";
      return moment(ts).local().format("L LT");
    },
    downloadXlsx() {
      this.xlsxdownloading = true;
      api
        .Call({
          url: "/api/v1/asset/file/report/xlsx/" + this.selectedReport.Link, //  +"?t=" +Lib.GetNanoSecTime(),
          responseType: "arraybuffer", // important! or file will be corrupted
          headers: {
            "Content-Type": "application/octet-stream",
          },
          show_error: true,
        })
        .then(
          (result) => {
            var blob = new Blob([result], { type: "application/octet-stream" });
            var link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob, {
              oneTimeOnly: true,
            });
            // link.download = this.selectedReport.Link + ".xlsx";
            link.download =
              this.selectedReport.GroupName +
              (this.selectedReport.ObjectType !== "none"
                ? " for " +
                  this.selectedReport.ObjectType +
                  " " +
                  this.selectedReport.ObjectName
                : "") +
              " at " +
              this.selectedReport.createdat +
              ".xlsx";
            link.click();
            this.xlsxdownloading = false;
          },
          (e) => {
            this.xlsxdownloading = false;
            // commit("setError", { code: "error get xlsx report" });
            console.error("error", "error get xlsx report: ", e);
          },
        );
    },
  },
};
