<template>
  <q-checkbox v-model="group" label="Group" />
  <Bubble
    v-if="showChart"
    id="chart-bubble"
    :data="chartData"
    :options="chartOptions"
    style="height: 45vh"
  />
</template>

<script>
import { Bubble } from "vue-chartjs";
import { Chart as ChartJS, registerables } from "chart.js";
import { getCssVar } from "quasar";
import { i18n } from "../../../boot/i18n";

var colorsBubble = ["777DDD", "FF8800"];

ChartJS.register(...registerables);

export default {
  name: "RusselsCircumplex",
  props: ["item"],
  components: { Bubble },
  data() {
    return {
      showChart: false,
      group: false,
      chartData: {
        datasets: [
          {
            label: i18n.global.t("-raw-general-attempts"),
            backgroundColor: function (obj) {
              return "#" + colorsBubble[1];
            },
            order: 999,
            data: [],
          },
          {
            label: i18n.global.t("-raw-general-emotion-love"),
            backgroundColor: this.getColor(0.91, 0.82),
            radius: 10,
            rotation: 0,
            pointStyle: "triangle",
            data: [{ x: 0.91, y: 0.82 }],
          },
          {
            label: i18n.global.t("-raw-general-emotion-joy"),
            backgroundColor: this.getColor(0.89, 0.88),
            order: 1,
            radius: 10,
            rotation: 0,
            pointStyle: "triangle",
            data: [{ x: 0.89, y: 0.88 }],
          },
          {
            label: i18n.global.t("-raw-general-emotion-surprise"),
            backgroundColor: this.getColor(0.48, 0.78),
            order: 1,
            radius: 10,
            rotation: 0,
            pointStyle: "triangle",
            data: [{ x: 0.48, y: 0.78 }],
          },
          {
            label: i18n.global.t("-raw-general-emotion-anger"),
            backgroundColor: this.getColor(-0.78, 0.82),
            order: 1,
            radius: 10,
            rotation: 0,
            pointStyle: "triangle",
            data: [{ x: -0.78, y: 0.82 }],
          },
          {
            label: i18n.global.t("-raw-general-emotion-sadness"),
            backgroundColor: this.getColor(-0.68, -0.48),
            order: 1,
            radius: 10,
            rotation: 0,
            pointStyle: "triangle",
            data: [{ x: -0.68, y: -0.48 }],
          },
          {
            label: i18n.global.t("-raw-general-emotion-fear"),
            backgroundColor: this.getColor(-0.82, 0.68),
            order: 1,
            radius: 10,
            rotation: 0,
            pointStyle: "triangle",
            data: [{ x: -0.82, y: 0.68 }],
          },
          {
            label: i18n.global.t("-raw-general-emotion-disgust"),
            backgroundColor: this.getColor(-0.58, 0.28),
            order: 1,
            radius: 10,
            rotation: 0,
            pointStyle: "triangle",
            data: [{ x: -0.58, y: 0.28 }],
          },
          {
            label: i18n.global.t("-raw-general-emotion-trust"),
            backgroundColor: this.getColor(0.68, 0.18),
            order: 1,
            radius: 10,
            rotation: 0,
            pointStyle: "triangle",
            data: [{ x: 0.68, y: 0.18 }],
          },
          {
            label: i18n.global.t("-raw-general-emotion-anticipation"),
            backgroundColor: this.getColor(0.28, 0.62),
            order: 1,
            radius: 10,
            rotation: 0,
            pointStyle: "triangle",
            data: [{ x: 0.28, y: 0.62 }],
          },
          {
            label: i18n.global.t("-raw-general-emotion-desire"),
            backgroundColor: this.getColor(0.82, 0.72),
            order: 1,
            radius: 10,
            rotation: 0,
            pointStyle: "triangle",
            data: [{ x: 0.82, y: 0.72 }],
          },
          {
            label: i18n.global.t("-raw-general-emotion-shame"),
            backgroundColor: this.getColor(-0.58, -0.28),
            order: 1,
            radius: 10,
            rotation: 0,
            pointStyle: "triangle",
            data: [{ x: -0.58, y: -0.28 }],
          },
          {
            label: i18n.global.t("-raw-general-emotion-contempt"),
            backgroundColor: this.getColor(-0.48, 0.12),
            order: 1,
            radius: 10,
            rotation: 0,
            pointStyle: "triangle",
            data: [{ x: -0.48, y: 0.12 }],
          },
          {
            label: i18n.global.t("-raw-general-emotion-awe"),
            backgroundColor: this.getColor(0.62, 0.52),
            order: 1,
            radius: 10,
            rotation: 0,
            pointStyle: "triangle",
            data: [{ x: 0.62, y: 0.52 }],
          },
          {
            label: i18n.global.t("-raw-general-emotion-embarrassment"),
            backgroundColor: this.getColor(-0.62, 0.92),
            order: 1,
            radius: 10,
            rotation: 0,
            pointStyle: "triangle",
            data: [{ x: -0.62, y: 0.92 }],
          },
          {
            label: i18n.global.t("-raw-general-emotion-pride"),
            backgroundColor: this.getColor(0.38, 0.28),
            order: 1,
            radius: 10,
            rotation: 0,
            pointStyle: "triangle",
            data: [{ x: 0.38, y: 0.28 }],
          },
          {
            label: i18n.global.t("-raw-general-emotion-relief"),
            backgroundColor: this.getColor(0.68, -0.18),
            order: 1,
            radius: 10,
            rotation: 0,
            pointStyle: "triangle",
            data: [{ x: 0.68, y: -0.18 }],
          },
          {
            label: i18n.global.t("-raw-general-emotion-hope"),
            backgroundColor: this.getColor(0.72, 0.28),
            order: 1,
            radius: 10,
            rotation: 0,
            pointStyle: "triangle",
            data: [{ x: 0.72, y: 0.28 }],
          },
          {
            label: i18n.global.t("-raw-general-emotion-compassion"),
            backgroundColor: this.getColor(0.58, 0.38),
            order: 1,
            radius: 10,
            rotation: 0,
            pointStyle: "triangle",
            data: [{ x: 0.58, y: 0.38 }],
          },
          {
            label: i18n.global.t("-raw-general-emotion-gratitude"),
            backgroundColor: this.getColor(0.68, 0.28),
            order: 1,
            radius: 10,
            rotation: 0,
            pointStyle: "triangle",
            data: [{ x: 0.68, y: 0.28 }],
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: true,
        borderColor: "#fff",
        plugins: {
          legend: {
            display: true,
          },
          tooltip: {
            enabled: true,
            callbacks: {
              label: function (context) {
                if (
                  context.dataset.label ===
                  i18n.global.t("-raw-general-attempts")
                ) {
                  return [
                    `${i18n.global.t("-raw-general-attempt")}`,
                    `${i18n.global.t(
                      "-raw-general-valence",
                    )} ${context.parsed.x.toFixed(2)}`,
                    `${i18n.global.t(
                      "-raw-general-arousal",
                    )} ${context.parsed.y.toFixed(2)}`,
                  ];
                } else {
                  return context.dataset.label;
                }
              },
            },
          },
          title: {
            display: true,
            text: "The Russell’s Circumplex Model of Affect",
            font: {
              size: 24,
            },
          },
          drawAddition: {},
        },
        scales: {
          y: {
            grid: {
              lineWidth: function (obj) {
                if (obj.index === 10) {
                  return 3;
                }
                return 1;
              },
              color: getCssVar("2"),
            },
            border: {
              dash: function (obj) {
                if (obj.index === 10) {
                  return [];
                }
                return [10, 5];
              },
              color: getCssVar("2"),
            },
            title: {
              display: true,
              text: "Arousal",
            },
            // suggestedMin: 50,
            // suggestedMax: 100,
            ticks: {
              stepSize: 0.1,
              display: true,
            },
            max: 1,
            min: -1,
          },
          x: {
            grid: {
              lineWidth: function (obj) {
                if (obj.index === 10) {
                  return 3;
                }
                return 1;
              },
              color: getCssVar("2"),
            },
            border: {
              dash: function (obj) {
                if (obj.index === 10) {
                  return [];
                }
                return [10, 5];
              },
              color: getCssVar("2"),
            },
            title: {
              display: true,
              text: "Valence",
            },
            ticks: {
              stepSize: 0.1, // forces step size
              display: true,
            },
            max: 1,
            min: -1,
          },
        },
      },
    };
  },
  mounted() {
    this.fillChartData();
  },
  watch: {
    group: {
      handler() {
        this.fillChartData();
      },
    },
  },
  methods: {
    fillChartData() {
      if (!Array.isArray(this.item.Result)) return;
      this.showChart = false;
      if (this.group) {
        this.fillChartDataGrouped();
      } else {
        this.fillChartDataScattered();
      }
      let this_ = this;
      this.$nextTick(() => {
        this_.showChart = true;
      });
    },
    fillChartDataGrouped() {
      // Define the step size for the grid
      const stepSize = 0.1;

      // Find the minimum and maximum values for Valence and Arousal
      let minValence = Infinity;
      let maxValence = -Infinity;
      let minArousal = Infinity;
      let maxArousal = -Infinity;
      for (let point of this.item.Result) {
        minValence = -1;
        maxValence = 1;
        minArousal = -1;
        maxArousal = 1;
      }

      // Calculate the number of rows and columns in the grid
      const numRows = Math.round((maxArousal - minArousal) / stepSize);
      const numCols = Math.round((maxValence - minValence) / stepSize);

      // Create the grid array and initialize each cell to zero
      let grid = new Array(numRows);
      for (let i = 0; i < numRows; i++) {
        grid[i] = new Array(numCols);
      }

      // Loop over the data points and increment the corresponding cell in the grid
      for (let point of this.item.Result) {
        let row = Math.min(
          numRows - 1,
          Math.floor((point.Arousal - minArousal) / stepSize),
        );
        let col = Math.min(
          numCols - 1,
          Math.floor((point.Valence - minValence) / stepSize),
        );
        if (!grid[row][col]) grid[row][col] = {};
        grid[row][col].cnt = (grid[row][col].cnt || 0) + 1;
        // grid[row][col].Valence = (grid[row][col].Valence || 0) + point.Valence;
        // grid[row][col].Arousal = (grid[row][col].Arousal || 0) + point.Arousal;
      }

      const totalElements = Object.keys(this.item.Result).length;
      this.chartData.datasets[0].data = [];
      for (let i = 0; i < numRows; i++) {
        for (let j = 0; j < numCols; j++) {
          if (grid[i][j]?.cnt > 0) {
            this.chartData.datasets[0].data.push({
              x: minValence + j * stepSize + stepSize / 2,
              y: minArousal + i * stepSize + stepSize / 2,
              r: Math.min(50, (grid[i][j].cnt * 500) / totalElements),
            });
          }
        }
      }
    },
    fillChartDataScattered() {
      this.chartData.datasets[0].data = [];
      this.item.Result.forEach((res) => {
        this.chartData.datasets[0].data.push({
          x: res.Valence,
          y: res.Arousal,
          r: 4,
        });
      });
    },
    getColor(x, y) {
      // hsl rotated 120deg for left top corner to be red

      const hue = Math.atan2(y, x) - (2 * Math.PI) / 3; // angle
      const saturation = Math.sqrt(x * x + y * y) / Math.sqrt(2); // normalized distance
      return `hsl(${hue}rad, ${saturation * 100}%, 50%)`;
    },
  },
};
</script>
