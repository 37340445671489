<template>
  <div class="row no-wrap">
    <div class="row q-my-auto q-gutter-xs ubuntu items-baseline">
      <div
        style="font-size: 1.5rem; font-weight: 800"
        :class="rating ? 'text-v' : 'text-5'"
      >
        <q-icon name="star" />{{ rating ? rating.toFixed(1) : "-" }}
      </div>
      <div v-show="countrating" class="text-5">/{{ countrating }}</div>
      <q-tooltip
        anchor="top middle"
        self="bottom middle"
        class="ubuntu bg-4 text-8 round-both"
        transition-show="jump-up"
        transition-hide="jump-down"
      >
        <b>
          <span v-if="countrating"
            >{{ $t("-raw-challenge-rated-by") }} {{ countrating }}
            {{ $t("-raw-challenge-rated-by-users") }}</span
          >
          <span v-else>{{ $t("-raw-challenge-be-the-first-to-rate") }}</span>
        </b>
      </q-tooltip>
    </div>
    <div
      style="font-weight: 400; font-size: 0.75rem"
      class="q-my-auto text-8 q-mx-sm column no-wrap"
    >
      <q-rating
        no-reset
        v-model="score"
        class="no-shadow"
        size="1.5rem"
        :color="score ? 'v' : '5'"
        icon-selected="star"
        icon-half="star_half"
        icon="star_border"
        :max="5"
        :disable="loading"
        style="min-width: 8rem"
        @update:model-value="updateScore"
      />
      <div v-if="createdat">
        {{ formatDate(createdat) }}
      </div>
    </div>
  </div>
</template>

<script>
import api from "src/h/api.js";

export default {
  props: {
    pobjecttype: {
      type: String,
      default: "",
    },
    pobjectid: {
      type: String,
      default: "",
    },
    prating: {
      type: Number,
      default: null,
    },
    pcountrating: {
      type: Number,
      default: null,
    },
    pfetchtotal: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    objecttype: null,
    objectid: null,
    score: 0,
    rating: null,
    countrating: null,
    loading: false,
    createdat: null,
  }),
  beforeMount() {
    this.objecttype = this.pobjecttype;
    this.objectid = this.pobjectid;
    this.rating = this.prating;
    this.countrating = this.pcountrating || 0;
    console.log("objectid:", this.objectid);
    if (this.pfetchtotal) this.getTotal();
    this.getScore();
  },
  methods: {
    updateScore(score) {
      this.loading = true;
      api
        .Call({
          url: `/api/v1/rating/${this.objecttype}/${this.objectid}`,
          method: "post",
          data: {
            Score: parseInt(score),
          },
        })
        .then(
          (response) => {
            this.$q.notify({
              classes: "round-both q-ml-lg q-mb-sm",
              message: this.txtRated(),
              textColor: "w",
              color: "1",
              icon: "star",
              group: "rating",
              actions: [
                {
                  icon: "close",
                  color: "w",
                  handler: () => {
                    /* ... */
                  },
                },
              ],
            });
            this.score = response.Score;
            this.createdat = response.updatedat || response.createdat;
            if (response.Total) {
              this.rating = response.Total.Rating;
              this.countrating = response.Total.CountRating;
            }
            this.loading = false;
          },
          (e) => {
            console.error("Rating: ", "error-0968d066", "update rating", e);
            this.$q.notify({
              type: "negative",
              message: e.message,
              classes: "round-both q-mb-sm no-shadow ubuntu",
            });
            this.score = 0;
            this.createdat = null;
            this.loading = false;
          },
        );
    },
    getScore() {
      this.loading = true;
      api
        .Call({
          url: `/api/v1/rating/${this.objectid}`,
          method: "get",
        })
        .then(
          (response) => {
            console.log(response);
            if (response) {
              this.score = response.Score;
              this.createdat = response.updatedat || response.createdat;
            }
            this.loading = false;
          },
          (e) => {
            console.error("Rating: ", "error-a2c46c93", "get rating", e);
            this.loading = false;
          },
        );
    },
    getTotal() {
      this.loading = true;
      api
        .Call({
          url: `/api/v1/rating_total/${this.objecttype}/${this.objectid}`,
          method: "get",
        })
        .then(
          (response) => {
            console.log(response);
            if (response) {
              this.rating = response.Rating;
              this.countrating = response.CountRating;
            }
            this.loading = false;
          },
          (e) => {
            console.error("Rating: ", "error-5349feec", "get total rating", e);
            this.loading = false;
          },
        );
    },
    formatDate(dateStr) {
      if (!dateStr) return "";
      return new Date(dateStr).toLocaleString();
    },
    txtRated() {
      switch (this.objecttype) {
        case "challenge":
          return this.$t("-raw-challenge-rating-thank-you");
        case "channel":
          return this.$t("-raw-channel-rating-thank-you");
        case "playlist":
          return this.$t("-raw-playlist-rating-thank-you");
        case "story":
          return this.$t("-raw-story-rating-thank-you");
        case "classroom":
          return this.$t("-raw-classroom-rating-thank-you");
        case "campus":
          return this.$t("-raw-campus-rating-thank-you");
        default:
          return this.$t("-raw-rating-thank-you");
      }
    },
  },
};
</script>
