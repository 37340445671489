<template>
  <q-select
    borderless
    color="v"
    :options="langs"
    option-value="id"
    option-label="name"
    v-model="language"
    @update:model-value="modelChanged()"
    emit-value
    use-input
    hide-selected
    fill-input
    @filter="inputFilter"
    :menu-offset="[0, 16]"
    menu-anchor="top left"
    menu-self="bottom left"
    popup-content-style="border-radius: 1rem !important;"
    style="height: 3rem; border-radius: 1rem !important"
    :input-style="
      label == 'true' ? 'margin-top: 0rem;' : 'margin-top: -1.5rem;'
    "
    class="full-width bg-0 q-px-md"
    behavior="menu"
    :style="
      required == 'true' && (language == null || !language?.ID)
        ? 'height: 3rem; border-radius: 1rem !important; border: 2px solid var(--q-h);'
        : 'height: 3rem; border-radius: 1rem !important; border: 2px solid var(--q-2);'
    "
    :label="
      label == 'true'
        ? labelval
          ? labelval
          : $t('-raw-source-language')
        : null
    "
    :disable="disabled == 'true' ? 'disable' : null"
  >
    <template #no-option>
      <q-item>
        <q-item-section class="text-grey">No results</q-item-section>
      </q-item>
    </template>
  </q-select>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: [
    "languageid",
    "required", // true, false
    "label", // true, false
    "disabled", // true, false
    "labelval",
  ],
  emits: ["select"],
  data() {
    return {
      language: {},
      langs: [],
    };
  },
  mounted() {
    if (this.languages) {
      this.language = this.languages.find((v) => v.ID === this.languageid);
    }
  },
  computed: {
    ...mapGetters(["languages"]),
  },
  methods: {
    modelChanged(k) {
      this.$emit("select", this.language?.ID);
    },
    inputFilter(val, update) {
      if (val === "") {
        update(() => {
          this.langs = this.languages;
        });
        return;
      }
      update(() => {
        const needle = val.toLowerCase();
        this.langs = this.languages.filter(
          (v) => v.name.toLowerCase().indexOf(needle) > -1,
        );
      });
    },
  },
};
</script>
