<template>
  <div>
    <q-dialog v-model="reportDialog" @hide="loading = false">
      <q-card
        style="border-radius: 1rem; width: 500px; max-width: 80vw"
        class="bg-1 no-shadow"
      >
        <q-card-section class="column col full-width q-ma-md">
          <span class="text-bold q-pb-sm" style="font-size: 1.1rem">{{
            $t("-raw-report-new")
          }}</span>
          <q-select
            v-if="!reportType?.ReportID"
            borderless
            :label="$t('-raw-report-type') + '*'"
            popup-content-style="border-radius: 1rem;"
            style="border-radius: 1rem"
            class="bg-0 q-mr-sm q-px-md q-mt-sm col"
            v-model="params.ReportID"
            :options="reportTypeList"
            option-label="GroupName"
            option-value="ReportID"
            emit-value
            map-options
          >
          </q-select>
          <q-input
            v-else
            type="text"
            :label="$t('-raw-report-type')"
            borderless
            clearable
            v-model="reportType.GroupName"
            style="border-radius: 1rem"
            class="glassy-8 q-px-md full-width bg-transparent q-mt-sm"
            input-class="ubuntu bg-transparent"
            input-style="color: var(--q-a);"
            disable
          />

          <div class="column q-pt-sm" v-if="filter?.DateFrom?.Use">
            <div class="row q-gutter-sm no-wrap">
              <q-input
                borderless
                :label="$t('-raw-report-date-from') + ' *'"
                style="border-radius: 1rem; max-width: 15rem"
                class="glassy-8 q-mx-sm q-px-md bg-transparent col"
                v-model="filterQ.DateFrom"
                @click="$refs['qDateFrom_' + uid].show()"
                :mask="dateMask"
              >
                <template #append>
                  <q-icon name="event" class="cursor-pointer">
                    <q-popup-proxy
                      :ref="'qDateFrom_' + uid"
                      transition-show="scale"
                      transition-hide="scale"
                    >
                      <q-date
                        :mask="dateFormat"
                        v-model="filterQ.DateFrom"
                        @update:model-value="
                          () => $refs['qDateFrom_' + uid].hide()
                        "
                      />
                    </q-popup-proxy>
                  </q-icon>
                </template>
              </q-input>

              <q-input
                borderless
                :label="$t('-raw-report-date-to') + ' *'"
                style="border-radius: 1rem; max-width: 15rem"
                class="glassy-8 q-mx-sm q-px-md bg-transparent col"
                v-model="filterQ.DateTo"
                @click="$refs['qDateTo_' + uid].show()"
                :mask="dateMask"
              >
                <template #append>
                  <q-icon name="event" class="cursor-pointer">
                    <q-popup-proxy
                      :ref="'qDateTo_' + uid"
                      transition-show="scale"
                      transition-hide="scale"
                    >
                      <q-date
                        :mask="dateFormat"
                        v-model="filterQ.DateTo"
                        @update:model-value="
                          () => $refs['qDateTo_' + uid].hide()
                        "
                      />
                    </q-popup-proxy>
                  </q-icon>
                </template>
              </q-input>
            </div>
            <div
              v-if="filter?.DateFromToQuick?.Use"
              class="row q-gutter-md flex-center no-wrap q-pt-sm"
            >
              <q-btn
                align="between"
                class="text-8 bg-1"
                :label="$t('-raw-report-last-day')"
                no-caps
                @click="setPeriodDateFromToQuick(-1, 'days')"
              />
              <q-btn
                align="between"
                class="text-8 bg-1"
                :label="$t('-raw-report-last-week')"
                no-caps
                @click="setPeriodDateFromToQuick(-1, 'weeks')"
              />
              <q-btn
                align="between"
                class="text-8 bg-1"
                :label="$t('-raw-report-last-month')"
                no-caps
                @click="setPeriodDateFromToQuick(-1, 'months')"
              />
            </div>
            <div
              class="row q-gutter-sm no-wrap q-pt-sm"
              v-if="params.ReportID && filterQ.SendEmail"
            >
              <q-checkbox
                v-model="params.SendEmail"
                :label="$t('-raw-report-send-email')"
              />
            </div>
          </div>
        </q-card-section>
        <q-card-actions align="right" class="q-px-md q-pb-md">
          <q-btn
            flat
            no-caps
            :label="$t('-raw-general-cancel')"
            class="bg-2 q-mr-sm"
            color="8"
            style="border-radius: 0.5rem"
            :loading="loading"
            v-close-popup
          />
          <q-btn
            flat
            no-caps
            :label="$t('-raw-route-create')"
            class="bg-v text-0 q-px-lg"
            style="border-radius: 0.5rem"
            :loading="loading"
            :disabled="!params.ReportID"
            @click="runCreateReport()"
            :ref="'rCreateReport_' + uid"
          />
        </q-card-actions>
      </q-card>
    </q-dialog>

    <q-btn
      style="border-radius: 1rem"
      no-caps
      flat
      class="bg-v text-f create q-mr-md ubuntu"
      @click="reportDialog = true"
    >
      <div class="gt-sm q-mr-lg" v-if="!iissmall">
        {{ $t("-raw-report-new") }}
      </div>
      <q-icon name="playlist_add" class="create-icon" />
      <q-tooltip
        v-if="iissmall"
        anchor="top right"
        self="bottom right"
        class="mytooltip round-both ubuntu bg-0 text-v row items-center q-px-md"
        transition-show="jump-right"
        transition-hide="jump-left"
      >
        {{ $t("-raw-report-new") }}
      </q-tooltip>
    </q-btn>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import Lib from "src/h/helpers.js";
import moment from "moment";
import { Notify } from "quasar";

export default {
  name: "Action",
  emits: ["run"],
  props: {
    ireporttype: {
      type: Object,
      default: function () {
        return {};
      },
    },
    ireporttypelist: {
      type: Object,
      default: function () {
        return {};
      },
    },
    iissmall: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
  },
  data: () => ({
    uid: uuidv4(),
    reportDialog: false,
    loading: false,
    reportType: {},
    reportTypeList: [],
    params: {
      ReportID: null,
      Filter: {
        DateFrom: null,
        DateTo: null,
      },
      SendEmail: null,
    },
    filter: {}, // from dictionary
    filterQ: {
      // some params for UI
      DateFrom: null,
      DateTo: null,
      SendEmail: null,
    },
  }),
  watch: {
    "params.ReportID": {
      handler(newVal, oldVal) {
        // if (newVal) { // don't check (newVal maybe null)
        this.setReportFilter();
        // }
      },
    },
    reportDialog: {
      handler(newVal, oldVal) {
        if (newVal) {
          setTimeout(() => {
            let obj = this.$refs["rCreateReport_" + this.uid];
            if (obj) {
              obj.$el.focus();
            }
          }, 50);
        }
      },
    },
  },
  computed: {
    dateFormat() {
      return Lib.getDatePatternByLocale("en");
    },
    dateMask() {
      return Lib.getDatePatternByLocale("en", true);
    },
  },
  mounted() {
    // order init important!
    this.reportTypeList = this.ireporttypelist ? this.ireporttypelist : [];
    this.setReportType(this.ireporttype);
    // set default values for filter
    this.filterQ.DateTo = Lib.formatDateByLocale("en", new Date());
    this.filterQ.DateFrom = Lib.formatDateByLocale(
      "en",
      moment().add("-1", "week"),
      // new Date().setDate(new Date().getDate() - 7),
    );
  },
  methods: {
    setReportType(obj) {
      this.reportType = obj ? obj : {};
      if (obj) {
        this.params.ReportID = obj.ReportID;
      } else {
        this.params.ReportID = null;
      }
    },
    setReportFilter() {
      let fnd = this.ireporttypelist.findIndex(
        (v) => v.ReportID === this.params.ReportID,
      );
      if (fnd > -1 && this.ireporttypelist[fnd].ReportFilter) {
        let res = JSON.parse(this.ireporttypelist[fnd].ReportFilter);
        Lib.CopyObject(res, this.filter);
        if (
          this.params.SendEmail == null &&
          this.ireporttypelist[fnd].EmailTemplate
        ) {
          // first show
          this.params.SendEmail = true;
        }
        if (this.ireporttypelist[fnd].EmailTemplate) {
          this.filterQ.SendEmail = true;
        } else {
          this.filterQ.SendEmail = false;
        }
      } else {
        this.filter = {};
        this.filterQ.SendEmail = false;
      }
    },
    setPeriodDateFromToQuick(amount, period) {
      this.filterQ.DateTo = Lib.formatDateByLocale("en", new Date());
      this.filterQ.DateFrom = Lib.formatDateByLocale(
        "en",
        moment().add(amount, period),
      );
    },
    runCreateReport() {
      this.loading = true;
      if (this.filterQ.DateFrom) {
        this.params.Filter.DateFrom = moment(
          moment(this.filterQ.DateFrom, this.dateFormat),
        )
          .utcOffset(0, true)
          .format();
      } else if (this.filter?.DateFrom?.Use) {
        Notify.create({
          group: true,
          timeout: 10000,
          message: this.$t("-raw-report-select-date-from"),
          color: "1",
          textColor: "n",
          classes: "round-both q-ml-lg q-mb-sm",
          position: "bottom",
        });
        this.loading = false;
        return;
      }
      if (this.filterQ.DateTo) {
        this.params.Filter.DateTo = moment(
          moment(this.filterQ.DateTo, this.dateFormat),
        )
          .utcOffset(0, true)
          .format();
      } else if (this.filter?.DateTo?.Use) {
        Notify.create({
          group: true,
          timeout: 10000,
          message: this.$t("-raw-report-select-date-to"),
          color: "1",
          textColor: "n",
          classes: "round-both q-ml-lg q-mb-sm",
          position: "bottom",
        });
        this.loading = false;
        return;
      }
      this.$emit("run", this.params);
    },
    endCreateReport(success) {
      if (success) {
        this.reportDialog = false;
      } else {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.create-icon {
  transition-duration: 0.2s;
}
.create:hover .create-icon {
  transform: rotate(90deg);
}
</style>
