<script src="./index.js"></script>
<template>
  <q-page
    class="boxshadow no-wrap"
    style="overflow-y: hidden"
    :class="$q.screen.gt.sm ? 'column full-width' : 'row'"
    ref="classroomRef"
  >
    <q-scroll-area
      visible
      class="q-pa-sm col-12"
      :style="{ height: `calc(${window.innerHeight}px - 10rem)` }"
    >
      <div
        class="column col-12 items-center q-pa-md"
        v-if="!isloaded || !isloadedAccess"
      >
        <q-spinner-ball color="primary" size="lg" />
      </div>

      <div
        v-else-if="
          accessUpdate ||
          classroom?.Confirmation?.Status == 'resolved' ||
          classroom?.Confirmation?.Status == 'confirmed'
        "
      >
        <div
          :class="$q.screen.gt.sm ? 'row' : 'column full-width'"
          :style="{ 'min-height': `calc(${window.innerHeight}px - 11rem)` }"
        >
          <div
            :class="
              $q.screen.gt.sm
                ? 'col-xs-2 col-sm-4 col-md-4 col-lg-3 col-xl-3'
                : ''
            "
          >
            <div class="column row items-center q-pa-md" v-if="!isloaded">
              <q-spinner-ball color="primary" size="lg" />
            </div>
            <info
              :class="
                $q.screen.gt.sm
                  ? 'col-xs-2 col-sm-4 col-md-4 col-lg-3 col-xl-3'
                  : ''
              "
              :style="{
                height: $q.screen.gt.sm
                  ? ``
                  : 'calc(${window.innerHeight}px - 11rem)',
                marginRight: $q.screen.gt.sm ? '-2rem' : '',
                paddingRight: $q.screen.gt.sm ? '2rem' : '',
                width: $q.screen.gt.sm ? 'calc(100% + 2rem)' : '',
                background:
                  ($q.dark.isActive
                    ? 'linear-gradient(rgba(0, 0, 0, 0.42), rgba(0, 0, 0, 0.42)), '
                    : 'linear-gradient(rgba(255, 255, 255, 0.42), rgba(255, 255, 255, 0.42)), ') +
                  `no-repeat`,
              }"
              style="
                background-size: cover;
                background-color: var(--q-5);
                position: relative;
              "
              :pclassroom="classroom"
              @save="save"
              @savestatus="saveStatus"
              v-if="isloaded"
              ref="infoRef"
            />
          </div>
          <div
            :class="
              $q.screen.gt.sm
                ? 'col-xs-10 col-sm-8 col-md-8 col-lg-9 col-xl-9'
                : ''
            "
          >
            <div class="column row items-center q-pa-md" v-if="!isloaded">
              <q-spinner-ball color="primary" size="lg" />
            </div>

            <!-- @choosetab="chooseTab" -->
            <content
              :class="
                $q.screen.gt.sm
                  ? 'col-xs-2 col-sm-4 col-md-4 col-lg-3 col-xl-3'
                  : ''
              "
              :style="{
                'min-height': $q.screen.gt.sm
                  ? ``
                  : 'calc(${window.innerHeight}px - 11rem)',
              }"
              :pclassroomuid="currentID"
              :pclassroomlanguages="classroom.LanguageIDs"
              :ptab="contenttab"
              @save="save"
              ref="contentRef"
              v-if="isloaded"
              key="contentkey"
            />
          </div>
        </div>
      </div>
      <div class="row justify-center" style="position: relative" v-else>
        <pickerimage
          :data="{
            uid: 'classroom_picker_banner_' + classroom?.Classroom.ID,
            objectid: classroom?.Classroom.ID,
            objecttype: 'classroom_banner',
            params: {
              ClassroomID: classroom?.Classroom.ID,
            },
          }"
          style="border-radius: 1rem"
          :view="{
            height: '10rem',
            onlyshow: true,
          }"
          class="glassy-8 q-mr-sm bg-transparent full-width"
        />
        <div
          :class="
            $q.screen.gt.sm ? 'col-sm-6 col-md-6 col-lg-5 col-xl-5' : 'col-8'
          "
        >
          <div style="position: absolute; top: 0; left: 0" class="full-width">
            <info
              :pclassroom="classroom"
              @save="save"
              @savestatus="saveStatus"
              ref="infoRef"
              class="q-pt-xl"
            />
          </div>
        </div>
      </div>
    </q-scroll-area>
  </q-page>
</template>
