<template>
  <q-layout view="lHh Lpr lFf" style="color: var(--lc); overflow-x: hidden">
    <!-- navstyle2 q-py-md / bg-transparent -->
    <q-header
      class="row justify-center bg-transparent q-pa-md"
      style="pointer-events: none"
    >
      <!-- (no q-py-md) / navstyle3 q-py-md -->
      <div
        class="col-lg-12 col-md-12 col-sm-12 col-xs-12 row justify-between items-center"
      >
        <div
          class="row col-xs-8 col-sm-5 col-md-3 justify-between no-wrap logobg q-py-md q-pl-md"
        >
          <!-- <localizator2
               class="q-ml-md rounded-12 gt-md"
               style="pointer-events: auto; background: var(--l3)"
               /> -->
          <a
            class="logo col-xs-6 col-sm-6 col-md-6 col-lg-6"
            :href="landingPage"
          >
            <svg
              version="1.1"
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 1250 400"
            >
              <use href="landing/Voccent.svg#voccent" />
            </svg>
          </a>

          <!-- <nav
               v-else-if="menuClicked"
               class="mobile-only row col-12 q-pa-sm justify-between"
               > -->
          <div class="q-mx-auto lt-lg col-3">
            <MiddleLink
              v-for="link in middleLinksMobile"
              :link="link"
              :key="link"
              :to="link"
              style="pointer-events: auto"
            />
          </div>
          <!-- </nav> -->

          <div class="q-mx-auto lt-lg col-6">
            <div v-if="iphone">
              <RightLink
                v-for="link in rightLinksIPhone"
                v-bind="link"
                :key="link"
              />
            </div>
            <div v-if="android">
              <RightLink
                v-for="link in rightLinksAndroid"
                v-bind="link"
                :key="link"
              />
            </div>
            <div v-if="!iphone && !android">
              <RightLink
                v-for="link in rightLinksWeb"
                v-bind="link"
                :key="link"
              />
            </div>
          </div>
        </div>

        <!-- <div v-if="$q.screen.lt.lg" class="col-3 q-pl-md row justify-end">
             <q-btn
             class="q-px-sm"
             style="
             color: var(--lc);
             pointer-events: auto;
             background: var(--l3);
             border-radius: 1rem 0 0 1rem;
             "
             flat
             no-caps
             :icon="menuClicked ? 'r_close' : 'r_menu'"
             @click="menuClicked = !menuClicked"
             />
             </div> -->
        <!-- <nav v-if="$q.screen.gt.md" class="desktop-only row col-6 q-pa-sm">
             <MiddleLink
             v-for="link in middleLinksDesktop"
             :link="link"
             :key="link"
             :to="link"
             style="pointer-events: auto"
             />
             <MiddleLink
             link="Docs"
             @click="window.open('https://help.voccent.info', '_blank').focus()"
             style="pointer-events: auto"
             />
             <q-space />
             <div v-if="iphone && $q.screen.lt.sm">
             <RightLink
             v-for="link in rightLinksIPhone"
             v-bind="link"
             :key="link"
             />
             </div>
             <div v-if="!iphone && $q.screen.lt.sm">
             <RightLink
             v-for="link in rightLinksAndroid"
             v-bind="link"
             :key="link"
             />
             </div>
             <div v-if="!iphone && !android">
             <RightLink
             v-for="link in rightLinksWeb"
             v-bind="link"
             :key="link"
             />
             </div>
             </nav> -->

        <!-- <q-img
             height="2rem"
             width="2rem"
             class="hal"
             src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/f6/HAL9000.svg/1024px-HAL9000.svg.png"
             spinner-color="primary"
             spinner-size="82px"
             /> -->

        <!-- <q-space /> -->

        <!-- <localizator2
            class="q-ml-md rounded-12"
            style="pointer-events: auto; background: var(--l3)"
          /> -->
      </div>
    </q-header>

    <main class="row justify-center">
      <div class="col-lg-7 col-md-9 col-sm-11 col-xs-12">
        <router-view />
      </div>
    </main>

    <Intercom
      style="
        pointer-events: auto;
        position: fixed;
        bottom: 1rem;
        right: 1rem;
        color: var(--q-0);
        background: var(--q-p);
      "
      direction="up"
    />
  </q-layout>
</template>

<script setup>
import { ref } from "vue";
let android = navigator.userAgent.toLowerCase().indexOf("android") > -1;
let iphone = navigator.userAgent.toLowerCase().indexOf("iphone") > -1;

import Intercom from "../../components/intercom.vue";
import RightLink from "./RightLink.vue";
import MiddleLink from "./MiddleLink.vue";
import localizator2 from "./localizator2.vue";
import { useStore } from "vuex";
import { i18n } from "src/boot/i18n.js";

let menuClicked = ref(false);
const store = useStore();
store.dispatch("getUser");
const landingPage = process.env.PUBLIC_URL;
const middleLinksDesktop = [""];
const middleLinksMobile = [""];
const rightLinksAndroid = [
  {
    name: i18n.global.t("-raw-landing-start-learning"),
    link: "https://play.google.com/store/apps/details?id=com.voccent.voccent&amp;utm_source=landingpage&amp;utm_campaign=landing_page&amp;pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-2",
    color: "hsl(var(--w))",
    textColor: "black",
    icon: "login",
  },
];
const rightLinksIPhone = [
  {
    name: i18n.global.t("-raw-landing-start-learning"),
    link: "https://apps.apple.com/us/app/voccent-learning-languages/id1608462833",
    color: "hsl(var(--w))",
    textColor: "black",
    icon: "login",
  },
];
const rightLinksWeb = [
  {
    name: i18n.global.t("-raw-landing-start-learning"),
    link: "/login",
    color: "hsl(var(--w))",
    textColor: "black",
    icon: "login",
  },
];
</script>

<style>
:root {
  --lt: #fff;
  --l0: #eeefff;
  --l1: #dddfff;
  --l2: 236, 100%, 90%;
  --l3: #bbbeee;
  --l4: #aaaddd;
  --l5: #999ccc;
  --l6: #888ccc;
  --l7: #777999;
  --l8: #666888;
  --l9: #555777;
  --la: #444666;
  --lb: #333555;
  --lc: #000111;
  --v: #9999ff;
  --w: 35, 100%, 55%; /* #ff9999 */
}
@media (prefers-color-scheme: dark) {
  :root {
    --lt: #000;
    --l0: #222444;
    --l1: #333555;
    --l2: 236, 20%, 33%;
    --l3: #555777;
    --l4: #666888;
    --l5: #777999;
    --l6: #888aaa;
    --l7: #999bbb;
    --l8: #aaaccc;
    --l9: #bbbddd;
    --la: #ccceee;
    --lb: #ddefff;
    --lc: #eeefff;
    --v: #6666ff;
    --w: 35, 100%, 55%; /* #ff6666 */
  }
}

.hal {
  border-radius: 100%;
  box-shadow: 0 0 0rem -1rem #f00;
  animation: pulsehal 5s ease infinite;
}
@keyframes pulsehal {
  0% {
    box-shadow: 0 0 0rem -1rem #f00;
  }
  50% {
    scale: 1.2;
    box-shadow: 0 0 5rem 0.5rem #f00;
  }
  100% {
    box-shadow: 0 0 0rem -1rem #f00;
  }
}
.logobg {
  transition: all 0.5s ease-out;
  pointer-events: none;
  /* background-position-x: -100px !important;
      background: linear-gradient(to right, #555777, #0000); */
  /* background-position-x: -110px !important; */
}
.logo {
  transition: filter 0.4s ease;
  pointer-events: auto;
  cursor: pointer;
  color: var(--q-v);
}
.logobg:hover {
  box-shadow: inset 8rem 0 3rem -3rem hsl(var(--l2));
}
.logobg:hover .logo {
  filter: drop-shadow(0 0 3rem var(--lc));
}

/* mobile touch hover */
@media (hover: none) {
  .logobg:hover {
    box-shadow: none;
  }
  .logobg:hover .logo {
    filter: none;
  }
}

.navstyle1 {
  background: linear-gradient(180deg, var(--l0) 0%, #0000 98%);
}

.navstyle2 {
  background-color: #222444bb;
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);
  background-blend-mode: overlay;
}

.navstyle3 {
  overflow: hidden;
  background: linear-gradient(
    to right,
    hsl(var(--l2), 50%),
    hsl(var(--l2), 50%) 70%,
    var(--l4)
  );
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);
  background-blend-mode: overlay;
  border-radius: 1.75rem !important;
  border: 1px solid hsl(var(--l2));
  box-shadow: 0 1.5rem 3rem -1.5rem hsl(var(--lc));
}

.bd-bottom-gradient {
  border-bottom: 1rem solid;
  border-image-source: linear-gradient(
    to right,
    var(--q-v),
    var(--q-h) 10%,
    var(--q-w),
    var(--q-h) 90%,
    var(--q-v)
  );
  border-image-slice: 1;
}
.bd-gradient {
  border-image-source: linear-gradient(
    to right,
    var(--q-v),
    var(--q-h) 10%,
    var(--q-w),
    var(--q-h) 90%,
    var(--q-v)
  );
  border-image-slice: 1;
}

/* fonts */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&display=swap");
/* TODO: remove unused */
.mont-900 {
  font-family: "Montserrat";
  font-weight: 900 !important;
}
.mont-800 {
  font-family: "Montserrat";
  font-weight: 800 !important;
}
.mont-700 {
  font-family: "Montserrat";
  font-weight: 700 !important;
}
.mont-600 {
  font-family: "Montserrat";
  font-weight: 600 !important;
}
.mont-500 {
  font-family: "Montserrat";
  font-weight: 500 !important;
}
.mont-400 {
  font-family: "Montserrat";
  font-weight: 400 !important;
}

.landingbg {
  background: linear-gradient(145deg, #222444 0%, #000000 100%);
}
/* override dark background color quasar defaults */
/* body.body--dark {
    background: var(--l0);
    } */
body {
  background: #ececec !important;
}

html,
body {
  scroll-behavior: smooth;
  scroll-padding-top: 8rem;
}

/* TODO:  */
/* custom firefox scrollbar */
/* custom webkit scrollbar */

/* custom helper classes */
.rounded-16 {
  border-radius: 1rem;
}
.rounded-12 {
  border-radius: 0.75rem;
}
.rounded-10 {
  border-radius: 0.625em;
}
.rounded-8 {
  border-radius: 0.5rem;
}
</style>
