<template>
  <div
    class="q-pa-sm full-height full-width column no-wrap report bg-0"
    :class="canSelect === true ? 'report-can-select' : ''"
    style="display: inline-block; border-radius: 1rem; max-height: 32rem"
    :style="{
      'max-height': '32rem',
    }"
  >
    <div
      :style="$q.screen.gt.sm ? 'font-size: 4rem' : 'font-size: 2rem'"
      class="row text-9"
    >
      <!--Title-->
      <div
        class="col column no-wrap text-no-wrap column text-11 q-mx-sm"
        style="
          font-size: 1.7rem;
          font-weight: bold;
          overflow: hidden;
          white-space: nowrap;
          text-stroke: 1px var(--q-0);
          text-fill-color: var(--q-9);
          -webkit-text-stroke: 1px var(--q-0);
          -webkit-text-fill-color: var(--q-9);
          paint-order: stroke fill;
        "
      >
        {{ item.GroupName }}
      </div>
      <!--Icon-->
      <div class="column no-wrap justify-center items-end">
        <q-icon
          :color="statusIcons[item.Status].color"
          class="q-pr-sm"
          size="1.7rem"
          style="position: relative; right: 0.3rem; top: 0rem; opacity: 0.6"
          :name="statusIcons[item.Status].name"
        >
          <q-tooltip
            anchor="top middle"
            self="bottom middle"
            class="bg-2 text-8 round-both"
            transition-show="jump-up"
            transition-hide="jump-down"
          >
            <b>{{ h.capitalize(item.Status) }}</b>
          </q-tooltip>
        </q-icon>
      </div>
    </div>
    <div class="row">
      <div
        class="col text-8 ubuntu row q-mr-sm"
        style="font-size: 0.8rem; font-weight: normal"
      >
        <div
          class="col column items-start q-mx-xs text-4"
          style="overflow: hidden"
        >
          <div class="row full-width">
            <div class="column col">
              <div v-if="item.CreatedByName">
                <span class="q-pr-md">
                  <span style="font-weight: bold"
                    >{{ $t("-raw-report-author") }}:
                  </span>
                  {{ item.CreatedByName }}
                </span>
              </div>
              <div style="white-space: nowrap">
                <span class="q-pr-md">
                  <span style="font-weight: bold"
                    >{{ $t("-raw-report-created") }}:
                  </span>
                  {{ formatTimestamp(item.createdat) }}
                </span>
                <span v-show="item.updatedat">
                  <span style="font-weight: bold"
                    >{{ $t("-raw-report-updated") }}:
                  </span>
                  {{ formatTimestamp(item.updatedat) }}
                </span>
              </div>
            </div>
            <div class="column items-end justify-end">
              <q-btn
                v-if="$store.getters.user?.ID === item.createdby"
                :loading="isDeleting"
                size="0.65rem"
                round
                flat
                color="8"
                class=""
                icon="delete"
                style="width: 1rem; height: 1rem; position: relative"
                @click.stop="deleteReport(item.ID)"
              >
                <q-tooltip
                  anchor="top middle"
                  self="bottom middle"
                  class="bg-2 text-8 round-both"
                  transition-show="jump-up"
                  transition-hide="jump-down"
                >
                  <b>{{ $t("-raw-tooltip-delete") }}</b>
                </q-tooltip>
              </q-btn>
            </div>
          </div>
          <div
            v-if="reportParams?.DateFrom || reportParams?.DateTo"
            style="white-space: nowrap"
          >
            <span class="q-pr-md"
              ><span v-if="reportParams?.DateFrom" style="font-weight: bold"
                >From:</span
              >
              {{ formatDate(reportParams.DateFrom) }}
            </span>
            <span
              ><span v-if="reportParams?.DateTo" style="font-weight: bold"
                >To:</span
              >
              {{ formatDate(reportParams.DateTo) }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.report {
  user-select: none;
  border: 2px solid var(--q-1);
}

.report-can-select {
  cursor: pointer;
}

.report-can-select:hover {
  border: 2px dashed var(--q-v);
}
</style>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import h from "src/h/helpers.js";

export default {
  props: ["item", "isDeleting", "canSelect"],
  emits: ["deleteReport"],
  data: () => ({
    prompt: false,
    previewLoading: false,
    reportParams: {},
    statusIcons: {
      new: {
        name: "fiber_new",
        color: "8",
      },
      "in progress": {
        name: "pending",
        color: "v",
      },
      done: {
        name: "done",
        color: "p",
      },
      error: {
        name: "error",
        color: "n",
      },
      canceled: {
        name: "cancel",
        color: "8",
      },
    },
  }),
  mounted() {
    moment.locale(this.$i18n.locale);
  },
  computed: {
    h() {
      return h;
    },
    ...mapGetters(["user"]),
    dateFormat() {
      return moment.localeData().longDateFormat("LTS");
    },
  },
  beforeMount() {
    if (this.item.Params) {
      if (typeof this.item.Params == "string") {
        this.reportParams = JSON.parse(this.item.Params);
      } else {
        this.reportParams = this.item.Params;
      }
    }
  },
  methods: {
    deleteReport(id) {
      this.$emit("deleteReport", id);
    },
    formatTimestamp(ts) {
      if (!ts) return "";
      return moment(ts).format("L LT");
    },
    formatDate(ts) {
      if (!ts) return "";
      return moment(ts).local().format("L LT");
    },
  },
};
</script>
