import axios from "axios";
import Lib from "src/h/helpers.js";
import { router, Store } from "src/router";
import { Notify } from "quasar";
import { i18n } from "src/boot/i18n.js";
// import { streamer } from "src/boot/streamer.js";

export default {
  Call,
  CallConfirmation,
  CallUndo,
};

// fnct must be a Promise!
function CallConfirmation(params, fnct) {
  // Default params
  // Need set method OR other params !
  var prm = {
    method: null,
    message: null,
    icon: null,
    key: null,
    buttonOk: null,
    messageDetails: [],
  };

  Lib.CopyObject(params, prm);

  if (prm.method === "delete") {
    if (params?.message === undefined || params?.message === null) {
      prm.message = i18n.global.t("-raw-confirm-delete-object");
    }
    if (prm.icon === undefined || prm.icon === null) {
      prm.icon = "delete";
    }
    if (prm.buttonOk === undefined || prm.buttonOk === null) {
      prm.buttonOk = i18n.global.t("-raw-tooltip-delete");
    }
  }
  prm.fnct = fnct;

  if (prm.buttonOk === undefined || prm.buttonOk === null) {
    prm.buttonOk = "Ok";
  }
  if (prm.message === undefined || prm.message === null) {
    prm.message = i18n.global.t("-raw-confirm-operation");
  }

  Store.dispatch("showConfirmation", prm);
}

function CallUndo(params, fnctOk, fnctUndo) {
  // Default params
  var prm = {
    message: i18n.global.t("-raw-general-operation-in-progress"),
    ID: null,
    timeout: 2000,
    label: i18n.global.t("-raw-general-undo"),
  };

  Lib.CopyObject(params, prm);

  if (fnctUndo == undefined || fnctUndo == null) {
    fnctUndo = function () {};
  }
  if (!prm.ID) {
    prm.ID = Math.random().toString(36).substring(2);
  }

  // check - we already doing this operation
  if (prm.ID in Store.getters.undooperationIDs) {
    return;
  }

  var timeoutID = setTimeout(() => {
    Store.dispatch("hideUndo", timeoutID);
    fnctOk();
    notf(); //the timeout in close notify sometimes<timeout deleted, so close notify programmatically
  }, prm.timeout);
  Store.dispatch("showUndo", { ID: timeoutID, operationID: prm.ID });

  var notf = Notify.create({
    progress: true,
    group: false,
    // I have -1sec for notify and timeout. Why?
    timeout: prm.timeout - 1000,
    progressClass: "q-mx-md",
    message: prm.message,
    color: "1",
    textColor: "8",
    classes: "round-both q-ml-lg q-mb-sm z-max",
    position: "bottom", // 'bottom-left'
    actions: [
      {
        label: prm.label,
        color: "8",
        rounded: true,
        handler: () => {
          Store.dispatch("hideUndo", timeoutID);
          fnctUndo();
        },
      },
    ],
  });
}

function Call(params) {
  if (Lib.getItem("VOC_USER_TOKEN_SCOPE")) {
    Lib.removeItem("VOC_USER_TOKEN_SCOPE");
  }
  // Default params
  var prm = {
    url: null,
    method: "get",
    data: {},
    params: {},
    headers: {}, // set header
    responseType: null,
    getHeaders: false, // get headers from response
    show_error: false,
    show_success: false,
    show_success_message: null,
    recaptcha: null,
  };
  Lib.CopyObject(params, prm);

  if (params.data !== null && params.data !== undefined) {
    // need for FormData type
    prm.data = params.data;
  } else {
    prm.data = {};
  }

  const promiseParam = {
    need_skip_api: false,
    refresh_token_type: "",
    reject_error: null,
  };

  // check if token will soon expire (1 min -> 60000 msec) need check > 0 because API call time
  // if we romove this check, it's will be work, but we will get in some case 401 error and call API twice
  var timeExpireCheckMsc = 60000;

  // Get client token if need (if it's empty or expired)
  // we don't refresh client token (but we can do it) - it's simpler get new client token
  // if we want refresh client token we need add addition call if client' token expired forever (refresh_token->get_new_token_if_expired_forever)
  const GetClientTokenCall = function (obj) {
    return new Promise((resolve, reject) => {
      if (
        Lib.getItem("VOC_CLIENT_TOKEN") &&
        Lib.getItem("VOC_CLIENT_TOKEN_REFRESH") &&
        Lib.getItem("VOC_CLIENT_TOKEN_EXPIRE_AT")
      ) {
        let d = Lib.getItem("VOC_CLIENT_TOKEN_EXPIRE_AT");
        let from = new Date(d);
        let now = new Date();
        if (from - now > timeExpireCheckMsc) {
          resolve(obj);
          return;
        }
      }
      const vocClientTokenRequest = {
        // Scope: null, // all available methods for client
        GrantType: "client_credentials",
        ClientId: "VocClientV1",
        ClientSecret: "f0491aee-2e11-4956-8e17-371dfb1f4aa6",
      };
      return axios({
        method: "post",
        url: process.env.PUBLIC_API_URL + "/oauth2/token_client",
        data: vocClientTokenRequest,
        auth: {
          username: vocClientTokenRequest.ClientId,
          password: vocClientTokenRequest.ClientSecret,
        },
      })
        .then((response) => {
          if (!Lib.IsEmpty(response.data.err)) {
            reject(new Error(response.data.err));
            return;
          }
          // set client token
          Lib.setItem("VOC_CLIENT_TOKEN", response.data.data.access_token);
          Lib.setItem(
            "VOC_CLIENT_TOKEN_REFRESH",
            response.data.data.refresh_token,
          );
          Lib.setItem(
            "VOC_CLIENT_TOKEN_EXPIRE_AT",
            response.data.data.expire_at,
          );
          resolve(obj);
        })
        .catch((e) => {
          const returnErr = e;
          reject(returnErr);
        });
    });
  };

  // refresh user/client token
  const RefreshTokenCall = function (obj) {
    return new Promise((resolve, reject) => {
      let cookieTokName, cookieRefTokName, cookieExpireAtTokName, isClient;
      if (promiseParam.refresh_token_type === "user") {
        cookieTokName = "VOC_USER_TOKEN";
        cookieRefTokName = "VOC_USER_TOKEN_REFRESH";
        cookieExpireAtTokName = "VOC_USER_TOKEN_EXPIRE_AT";
        isClient = false;
      } else if (promiseParam.refresh_token_type === "client") {
        cookieTokName = "VOC_CLIENT_TOKEN";
        cookieRefTokName = "VOC_CLIENT_TOKEN_REFRESH";
        cookieExpireAtTokName = "VOC_CLIENT_TOKEN_EXPIRE_AT";
        isClient = true;
      } else {
        resolve(obj);
        return;
      }
      const vocClientTokenRequest = {
        // Scope: null, // all available methods for client
        GrantType: "refresh_token",
        ClientId: "VocClientV1",
        ClientSecret: "f0491aee-2e11-4956-8e17-371dfb1f4aa6",
        RefreshToken: Lib.getItem(cookieRefTokName),
      };
      return axios({
        method: "post",
        url:
          process.env.PUBLIC_API_URL +
          "/oauth2/token" +
          (isClient ? "_client" : ""),
        data: vocClientTokenRequest,
        auth: isClient
          ? {
              // this param set 'Authorization' = "Basic" if it's != null instead of headers:{'Authorization'} below
              username: vocClientTokenRequest.ClientId,
              password: vocClientTokenRequest.ClientSecret,
            }
          : null,
        headers: {
          Authorization: isClient
            ? "Basic"
            : "Bearer " + Lib.getItem("VOC_CLIENT_TOKEN"),
        },
      })
        .then((response) => {
          if (!Lib.IsEmpty(response.data.err)) {
            reject(new Error(response.data.err));
            return;
          }
          // set token
          Lib.setItem(cookieTokName, response.data.data.access_token);
          if (!isClient) {
            h.setItem(
              "VOC_USER_ROLE_IS_SYSTEM",
              response.data.data.RoleIsSystem,
            );
            h.setItem(
              "VOC_USER_ROLE_IS_ADMIN",
              response.data.data.RoleIsAdmin,
            );
            h.setItem(
              "VOC_USER_ORGANIZATION_ID",
              response.data.data.OrganizationID,
            );
            h.setItem(
              "VOC_USER_SIMPLE_UI",
              response.data.data.SimpleUI,
            );
          }
          Lib.setItem(cookieRefTokName, response.data.data.refresh_token);
          Lib.setItem(cookieExpireAtTokName, response.data.data.expire_at);
          // // for user, is not necessary (it's refresh token, so we already must be check connect)
          // if (!isClient) {
          //   streamer.socket.send(
          //     JSON.stringify({
          //       Token: response.data.data.access_token,
          //       Type: "connect",
          //     })
          //   );
          // }
          resolve(obj);
        })
        .catch((e) => {
          const returnErr = e;
          // check here additional, for example if we delete from cookie only VOC_CLIENT_TOKEN_REFRESH
          // or if token expire forever
          if (promiseParam.refresh_token_type === "user") {
            Store.dispatch("userSignOut");
            router.push({ path: "/" }).catch(() => {});
          } else if (promiseParam.refresh_token_type === "client") {
            Store.dispatch("userSignOut");
            promiseParam.need_skip_api = false;
          }
          reject(returnErr);
        });
    });
  };

  // check expire user/client token before call API and get 401 about expire from backend
  const CheckRefreshTokenCall = function (obj) {
    return new Promise((resolve, reject) => {
      if (Lib.getItem("VOC_USER_TOKEN")) {
        // console.log('EXPIRE TOKEN USER MSEC: ', new Date(Lib.getItem('VOC_USER_TOKEN_EXPIRE_AT')) - new Date())
        if (
          new Date(Lib.getItem("VOC_USER_TOKEN_EXPIRE_AT")) - new Date() <=
          timeExpireCheckMsc
        ) {
          promiseParam.refresh_token_type = "user";
        }
      } else if (Lib.getItem("VOC_CLIENT_TOKEN")) {
        // console.log('EXPIRE TOKEN CLIENT MSEC: ', new Date(Lib.getItem('VOC_CLIENT_TOKEN_EXPIRE_AT')) - new Date())
        if (
          new Date(Lib.getItem("VOC_CLIENT_TOKEN_EXPIRE_AT")) - new Date() <=
          timeExpireCheckMsc
        ) {
          promiseParam.refresh_token_type = "client";
        }
      }
      resolve(obj);
    });
  };

  const showErrMessageIfNeed = function (msg, errcode, errdetail) {
    if (prm.show_error === true) {
      switch (errcode) {
        case "ret-2000":
          Notify.create({
            group: true,
            timeout: 10000,
            // message: "Sorry, please try again (error-058bc68c)",
            // WE SHOW MESSAGE FROM BACKEND IF TYPE ret-2000
            message: msg ? msg : i18n.global.t("-raw-error-occurred"),
            color: "1",
            textColor: "n",
            classes: "round-both q-ml-lg q-mb-sm z-max",
            position: "bottom",
          });
          break;
        case "ret-2003":
          Store.dispatch(
            "showNoAccessTariff",
            Lib.isValidUuid(
              prm.url.replace(`/billing/check_quota_tariff_limit/`, ""),
            )
              ? prm.url.replace(`/billing/check_quota_tariff_limit/`, "")
              : Lib.isValidUuid(errdetail.slice(-36))
                ? errdetail.slice(-36)
                : null,
          );
          break;
        default:
          Notify.create({
            group: true,
            timeout: 5000,
            message: msg,
            color: "1",
            textColor: "n",
            classes: "round-both q-ml-lg q-mb-sm z-max",
            position: "bottom",
          });
      }
    }
  };

  // call main API
  const MainAPICall = function (obj) {
    // logic API call, use user or client token (if the user token does not exist)
    return new Promise((resolve, reject) => {
      // we need return (don't need call the function twice in our bus-call-api)
      if (promiseParam.need_skip_api === true) {
        resolve(obj);
        return;
      }
      let token = Lib.getItem("VOC_CLIENT_TOKEN"); // clientToken
      if (Lib.getItem("VOC_USER_TOKEN")) {
        token = Lib.getItem("VOC_USER_TOKEN");
      }

      let headers = {
        Authorization: "Bearer " + token,
      };
      // recaptcha have matter only for main api call (not refresh or client token)
      if (prm.recaptcha) {
        headers.Recaptcha = prm.recaptcha;
      }
      return axios({
        method: prm.method,
        url: process.env.PUBLIC_API_URL + prm.url,
        data: prm.data,
        params: prm.params,
        headers: prm.headers,
        responseType: prm.responseType,
        headers: headers,
        // validateStatus: function (status) {
        //   return status >= 200 && status < 400
        // }
      })
        .then((response) => {
          if (response.data === null) {
            response.data = {};
          }
          if (response.data.err !== undefined && response.data.err !== null) {
            reject(new Error(response.data.err));
            return;
          }
          // if (response.data.data == null) {
          //   reject(new Error('Empty object'))
          //   return
          // }
          promiseParam.need_skip_api = true;
          promiseParam.refresh_token_type = "";
          if (response.data.data !== undefined) {
            if (prm.getHeaders === true) {
              resolve({
                data: response.data.data,
                headers: response.headers,
              });
            } else {
              resolve(response.data.data);
            }
          } else {
            if (prm.getHeaders === true) {
              resolve({
                data: response.data,
                headers: response.headers,
              });
            } else {
              resolve(response.data);
            }
          }
          if (prm.show_success === true) {
            Notify.create({
              group: true,
              timeout: 500,
              message:
                prm.show_success_message === null
                  ? i18n.global.t("-raw-op-succeed")
                  : prm.show_success_message,
              color: "p",
              textColor: "0",
              classes: "round-both q-ml-lg q-mb-sm z-max",
              position: "bottom",
            });
          }
        })
        .catch((e) => {
          const returnErr = e;
          // returnErr.response === undefined for example if Network Error
          if (returnErr.response !== undefined) {
            let errcode = "";
            let errmsg = "";
            let errdetail = "";
            try {
              let obj = {};
              if (typeof returnErr.response.data === "string") {
                // 401
                obj = JSON.parse(returnErr.response.data);
              } else {
                // object //400
                obj = returnErr.response.data;
              }
              errcode = obj.errcode;
              errmsg = obj.err;
              errdetail = obj.errdetail;
            } catch (e) {
              console.log("401 unauthorized have no body");
            }

            if (returnErr.response.status === 401) {
              switch (errcode) {
                // NOTE: ref-5801ee14
                case "ret-auth-user-expire":
                  promiseParam.refresh_token_type = "user";
                  resolve(obj);
                  return;
                // NOTE: ref-5801ee70
                case "ret-auth-client-expire":
                  promiseParam.refresh_token_type = "client";
                  resolve(obj);
                  return;
                // NOTE: ref-5801ee79
                case "ret-auth-user":
                  Store.dispatch("userSignOut");
                  router.push({ path: "/" }).catch(() => {});
                  promiseParam.need_skip_api = true;
                  promiseParam.reject_error = returnErr;
                  resolve(obj);
                  return;
                // NOTE: ref-5801ee11
                case "ret-auth-client":
                  Store.dispatch("userSignOut");
                  promiseParam.need_skip_api = false;
                  resolve(obj);
                  return;
                default:
                  break;
              }
            }
            if (returnErr.response.status === 400) {
              if (
                errcode === "ret-2000" ||
                errcode === "ret-2003" ||
                errcode === "ret-2004"
              ) {
                showErrMessageIfNeed(errmsg, errcode, errdetail);
                promiseParam.need_skip_api = true;
                reject(new Error(errmsg, { cause: returnErr }));
                return;
              } else {
                // system error (return from api)
                showErrMessageIfNeed(i18n.global.t("-raw-op-error"));
                reject(returnErr);
                return;
              }
            }
          }
          showErrMessageIfNeed(i18n.global.t("-raw-op-error"));
          reject(returnErr);
        });
    });
  };

  const rejectResult = function (obj) {
    // logic API call, use user or client token (if the user token does not exist)
    return new Promise((resolve, reject) => {
      if (promiseParam.reject_error) {
        reject(promiseParam.reject_error);
        return;
      }
      resolve(obj);
    });
  };

  const APIPromise = GetClientTokenCall()
    .then(CheckRefreshTokenCall)
    .then(RefreshTokenCall)
    .then(MainAPICall)
    .then(RefreshTokenCall)
    .then(GetClientTokenCall)
    .then(MainAPICall)
    .then(rejectResult);

  return APIPromise;
}
