import Avatar from "src/components/avatar.vue";
import TitleBar from "./title.vue";
import Navigator from "./navigator.vue";
import Theminator from "src/components/theminator.vue";
import Localizator from "src/components/localizator.vue";
import InputSelector from "./inputselector.vue";
import Inbox from "src/components/inbox/inbox.vue";
import Mic from "./mic.vue";
// import Loc from "./loc.vue";
import Lib from "src/h/helpers.js";
// import { defineAsyncComponent } from 'vue'
import Intercom from "../../components/intercom.vue";
import noAccessByTariff from "src/components/have-no-access-by-tariff.vue";
import systemNotification from "src/components/system-notification/index.vue";

export default {
  components: {
    Avatar,
    TitleBar,
    Navigator,
    Theminator,
    Localizator,
    InputSelector,
    Mic,
    // Loc,
    Inbox,
    Intercom,
    noAccessByTariff,
    systemNotification,
    // ClassesTabs: defineAsyncComponent(() =>
    //   import('./tabs.vue')
    // ),
  },
  data() {
    return {
      mixer: true,
      split: true,
      debug: false,
      locales: ["en-US", "de-DE", "ru-RU"],
      rightmini: true,
      bottom: true,
      right: true,
      top: true,
      confirmationKey: null,
      dialogCookie: false,
      windowWidth: window.innerWidth,
      settingstoggle: false,
    };
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
      if (window.innerWidth < 768) {
        this.left = false;
      }
    });
    if (this.$q.screen.lt.sm) {
      this.right = false;
      this.left = false;
    }
    // we need to get user here (after F5 for example)
    this.$store.dispatch("fetchLanguages");
    this.$store.dispatch("getUser");
    this.$store.dispatch("getUserTariff");
    this.$store.dispatch("fillUserScope");
    this.$store.dispatch("fillOrganizationID");
    // Set default theme
    if (!this.$store.getters.getTheme) {
      this.$store.dispatch("setSaveTheme", this.$store.getters.getDefaultTheme);
    }
    // TODO: move to router/index.js or App.vue or something
    this.ShowCookieInfo();
  },
  beforeDestroy() {
    window.removeEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  },
  computed: {
    left: {
      get() {
        return this.$store.state.left;
      },
      set(newVal) {
        this.$store.commit("setLeft", newVal);
      },
    },
    isStage() {
      try {
        return process.env.DEV;
      } catch (e) {
        return true;
      }
    },
    isSystem() {
      if (Lib.getItem("VOC_USER_ROLE_IS_SYSTEM")) {
        return true;
      }
      return false;
    },
    scopes() {
      if (this.$store.getters.userscope) {
        return this.$store.getters.userscope;
      }
      return {};
    },
    loading() {
      return this.$store.getters.loading;
    },
    error() {
      return this.$store.getters.error;
    },
    user() {
      // NOTE: 88dbb28e We need this to Open a side bar when doing `/create` recording new Audio Challenge
      if (
        this.$route.path === "/record" ||
        this.$route.path.startsWith("/edit/")
      ) {
        this.right = true;
      }
      return this.$store.getters.user;
    },
    confirmation() {
      return this.$store.getters.confirmation;
    },
    stream: {
      get() {
        return this.$store.state.audio.stream;
      },
    },
    noaccesstariffShow: {
      get() {
        return this.$store.getters.noaccesstariff.show;
      },
      set(newVal) {
        this.$store.commit("setNoAccessTariffShow", newVal);
      },
    },
    isSimpleUI() {
      return Lib.getItem("VOC_USER_SIMPLE_UI") === true;
    },
  },
  watch: {
    windowWidth: {
      handler(val) {
        // console.log(val);
        if (val > 1023) {
          this.right = true;
          this.left = true;
        }
      },
    },
  },
  methods: {
    rightHandler() {
      this.rightmini = !this.rightmini;
      if (this.$q.screen.lt.md && this.right) this.right = false;
      if (this.$q.screen.lt.md && this.left) this.left = false;
      // auto-closes right drawer on mobile on mount/unmount()
      // event-based from 'right' components on each route
    },
    handleSwipe({ evt, ...swipe }) {
      // evt = native JS event
      // console.log(swipe)
      if (this.$q.screen.lt.md) {
        // left and right
        // if (swipe.direction === 'left') this.left ? this.left = false : this.right = true
        // if (swipe.direction === 'right') this.right ? this.right = false : this.left = true

        // right only & only if right component defined, left only close
        if (this.$route.matched[1].components.right) {
          if (swipe.direction === "left" && !this.rightmini) {
            this.left
              ? ((this.left = false), (this.right = true))
              : (this.right = true);
          }
          if (swipe.direction === "right" && !this.rightmini)
            this.right = false;
        }
      }
    },
    hideConfirmation(isConfirm) {
      this.$store.dispatch("hideConfirmation", isConfirm);
    },
    hideNoAccessTariff() {
      this.$store.dispatch("hideNoAccessTariff");
    },
    shourtcuts() {
      // window.addEventListener('keypress', (e) => {
      //   if (e.keyCode === 23) {   // 'w'
      //     this.top = !this.top
      //   }
      //   if (e.keyCode === 92) {   // ']'
      //     if (this.right && this.rightmini) { this.right = false; this.rightmini = false } else if (this.right) this.rightmini = true
      //     else this.right = true
      //   }
      // })
    },
    CookieOk() {
      Lib.setItem("CookieOk", true);
      this.dialogCookie = false;
    },
    ShowCookieInfo() {
      var cookieOk = Lib.getItem("CookieOk");
      var path1 = decodeURI(this.$route.path).split("/");
      if (path1.length > 1) {
        path1 = path1[1];
      }
      if (
        cookieOk === true ||
        decodeURI(this.$route.path) === "/privacy-policy" ||
        path1 === "jc" ||
        path1 === "sl" // %address%/jc/%code%
      ) {
        this.dialogCookie = false;
      } else {
        this.dialogCookie = true;
      }
    },
  },
};
