import Lib from "src/h/helpers.js";
import { ref } from "vue";
import { v4 as uuidv4 } from "uuid";

let streamer = {};

export default ({ app }) => {
  console.debug(
    "%cboot/streamer.js",
    "background: black; font-weight: bold; color: white;",
  );

  app.config.globalProperties.$streamer = streamer;
  const isConnect = ref(false);

  let connect = function (isreconnect) {
    let address = process.env.WEBSOCKET_API + "/streamer/ws/main";
    let socket = new WebSocket(address);
    socket.onopen = function (evt) {
      while (socket.readyState !== 1) {}
      isConnect.value = true;
      // store.commit("setIsConnect", true)
      socket.send(
        JSON.stringify({
          Token: Lib.getItem("VOC_USER_TOKEN"),
          Type: "connect",
        }),
      );
      console.debug("Connection open: " + address);
    };
    socket.onclose = function (evt) {
      isConnect.value = false;
      // store.commit("setIsConnect", false)
      setTimeout(function () {
        app.config.globalProperties.$streamer.socket = connect(true);
      }, 2000);
      console.debug("Connection closed: " + address);
    };
    socket.onmessage = function (evt) {
      if (!evt.data) {
        return;
      }
      let obj = JSON.parse(evt.data);
      let k = obj.Ticket + "/" + obj.OperationID;
      if (obj.Type == "message" || obj.Type == "watch_user_status") {
        if (app.config.globalProperties.$streamer.tickets[k]) {
          let res = obj.Data;
          if (typeof res === "string" || res instanceof String) {
            // for return without jetstream (read and return for current query) - we return other structer (string)
            res = JSON.parse(res);
          }
          for (let key in app.config.globalProperties.$streamer.tickets[k]) {
            if (
              app.config.globalProperties.$streamer.tickets[k][key].onmessage
            ) {
              app.config.globalProperties.$streamer.tickets[k][key].onmessage(
                res,
                obj.SessionID ? obj.SessionID : null,
                obj.Type,
              );
            }
          }
        }
        // if (
        //   app.config.globalProperties.$streamer.tickets[k] &&
        //   app.config.globalProperties.$streamer.tickets[k].onmessage
        // ) {
        //   let res = obj.Data;
        //   if (typeof res === "string" || res instanceof String) {
        //     // for return without jetstream (read and return for current query) - we return other structer (string)
        //     res = JSON.parse(res);
        //   }
        //   app.config.globalProperties.$streamer.tickets[k].onmessage(
        //     res,
        //     obj.SessionID ? obj.SessionID : null,
        //     obj.Type,
        //   );
        // }
      } else if (obj.Type == "generate_ticket_token") {
        if (
          obj.TicketToken &&
          app.config.globalProperties.$streamer.tickets[k] &&
          Object.keys(app.config.globalProperties.$streamer.tickets[k]).length >
            0
        ) {
          // console.log(
          //   "GENERATE ticketToken",
          //   k,
          //   app.config.globalProperties.$streamer.tickets[k],
          // );
          let keyExt = null;
          if (obj.SessionID) {
            // if we have SessionID, save tickettoken name for current example
            for (let key in app.config.globalProperties.$streamer.tickets[k]) {
              if (
                app.config.globalProperties.$streamer.tickets[k][key]
                  .sessionID === obj.SessionID
              ) {
                keyExt = key;
                break;
              }
            }
          }
          if (!keyExt) {
            // if no sessionID save for first element
            keyExt = Object.keys(
              app.config.globalProperties.$streamer.tickets[k],
            )[0];
          }
          if (keyExt) {
            app.config.globalProperties.$streamer.tickets[k][
              keyExt
            ].tickettoken = obj.TicketToken;
          }

          if (
            keyExt &&
            app.config.globalProperties.$streamer.tickets[k][keyExt].onready
          ) {
            app.config.globalProperties.$streamer.tickets[k][keyExt].onready(
              obj.Data,
              obj.SessionID ? obj.SessionID : null,
            );
          }
        }
        // if (
        //   obj.TicketToken &&
        //   app.config.globalProperties.$streamer.tickets[k]
        // ) {
        //   app.config.globalProperties.$streamer.tickets[k].tickettoken =
        //     obj.TicketToken;
        //   if (
        //     app.config.globalProperties.$streamer.tickets[k] &&
        //     app.config.globalProperties.$streamer.tickets[k].onready
        //   ) {
        //     app.config.globalProperties.$streamer.tickets[k].onready(
        //       obj.Data,
        //       obj.SessionID ? obj.SessionID : null,
        //     );
        //   }
        // }
      } else if (obj.Type == "error") {
        if (
          app.config.globalProperties.$streamer.tickets[k] &&
          Object.keys(app.config.globalProperties.$streamer.tickets[k]).length >
            0
        ) {
          // if return not lib.NewError(json - struct error from backend)
          try {
            JSON.parse(obj.Data);
          } catch (e) {
            obj.Data = JSON.stringify({
              // ErrCode:
              Err: obj.Data,
            });
          }

          let keyExt = null;
          if (obj.SessionID) {
            // if we have SessionID, save tickettoken name for current example
            for (let key in app.config.globalProperties.$streamer.tickets[k]) {
              if (
                app.config.globalProperties.$streamer.tickets[k][key]
                  .sessionID === obj.SessionID
              ) {
                keyExt = key;
                break;
              }
            }
          }

          if (!keyExt) {
            // if no sessionID save for first element
            keyExt = Object.keys(
              app.config.globalProperties.$streamer.tickets[k],
            )[0];
          }

          if (
            keyExt &&
            app.config.globalProperties.$streamer.tickets[k][keyExt].onerror
          ) {
            app.config.globalProperties.$streamer.tickets[k][keyExt].onerror(
              obj.Data,
              app.config.globalProperties.$streamer.tickets[k][keyExt].lastSend,
              app.config.globalProperties.$streamer.tickets[k][keyExt]
                .lastOperation,
            );
          }
        }

        // if (
        //   app.config.globalProperties.$streamer.tickets[k] &&
        //   app.config.globalProperties.$streamer.tickets[k].onerror
        // ) {
        //   // if return not lib.NewError(json - struct error from backend)
        //   try {
        //     JSON.parse(obj.Data);
        //   } catch (e) {
        //     obj.Data = JSON.stringify({
        //       // ErrCode:
        //       Err: obj.Data,
        //     });
        //   }
        //   app.config.globalProperties.$streamer.tickets[k].onerror(
        //     obj.Data,
        //     app.config.globalProperties.$streamer.tickets[k].lastSend,
        //     app.config.globalProperties.$streamer.tickets[k].lastOperation,
        //   );
        // }
      }
    };
    return socket;
  };

  streamer = {
    isConnect: isConnect,
    socket: connect(false),
    tickets: {},
    addTicket: function (
      name,
      operationID,
      onmessage,
      onerror,
      sessionID,
      onready,
    ) {
      let prop = name + "/" + operationID;
      let explUid_ = uuidv4();
      // if (app.config.globalProperties.$streamer.tickets.hasOwnProperty(prop)) {
      //   // autoremove old tickettoken
      //   // delete app.config.globalProperties.$streamer.tickets[prop];
      //   if (app.config.globalProperties.$streamer.tickets[prop]) {
      //     app.config.globalProperties.$streamer.tickets[prop].removeTicket(); // need try remove from backend too (actual in parallel mode)
      //   } else {
      //     delete app.config.globalProperties.$streamer.tickets[prop];
      //   }
      //   // return app.config.globalProperties.$streamer.tickets[prop];
      // }
      if (!app.config.globalProperties.$streamer.tickets.hasOwnProperty(prop)) {
        app.config.globalProperties.$streamer.tickets[prop] = {};
      }

      app.config.globalProperties.$streamer.tickets[prop][explUid_] = {
        name: name,
        operationID: operationID,
        tickettoken: null,
        sessionID: sessionID,
        onmessage: onmessage,
        onerror: onerror,
        onready: onready,
        lastSend: null,
        lastOperation: null,
        explUid: explUid_,
        send: function (data) {
          if (app.config.globalProperties.$streamer.socket.readyState === 1) {
            app.config.globalProperties.$streamer.socket.send(
              JSON.stringify({
                Data: data,
                TicketToken: this.tickettoken,
                Type: "message",
                // SessionID: this.sessionID, // don't use it for type message, generate once for generate_ticket_token
              }),
            );
            if (
              app.config.globalProperties.$streamer.tickets[prop] &&
              app.config.globalProperties.$streamer.tickets[prop][this.explUid]
            ) {
              app.config.globalProperties.$streamer.tickets[prop][
                this.explUid
              ].lastSend = data;
              app.config.globalProperties.$streamer.tickets[prop][
                this.explUid
              ].lastOperation = "message";
            }
          }
        },
        // check access and get TicketToken
        generateTicket: function () {
          if (app.config.globalProperties.$streamer.socket.readyState === 1) {
            app.config.globalProperties.$streamer.socket.send(
              JSON.stringify({
                Ticket: this.name,
                OperationID: this.operationID,
                Token: Lib.getItem("VOC_USER_TOKEN"),
                Type: "generate_ticket_token",
                SessionID: this.sessionID,
              }),
            );
            app.config.globalProperties.$streamer.tickets[prop][
              this.explUid
            ].lastSend = null;
            app.config.globalProperties.$streamer.tickets[prop][
              this.explUid
            ].lastOperation = "generate_ticket_token";
          }
        },
        removeTicket: function () {
          let prop = this.name + "/" + this.operationID;
          if (
            app.config.globalProperties.$streamer.tickets.hasOwnProperty(prop)
          ) {
            if (
              app.config.globalProperties.$streamer.tickets[
                prop
              ].hasOwnProperty(this.explUid)
            ) {
              delete app.config.globalProperties.$streamer.tickets[prop][
                this.explUid
              ];
            }
            if (
              Object.keys(app.config.globalProperties.$streamer.tickets[prop])
                .length == 0
            ) {
              delete app.config.globalProperties.$streamer.tickets[prop];
            }
          }
          if (
            app.config.globalProperties.$streamer.socket.readyState === 1 &&
            this.tickettoken
          ) {
            app.config.globalProperties.$streamer.socket.send(
              JSON.stringify({
                TicketToken: this.tickettoken,
                Type: "remove_ticket_token",
              }),
            );
            // we already delete from js above
            // app.config.globalProperties.$streamer.tickets[prop][this.explUid].lastSend = null
            // app.config.globalProperties.$streamer.tickets[prop][this.explUid].lastOperation = 'remove_ticket_token'
          }
        },
      };
      app.config.globalProperties.$streamer.tickets[prop][
        explUid_
      ].generateTicket();

      return app.config.globalProperties.$streamer.tickets[prop][explUid_];
    },
  };

  app.config.globalProperties.$streamer = streamer;
};

export { streamer };
